import { buildSlice, CommonState, Row } from '~libs/reduxUtils';

import { getAll, typePrefix } from '~weekly-planner/actions/appointments/conflicts';

const initialState: CommonState<Row> & {
  params: { [key: string]: any };
  options: { [key: string]: boolean };
  count?: number;
} = {
  loading: 'idle',
  hasLoaded: false,
  error: null,
  rows: [],

  params: { limit: 30 },
  options: {
    conflictTime: true,
    conflictTravel: true,
    conflictPreference: true,
  },
};

const slice = buildSlice(typePrefix, initialState, {
  extraReducers: (builder) => {
    builder.addCase(getAll.fulfilled, (state, action) => {
      const { data } = action.payload;
      const { rows, total } = data;
      state.loading = 'fulfilled';
      state.rows = rows;
      state.count = total;
      state.hasLoaded = true;
    });
  },
  customActions: {
    toggleOption(state, action) {
      const { key, toggle } = action.payload;
      state.options[key] = toggle;
    },
  },
});

export const { clear, updateParams, toggleOption } = slice.actions;
export default slice.reducer;
