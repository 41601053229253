import React, { useState } from 'react';
import { useAppDispatch, useAppSelector } from 'hooks';
import { Link, NavLink as RouterNavLink } from 'react-router-dom';
import {
  Col,
  Collapse,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  NavLink,
  Row,
  UncontrolledDropdown,
} from 'reactstrap';

import quickMenus, { QuickMenuItem } from '~constants/quickMenus';

import Icon, { faBars } from '~components/Icon';

import { selectQuickMenuNarrow, toggleQuickMenu } from '~main/reducers/app';

import {
  selectAuthenticatedUser,
  selectCompanyDefaults,
  selectCompanySettings,
  selectUserTags,
} from '~main/selectors/login';

const QuickMenu: React.FC = () => {
  const dispatch = useAppDispatch();
  const user = useAppSelector(selectAuthenticatedUser);
  const settings = useAppSelector(selectCompanySettings);
  const companyDefaults = useAppSelector(selectCompanyDefaults);
  const tags = useAppSelector(selectUserTags);
  const narrow = useAppSelector(selectQuickMenuNarrow);
  const [expanded, setExpanded] = useState(quickMenus(user, settings, companyDefaults).map(() => false));
  const [openMenuIndex, setOpenMenuIndex] = useState<number | null>(null);

  const toggleExpand = (index: number) => {
    const newExpanded = [...expanded];
    newExpanded[index] = !newExpanded[index];
    setExpanded(newExpanded);
  };

  const setNarrow = (toggle: boolean) => dispatch(toggleQuickMenu(toggle));

  const renderChildren = (items: Array<QuickMenuItem>, isFlyout = false) => {
    if (isFlyout) {
      return (
        <DropdownMenu>
          {items.map(({ caption, link }, childIndex) => (
            <DropdownItem key={childIndex} tag={RouterNavLink} to={link} className="quick-menu-dropdown-item">
              {caption}
            </DropdownItem>
          ))}
        </DropdownMenu>
      );
    } else {
      return (
        <ul>
          {items.map(({ caption, link = null, children = [], authTag, isFlyout = false }, index) => {
            const isAuthorized = !authTag || tags.includes(authTag);
            if (!isAuthorized) return null;

            if (isFlyout && children.length > 0) {
              // Render child list as flyout
              return (
                <UncontrolledDropdown
                  nav
                  inNavbar
                  onMouseEnter={() => setOpenMenuIndex(index)}
                  onMouseLeave={() => setOpenMenuIndex(null)}
                  toggle={() => setOpenMenuIndex(openMenuIndex !== index ? index : null)}
                  isOpen={openMenuIndex === index}
                  key={index}
                  direction="end"
                >
                  <DropdownToggle nav className={`quick-menu-item child`}>
                    {caption}
                  </DropdownToggle>
                  {renderChildren(children, true)}
                </UncontrolledDropdown>
              );
            } else {
              // Render normal quick menu list
              return (
                <li key={index} className="quick-menu-item child">
                  {link ? (
                    <NavLink to={link} tag={Link}>
                      {caption}
                    </NavLink>
                  ) : (
                    caption
                  )}
                  {children.length > 0 && renderChildren(children, false)}
                </li>
              );
            }
          })}
        </ul>
      );
    }
  };

  const renderMenu = () => (
    <>
      <div
        className="d-flex align-items-center justify-content-between p-2 header"
        onClick={() => setNarrow(!narrow)}
        role="button"
      >
        <Icon icon={faBars} className="text-white" />
        <div>Quick Access</div>
        <div></div>
      </div>
      {quickMenus(user, settings, companyDefaults).map(
        ({ caption, icon = null, iconTag, children = [], authTag = null }, index) =>
          (!authTag || (authTag && tags.includes(authTag))) && (
            <Row key={index} className="py-2 gx-0 ms-1" role="button">
              <Col sm={3} className="text-center px-2" onClick={() => toggleExpand(index)}>
                {icon && (
                  <div className={`quick-menu-icon ${iconTag}`}>
                    <Icon icon={icon} className="text-white" />
                  </div>
                )}
              </Col>
              <Col className="quick-menu-item parent pt-1">
                <span className="quick-header" onClick={() => toggleExpand(index)}>
                  {caption}
                </span>
                {children.length > 0 && (
                  <Collapse className="qm-collapse" isOpen={expanded[index]}>
                    {renderChildren(children)}
                  </Collapse>
                )}
              </Col>
            </Row>
          ),
      )}
      ,
    </>
  );

  const renderNarrowMenu = () => (
    <>
      <div
        className="d-flex align-items-center justify-content-center header"
        onClick={() => setNarrow(!narrow)}
        role="button"
      >
        <Icon icon={faBars} className="text-white" />
      </div>
      {quickMenus(user, settings, companyDefaults).map(
        ({ icon = null, iconTag, authTag = null }, index) =>
          (!authTag || (authTag && tags.includes(authTag))) && (
            <div key={index} className="d-flex align-items-center justify-content-center py-2 gx-0">
              {icon && (
                <div className={`quick-menu-icon ${iconTag}`}>
                  <Icon icon={icon} className="text-white " />
                </div>
              )}
            </div>
          ),
      )}
    </>
  );

  return tags.includes('quickaccess:View') ? (
    <Col className={`quick-menu${narrow ? ' narrow' : ''}`}>
      {!narrow && renderMenu()}
      {narrow && renderNarrowMenu()}
    </Col>
  ) : null;
};

export default QuickMenu;
