import React from 'react';
import { Col } from 'reactstrap';
import classNames from 'classnames';

import { formatDuration } from '~libs/dayjs';
import { Event, getEventRange, getEventTotalsRange } from '~weekly-planner/lib/common';

import Icon, { faBusinessTime, faCalculator, faRoute, faUserNurse } from '~components/Icon';

interface ComponentProps {
  type: string;
  events: Event[];
  col?: number;
  direction?: 'left' | 'right';
}

const TotalCard: React.FC<ComponentProps> = ({ type, events = [], col = 2, direction = 'left' }) => {
  const className = classNames('total-card d-flex align-items-center justify-content-between', {
    type: true,
    'flex-row-reverse': direction === 'right',
  });

  let icon;
  let title;

  const duration = getEventTotalsRange(events, type);
  let start = formatDuration(Math.round(duration[0]));
  let end = duration.length > 1 ? formatDuration(Math.round(duration[1])) : null;
  let suffix = '';
  let tooltipTitle = 'Duration';

  switch (type) {
    case 'simple': {
      icon = faCalculator;
      title = 'All Totals';
      break;
    }

    case 'predicted': {
      icon = faUserNurse;
      title = 'Predicted Shift';
      break;
    }

    case 'appointments': {
      icon = faBusinessTime;
      title = 'Appointment Totals';
      break;
    }

    case 'distance': {
      icon = faRoute;
      tooltipTitle = 'Distance';
      title = 'Total Distance';
      suffix = ' kms';

      // Return distance values when distance type total
      const distance = getEventRange(events, 'distance', 'travel');
      start = Math.round(distance[0]).toString();
      end = distance.length > 1 ? Math.round(distance[1]).toString() : null;
      break;
    }

    default: {
      break;
    }
  }

  const caption = `~ ${start}${end ? ` - ${end}` : ''}${suffix}`;

  return (
    <div className={className}>
      <Col>
        <div className="d-flex align-items-center justify-content-end" title={`${tooltipTitle} - ${caption}`}>
          <div>{caption}</div>
          <Col sm={col} className="mx-1 text-center" title={title ?? ''}>
            {icon && <Icon icon={icon} />}
          </Col>
        </div>
      </Col>
    </div>
  );
};

export default TotalCard;
