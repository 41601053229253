import React, { CSSProperties } from 'react';
import { useAppSelector, useAppDispatch } from 'hooks';
import { Col, Input } from 'reactstrap';
import classNames from 'classnames';

import {
  Availability,
  Event,
  formatAvailabilityDisplayString,
  formatDistanceDisplayString,
  isInRange,
  isUserAvailable,
  maxDistance,
} from '~weekly-planner/lib/common';

import { updateBiddingWorkers } from '~weekly-planner/reducers/weeklyPlanner';
import { selectFocusedAppointment, selectOrderBy } from '~weekly-planner/selectors';

interface ComponentProps {
  workerId: string | undefined;
  events: Event[];
  availability: Availability;
}

const FocusAppointmentTotal: React.FC<ComponentProps> = ({ workerId, events = [], availability }) => {
  const dispatch = useAppDispatch();

  const { appointment: focusedAppointment, isBiddingView, newBidders } = useAppSelector(selectFocusedAppointment);
  const { start_time, end_time, bidding_user_ids, rejected_user_ids, is_bidding } = focusedAppointment || {};
  const { distance: isDistance } = useAppSelector(selectOrderBy);

  const className = classNames('total-card d-flex align-items-center justify-content-between', {
    'flex-row-reverse': 'left',
  });

  const availabilityStyle: CSSProperties = {
    color: isUserAvailable(availability, start_time, end_time) ? 'green' : 'red',
  };

  const distanceStyle: CSSProperties = {
    color: isInRange(maxDistance(events)) ? 'green' : 'red',
  };

  const biddingStyle: CSSProperties = {
    color: is_bidding && Array.isArray(rejected_user_ids) && rejected_user_ids.includes(workerId) ? 'red' : 'green',
  };

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const workerIds = [workerId];
    dispatch(updateBiddingWorkers({ workerIds, isChecked: event.target.checked }));
  };

  return (
    <div className={className}>
      {!isDistance ? (
        <Col>
          <div className="d-flex align-items-center justify-content-end fw-bold" style={availabilityStyle}>
            {formatAvailabilityDisplayString(availability)}
          </div>
        </Col>
      ) : (
        <Col>
          <div className="d-flex align-items-center justify-content-end fw-bold" style={distanceStyle}>
            {formatDistanceDisplayString(maxDistance(events))}
          </div>
        </Col>
      )}
      {isBiddingView &&
        (Array.isArray(rejected_user_ids) && rejected_user_ids.includes(workerId) ? (
          <div className="d-flex align-items-center justify-content-end fw-bold" style={biddingStyle}>
            REJECTED
          </div>
        ) : Array.isArray(bidding_user_ids) && bidding_user_ids.includes(workerId) ? (
          <div className="d-flex align-items-center justify-content-end fw-bold" style={biddingStyle}>
            BIDDING
          </div>
        ) : (
          <Input
            type="checkbox"
            name={`bidding-${workerId}`}
            id={workerId ?? ''}
            style={{ width: '1.5rem', height: '1.5rem', margin: 2 }}
            checked={Array.isArray(newBidders) && newBidders.includes(workerId)}
            onChange={handleCheckboxChange}
          />
        ))}
    </div>
  );
};

export default FocusAppointmentTotal;
