import { FieldProps } from 'tsx/components/FormFields';

import { deriveChargeableCancellation, deriveClientAddress } from '~appointments/lib/derive';
import { validateCancelReason, validateCancelReasonNote } from '~appointments/lib/validate';

import FormInputChargeableCancellation from '~appointments/components/FormInputChargeableCancellation';
import FormInputRepeatDates from '~appointments/components/FormInputRepeatDates';

import { selectLookupAppointmentCancellationReasons } from '~appointments/reducers/cancellationReasons';

const commonFields: FieldProps = {
  id: {
    type: 'hidden',
  },
  status: {
    type: 'readonly',
    field: 'status_id',
    caption: 'Status',
    colSize: 4,
    options: [
      { id: 1, name: 'Pending Carer Approval' },
      { id: 2, name: 'Rejected by Carer' },
      { id: 3, name: 'Changed by Office' },
      { id: 4, name: 'Changed by Carer' },
      { id: 5, name: 'Accepted' },
      { id: 6, name: 'Started' },
      { id: 7, name: 'Completed' },
      { id: 8, name: 'Cancelled' },
    ],
    order: 10,
  },
  client_name: {
    type: 'readonly',
    field: 'client.full_name',
    caption: 'Client / Facility',
    deriveDescription: deriveClientAddress,
    className: 'text-left',
    disabled: true,
    colSize: 4,
    order: 30,
  },
  cancellation_reason: {
    type: 'select',
    caption: 'Reason',
    colSize: 6,
    selectorOptions: selectLookupAppointmentCancellationReasons,
    validateOnSave: validateCancelReason,
    order: 40,
  },
  cancellation_reason_note: {
    type: 'textarea',
    caption: 'Note',
    colSize: 6,
    validateOnSave: validateCancelReasonNote,
    order: 50,
  },
  add_crm_note: {
    type: 'checkbox',
    description: 'Add this note to CRM',
    order: 60,
  },
};

const singleSpecific: FieldProps = {
  date: {
    type: 'date',
    caption: 'Appointment Date',
    disabled: true,
    colSize: 4,
    order: 20,
  },
  chargeable: {
    type: 'custom',
    caption: 'Chargeable Cancellation',
    colSize: 6,
    component: FormInputChargeableCancellation,
    deriveValue: deriveChargeableCancellation,
    order: 70,
  },
  notify_care_worker: {
    type: 'switch',
    caption: 'Notify Care Worker',
    defaultValue: false,
    order: 80,
  },
};

const repeatSpecific: FieldProps = {
  repeat_dates: {
    type: 'custom',
    component: FormInputRepeatDates,
    dependencies: ['repeat', 'repeat_start', 'repeat_end'],
    caption: 'Repeat Dates',
    colSize: 4,
    order: 20,
    disabled: true,
  },
};

const appointmentFields: FieldProps = {
  ...commonFields,
  ...singleSpecific,
};

const repeatFields: FieldProps = {
  ...commonFields,
  ...repeatSpecific,
};

export { appointmentFields, repeatFields };
