import { combineReducers } from '@reduxjs/toolkit';

import userAllowances from './userAllowances';
import userDepartments from './userDepartments';
import userLevels from './userLevels';
import userPayGroups from './userPayGroups';
import userPayLevels from './userPayLevels';
import users from './users';

export const careWorkers = { userAllowances, userDepartments, userLevels, userPayGroups, userPayLevels, users };

export default combineReducers(careWorkers);
