import React, { CSSProperties, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'hooks';
import { Container } from 'reactstrap';
import classNames from 'classnames';
import { throttle } from 'lodash';

import { WeeklyPlannerDateRangeOptions as RangeOptions } from '~constants/maps';

import { Appointment, getGridColumn } from '~weekly-planner/lib/common';
import { onUnallocatedDragEnd } from '~weekly-planner/lib/drag-drop';
import { buildAssignChange } from '~weekly-planner/lib/simulate';

import { DraggableItem, DroppableArea } from '~components/DragAndDrop';
import Icon, { faChevronLeft, faChevronRight, faMagnifyingGlass, faUserPen } from '~components/Icon';
import Spinner from '~components/Spinner';
import UnallocatedCard from '~weekly-planner/components/Card/UnallocatedCard';
import Search from '~weekly-planner/components/SideBar/UnallocatedAppointments/Search';
import UnallocatedViewMenu from '~weekly-planner/components/SideBar/UnallocatedAppointments/UnallocatedViewMenu';

import { getAssignableUsers } from '~weekly-planner/actions/appointments';
import { getAll as getUnallocated } from '~weekly-planner/actions/appointments/unallocated';
import { simulate } from '~weekly-planner/actions/unsaved';

import { clear, updateParams } from '~weekly-planner/reducers/appointments/unallocated';
import { focusAppointment, update } from '~weekly-planner/reducers/weeklyPlanner';

import { selectFocusedAppointment, selectParams as selectGlobalParams } from '~weekly-planner/selectors';
import { selectHasLoaded, selectLoading, selectParams } from '~weekly-planner/selectors/appointments/unallocated';
import {
  selectSimulatedWorkerChanges as selectSimulatedChanges,
  selectUnallocated,
} from '~weekly-planner/selectors/simulate';

interface Filters {
  client_id: number | null;
  user_id: number | string | null;
  suburb: string | null;
  date: string | null;
}

const UnallocatedAppointments: React.FC = React.memo(() => {
  const dispatch = useAppDispatch();
  const params = useAppSelector(selectParams);
  const globalParams = useAppSelector(selectGlobalParams);

  const loading = useAppSelector(selectLoading);
  const hasLoaded = useAppSelector(selectHasLoaded);
  const appointments = useAppSelector(selectUnallocated);
  const weeklyPlannerData = useAppSelector(selectSimulatedChanges);
  const focused = useAppSelector(selectFocusedAppointment);

  const isLoading = loading === 'pending';
  const { week_start } = globalParams;
  const { limit } = params;

  const [openAppointment, setOpenAppointment] = useState<number | null>(null);
  const [isSearchOpen, setSearchOpen] = useState<boolean>(false);
  const [filters, setFilters] = useState<Filters>({
    client_id: null,
    user_id: null,
    suburb: null,
    date: null,
  });

  const [isOver, setIsOver] = useState(false);
  const [isOrigin, setIsOrigin] = useState(false);

  const handleDragOverChange = (isOver: boolean, current?: any) => {
    setIsOver(isOver);
    setIsOrigin(current?.containerId === 'unallocatedAppts');
  };

  // Load on scroll
  const containerRef = useRef<HTMLDivElement>(null);
  const onScroll = useCallback(
    throttle(async () => {
      if (!containerRef.current || isLoading) return;

      const { scrollHeight, scrollTop, clientHeight } = containerRef.current;
      const nearBottom = scrollHeight - (scrollTop + clientHeight) < 50;

      if (!isLoading && nearBottom && limit <= appointments.length) {
        dispatch(updateParams({ ...params, limit: limit + 30 }));
      }
    }, 200),
    [limit, dispatch, isLoading],
  );

  useEffect(() => {
    const container = containerRef.current;
    if (!container) return;

    container.addEventListener('scroll', onScroll, { passive: true });
    return () => container.removeEventListener('scroll', onScroll);
  }, [onScroll]);

  // Fetch data on param change
  useEffect(() => {
    if (!isLoading && week_start && limit) {
      dispatch(getUnallocated({ week_start: week_start, limit }));
    }
  }, [week_start, limit, hasLoaded]);

  // Update params on date change
  useEffect(() => {
    if (!hasLoaded) {
      dispatch(updateParams({ ...params, week_start }));
    }
  }, [hasLoaded, week_start]);

  // unmount & clear reducer
  useEffect(() => {
    dispatch(updateParams({ week_start: globalParams.week_start }));
    return () => {
      dispatch(clear([]));
    };
  }, []);

  useMemo(() => {
    if (!focused && openAppointment) setOpenAppointment(null);
  }, [focused]);

  const onBulkAssign = () => {
    const targets = appointments.filter(({ user_id, user }) => {
      if (!user_id || !user) return false;
      const { is_qualified, is_available } = user;
      if (!is_qualified || !is_available) return false;
      return true;
    });

    const change = buildAssignChange(weeklyPlannerData, targets);
    dispatch(simulate({ ...change, week_start }));
  };

  const onUnallocatedAppointmentFocus = (key: number | null) => {
    if (key) {
      if (openAppointment === key) {
        dispatch(focusAppointment(null));
      } else {
        dispatch(getAssignableUsers({ id: key }));
        const targetAppointment = appointments.find((x) => x.key === key);
        dispatch(focusAppointment({ appointment: targetAppointment, isAvailabilityView: true, isBiddingView: false }));
        dispatch(update({ date: targetAppointment?.date, rangeType: RangeOptions.DAILY }));
      }
      setOpenAppointment((prevKey) => (prevKey === key ? null : key));
    }
  };

  const renderAppointments = (appointments: Appointment[]) => {
    if (appointments.length > 0) {
      return appointments.map((appointment, index) => {
        const { key } = appointment;
        if (!key) return <></>;

        const style: CSSProperties = {
          gridRow: 1,
          ...getGridColumn(appointment.start_time, appointment.end_time, 1),
        };

        return (
          <div key={index} className="unallocated-row">
            <div className="unallocated-card-container">
              <DraggableItem
                id={key}
                data={{
                  appointment,
                  containerId: 'unallocatedAppts',
                  containerStyle: style,
                  onItemDragEnd: onUnallocatedDragEnd,
                  weeklyPlannerData,
                  week_start,
                  dispatch,
                }}
              >
                <UnallocatedCard
                  appointment={appointment}
                  isActionMenuOpen={openAppointment === appointment.key}
                  anyActionMenuOpen={!!openAppointment}
                />
              </DraggableItem>
            </div>
            <div className="action-menu-container">
              <div className="action-toggle" onClick={() => onUnallocatedAppointmentFocus(appointment.key)}>
                <Icon icon={openAppointment === appointment.key ? faChevronLeft : faChevronRight} />
              </div>
            </div>
          </div>
        );
      });
    }
    return <p className="no-result">No results found.</p>;
  };

  // Filter appointments using search filters
  const filteredAppointments: Appointment[] = appointments.filter((appt) => {
    if (filters.client_id && appt.client.id !== filters.client_id.toString()) return false;
    if (filters.suburb && appt.client.suburb !== filters.suburb) return false;
    if (filters.date && appt.repeat_next_date !== filters.date) return false;
    if (filters.user_id && appt.user?.id !== filters.user_id) return false;
    return true;
  });

  const className = classNames('card-container', {
    'is-dragging-over': isOver,
    'is-dragging-origin': isOrigin,
  });

  const hasData = appointments.length > 0;

  return (
    <DroppableArea id={'unallocatedAppts'} onDragOverChange={handleDragOverChange} className={className}>
      <div ref={containerRef} className={className}>
        <Container className="unallocated-appointments inner-content limited">
          <div className="nav card-header">
            <Icon
              icon={faMagnifyingGlass}
              className="search-icon me-2"
              title="Search Appointments"
              onClick={() => setSearchOpen(!isSearchOpen)}
            />
            <Icon icon={faUserPen} className="me-2" title="Assign Preferred Workers" onClick={onBulkAssign} />
            <UnallocatedViewMenu />
          </div>
          <Search isOpen={isSearchOpen} appointments={appointments} weekStart={week_start} setFilters={setFilters} />
          {hasData ? (
            <div>{renderAppointments(filteredAppointments)}</div>
          ) : (
            hasLoaded && <p className="no-result">No appointments found.</p>
          )}
          <Spinner loading={isLoading} className="p-5" />
        </Container>
      </div>
    </DroppableArea>
  );
});

UnallocatedAppointments.displayName = 'UnallocatedAppointments';
export default UnallocatedAppointments;
