import React, { CSSProperties, ReactNode } from 'react';
import { useAppSelector } from 'hooks';
import classNames from 'classnames';
import { Dayjs } from 'dayjs';

import { dateInputFieldFormat } from '~libs/dayjs';
import { Client, Event, Worker } from '~weekly-planner/lib/common';

import { Cell } from '~weekly-planner/components/Main/Daily';

import { selectFocusedWorkerId } from '~weekly-planner/selectors';

interface ComponentProps {
  headerCell: ReactNode;
  dataObj: Worker | Client;
  date: Dayjs;
  events: Event[];
  style?: CSSProperties;
}

const Row: React.FC<ComponentProps> = ({ headerCell, dataObj, date, events, style }) => {
  const { id } = dataObj;
  const focusedWorkerId = useAppSelector(selectFocusedWorkerId);

  const className = classNames('worker-row', {
    focus: focusedWorkerId && parseInt(dataObj.id) === focusedWorkerId,
  });

  return (
    <div id={id} className={className} key={`${id}`} data-date={date.format(dateInputFieldFormat)}>
      {headerCell}
      <Cell dataObj={dataObj} date={date} events={events} style={style} startDate={date.weekday(0)} />
    </div>
  );
};

export default Row;
