import { buildSlice, CommonState } from '~libs/reduxUtils';
import { Client } from '~weekly-planner/lib/common';

import { typePrefix, getAll } from '~weekly-planner/actions/clients';

const initialState: CommonState<Client> = {
  loading: 'idle',
  hasLoaded: false,
  error: null,
  rows: [],
};

const slice = buildSlice<CommonState<Client>, Client>(typePrefix, initialState, {
  commonConfig: { getAll },
});

export const { clear, updateParams } = slice.actions;
export default slice.reducer;
