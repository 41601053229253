import { store } from 'store';
import { catchAllStartDate, getDayString } from 'tsx/libs/dayjs';

import { getAll as getAppointmentServiceTypes } from '~appointments/actions/serviceTypes';
import { getClientPackageSchedules } from '~main/actions/clients/clientPackageSchedules';
import { getAll as getNDISSupportItems } from '~main/actions/ndisSupportItems';

export const onChangeServiceType: (values: any) => any = ({ client_id, service_type_id }) => {
  // On service type change, force qualifications to equal default service qualifications.
  const state = store.getState();
  const { serviceTypes } = state.appointments;
  const serviceType = serviceTypes.rows.find(({ id }) => parseInt(service_type_id) === id);

  let qualification_level: number[] = [];
  if (serviceType) {
    qualification_level = serviceType?.default_qualification_ids ?? [];

    // If NDIS Support Item, query for NDIS Support Items from API
    if (serviceType.ndis_support_item > 0 && client_id) {
      store.dispatch(
        getNDISSupportItems({
          client_id,
          service_type_id,
          support_item_id: serviceType.ndis_support_item,
        }),
      );
    }
  }
  return {
    qualification_level,
  };
};

export const onChangeClient: (values: any) => any = async ({ client_id, date }) => {
  const state = store.getState();
  const { clients } = state.main;
  const client = clients.rows.find(({ id }) => parseInt(client_id) === id);

  // If client exists, collect and match current package schedules to existing default package
  if (client) {
    // Must wait for response in this case otherwise data is not up to date
    const { payload } = await store.dispatch(getClientPackageSchedules({ client_id, date }));
    const defaultPackage = payload?.data.find(
      ({ package: pkg }: any) => pkg.id === client?.default_package?.id,
    )?.package;
    return { package_fixed_id: defaultPackage?.id.toString() ?? '' };
  }
};

export const onChangePackage: (values: any) => any = ({ package_fixed_id }) => {
  if (package_fixed_id) {
    store.dispatch(getAppointmentServiceTypes({ package_id: package_fixed_id }));
  }
};

export const onChangeUserOrDate: (values: any) => any = ({ user_id, repeat_start }) => {
  if (user_id && repeat_start) {
    return { date: catchAllStartDate, repeat: true, repeat_weekday: getDayString(repeat_start) };
  }
};
