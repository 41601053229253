import React from 'react';
import { useAppSelector } from 'hooks';
import { Col, Input, Label, Row } from 'reactstrap';

import { selectUserTags } from '~main/selectors/login';

import * as FormInput from './Inputs';
import { FieldProp } from './props';

interface ComponentProps extends FieldProp {
  id: string;
  authTag?: string;
  fieldId: string;
  value?: any;
  disabled?: boolean;
  invalid?: boolean;
  size?: 'sm' | 'lg';
  handleChange: (name: string, value: any) => any;
}

const FormField: React.FC<ComponentProps> = ({
  id,
  authTag,
  value = '',
  disabled = false,
  invalid = false,
  handleChange,
  type,
  caption = '',
  labelCaption = '',
  link = '',
  component,
  options,
  initialOption,
  selectorOptions,
  selectorActions,
  selectorParams,
  required = false,
  placeholder,
  description,
  warning,
  show,
  colSize,
  setFieldValue,
  clearable,
  ...props
}) => {
  const tags = useAppSelector(selectUserTags);
  const authorised = !authTag || (authTag && tags.includes(authTag));
  let { className = '' } = props;

  const isDisabled = disabled || !authorised;

  let input;
  switch (type) {
    case 'readonly': {
      input = (
        <FormInput.ReadOnly
          {...props}
          colSize={colSize}
          type={type}
          name={id}
          id={id}
          value={value}
          options={options}
          selectorOptions={selectorOptions}
          className="ps-2 pe-2"
          link={link}
        />
      );
      break;
    }
    case 'multi-readonly': {
      input = (
        <FormInput.MultiReadOnly
          {...props}
          type={type}
          name={id}
          id={id}
          value={value}
          options={options}
          selectorOptions={selectorOptions}
          className="ps-2 pe-2"
        />
      );
      break;
    }
    case 'disabled': {
      input = <FormInput.ReadOnly type={'readonly'} name={id} id={id} value={'-'} className="ps-2 pe-2" />;
      break;
    }
    case 'multi-checkbox': {
      input = (
        <FormInput.MultiCheckbox
          type={type}
          id={id}
          name={id}
          value={value}
          onChange={handleChange}
          colSize={colSize}
          disabled={isDisabled}
          options={options}
          selectorOptions={selectorOptions}
          {...props}
        />
      );

      break;
    }
    case 'checkbox': {
      input = (
        <>
          <Input
            type={type}
            name={id}
            id={id}
            value={value}
            placeholder={placeholder}
            onChange={({ target: { name, value } }) => handleChange && handleChange(name, value)}
            disabled={isDisabled}
          />
          <Label for={id} className="ms-2 mb-0">
            {description}
          </Label>
        </>
      );
      break;
    }
    case 'select': {
      input = (
        <FormInput.Select
          type={type}
          name={id}
          id={id}
          clearable={clearable}
          value={value}
          onChange={handleChange}
          disabled={isDisabled}
          options={options}
          selectorOptions={selectorOptions}
        />
      );
      break;
    }
    case 'select-lazy': {
      input = (
        <FormInput.SelectLazy
          type={type}
          id={id}
          name={id}
          value={value}
          onChange={handleChange}
          clearable={clearable}
          disabled={isDisabled}
          initialOption={initialOption}
          selectorOptions={selectorOptions}
          selectorActions={selectorActions}
          selectorParams={selectorParams}
        />
      );
      break;
    }
    case 'custom': {
      input = <></>;
      if (component) {
        const Component = component;
        input = (
          <Component
            {...props}
            type={type}
            id={id}
            name={id}
            value={value}
            onChange={handleChange}
            className={className}
            options={options}
            selectorOptions={selectorOptions}
            placeholder={placeholder}
            disabled={isDisabled}
          />
        );
      }
      break;
    }
    case 'switch': {
      input = (
        <FormInput.Switch
          {...props}
          type={type}
          id={id}
          name={id}
          value={value}
          onChange={handleChange}
          disabled={isDisabled}
        />
      );

      break;
    }

    case 'time': {
      input = (
        <FormInput.Time
          {...props}
          type={type}
          id={id}
          name={id}
          value={value}
          placeholder={placeholder}
          onChange={handleChange}
          disabled={isDisabled}
        />
      );

      break;
    }

    case 'date': {
      input = (
        <FormInput.DateInput
          type={type}
          id={id}
          name={id}
          value={value}
          placeholder={placeholder}
          onChange={handleChange}
          disabled={isDisabled}
        />
      );

      break;
    }

    case 'currency': {
      input = (
        <FormInput.Currency
          type={type}
          id={id}
          name={id}
          value={value}
          onChange={handleChange}
          disabled={disabled}
          setFieldValue={setFieldValue}
        />
      );

      break;
    }

    case 'radio': {
      input = (
        <FormInput.Radio
          {...props}
          type={type}
          id={id}
          name={id}
          value={value}
          disabled={disabled}
          setFieldValue={setFieldValue}
          options={options}
          selectorOptions={selectorOptions}
          onChange={handleChange}
        />
      );
      break;
    }

    case 'alert-info':
    case 'alert-success':
    case 'alert-danger':
    case 'alert-warning': {
      const color = type.split('-')[1];
      input = <FormInput.AlertMessage type={type} id={id} name={id} color={color} value={value} />;
      break;
    }
    default: {
      input = (
        <Input
          type={type}
          name={id}
          id={id}
          value={value ?? ''}
          placeholder={placeholder}
          onChange={({ target: { name, value } }) => handleChange && handleChange(name, value)}
          disabled={isDisabled}
        />
      );

      break;
    }
  }

  const isVisible = show !== false && type !== 'hidden';

  if (type === 'disabled') className += ' bg-light';

  return (
    <>
      {isVisible && (
        <Row className={className}>
          <Col sm={3}>
            {caption && (
              <Label for={id} className="font-weight-bold">
                {caption}:{required ? ' *' : ''}
              </Label>
            )}
            {labelCaption && <div className="pe-2 small text-info"> {labelCaption}</div>}
          </Col>
          <Col sm={colSize ?? undefined} className={invalid ? 'border border-2 rounded border-danger p-2' : ''}>
            {input}
            {description && type !== 'checkbox' && <div className="ps-2 pe-2 small text-info">{description}</div>}
            {warning && type !== 'checkbox' && <div className="ps-2 pe-2 small text-danger">{warning}</div>}
          </Col>
        </Row>
      )}
    </>
  );
};

export default FormField;
