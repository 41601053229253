import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'hooks';
import ReactSelect from 'react-select';
import {
  Collapse,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Label,
  Spinner as ReactSpinner,
} from 'reactstrap';
import dayjs, { Dayjs } from 'dayjs';

import { WeeklyPlannerDatasetOptions, WeeklyPlannerDateRangeOptions } from '~constants/maps';

import { dateInputFieldFormat } from '~libs/dayjs';
import { useSearch } from '~libs/searchParams';

import searchFields from '~weekly-planner/forms/search';

import Button from '~components/Button';
import ContainerHeader from '~components/ContainerHeader';
import { SearchBar } from '~components/FormFields/Search';
import Icon, { faEye, faEyeSlash, faGear, faInfoCircle, faMagnifyingGlass } from '~components/Icon';
import Modal from '~components/Modal';
import WeekPicker from '~components/WeekPicker';
import {
  AppointmentExceptions,
  Changes,
  HoldPeriods,
  ServiceColour,
  UserExceptions,
} from '~weekly-planner/components/Modal';

import { focusAppointment, toggleOption, update, updateParams } from '~weekly-planner/reducers/weeklyPlanner';

import { selectDate, selectOptions, selectRangeType, selectViewType } from '~weekly-planner/selectors';
import { selectExceptions, selectOnHold } from '~weekly-planner/selectors/appointments';
import { selectLoading as selectUnsavedLoading } from '~weekly-planner/selectors/unsaved';
import { selectRunningLate, selectLoading as selectWPLoading } from '~weekly-planner/selectors/users';

interface ComponentProps {
  onRefresh?: (searchParams?: { repeat: boolean; sort: string[]; limit: number }) => void;
}

const Banner: React.FC<ComponentProps> = ({ onRefresh }) => {
  const viewOptions = Object.values(WeeklyPlannerDatasetOptions).map((option) => ({
    value: option,
    label: option,
  }));
  const dateRangeOptions = Object.values(WeeklyPlannerDateRangeOptions).map((option) => ({
    value: option,
    label: option,
  }));

  const dispatch = useAppDispatch();

  const {
    appointmentFlexibility,
    workerAvailability,
    workerQualifications,
    workerAwardAlerts,
    totalSimple,
    totalAppointments,
    totalPredicted,
    totalDistance,
    showTravel,
    showBreaks,
    showTeaBreaks,
  } = useAppSelector(selectOptions);
  const { rescheduled, cancelled } = useAppSelector(selectExceptions);
  const onHold = useAppSelector(selectOnHold);
  const { appointmentsBeforeNow, appointmentsNow, notLoggedIn } = useAppSelector(selectRunningLate);
  const viewType = useAppSelector(selectViewType);
  const rangeType = useAppSelector(selectRangeType);
  const date = useAppSelector(selectDate);

  const exceptionCount = rescheduled.length + cancelled.length;
  const onHoldCount = onHold.length;
  const runningLateCount = appointmentsBeforeNow.length + appointmentsNow.length + notLoggedIn.length;

  const initialSearchState: any = {};

  const [isChangesOpen, setIsChangesOpen] = useState(false);
  const [isViewOpen, setIsViewOpen] = useState(false);
  const [isInfoOpen, setIsInfoOpen] = useState(false);
  const [isColourEditOpen, setColourEditOpen] = useState<boolean>(false);
  const [isSearchBarOpen, setIsSearchBarOpen] = useState<boolean>(false);
  const [showInfoModal, setShowInfoModal] = useState<boolean>(false);
  const [modalProperties, setShowInfoModalProperties] = useState<{
    header: string;
    body: 'late' | 'hold' | 'exception' | null;
  }>({
    header: '',
    body: null,
  });

  const [searchParams, setSearchParams] = useSearch('search', initialSearchState);
  const search = (values: { [key: string]: any }) => setSearchParams(values);
  const weeklyPlannerDataLoading = useAppSelector(selectWPLoading);
  const unsavedLoading = useAppSelector(selectUnsavedLoading);
  const isLoading = weeklyPlannerDataLoading === 'pending' || unsavedLoading === 'pending';

  const renderItem = (onClick: (value?: any) => void, iconToggle: boolean, label: any, showIcon = true) => (
    <DropdownItem toggle={false} onClick={onClick} className="d-flex align-items-center">
      <Label className="me-2 mb-0">{label}</Label>
      {showIcon && <Icon icon={iconToggle ? faEye : faEyeSlash} className="mt-1 ms-auto" />}
    </DropdownItem>
  );

  const onDateChange = (value: Dayjs) => {
    const isDaily = rangeType === WeeklyPlannerDateRangeOptions.DAILY;
    const weekStart = value.weekday(0);

    dispatch(update({ date: value.format(dateInputFieldFormat) }));

    dispatch(
      updateParams({
        week_start: weekStart.format(dateInputFieldFormat),
        week_end: isDaily ? weekStart.add(1, 'day').format(dateInputFieldFormat) : null,
        limit: 30,
      }),
    );
  };

  const onTeaBreakVisibilityChange = () => {
    const newValue = !showTeaBreaks;
    dispatch(toggleOption({ key: 'showTeaBreaks', toggle: newValue }));
    dispatch(
      updateParams({
        include: { tea_break: newValue },
      }),
    );
  };

  const changeView = (value: any) => {
    dispatch(focusAppointment(null));
    dispatch(update({ viewType: value }));
    dispatch(updateParams({ limit: 30 }));
  };

  const changeRange = (value: any) => {
    dispatch(focusAppointment(null));
    dispatch(update({ rangeType: value }));

    const isDaily = value === WeeklyPlannerDateRangeOptions.DAILY;
    const weekStart = dayjs(date).weekday(0);
    dispatch(
      updateParams({
        week_start: weekStart.format(dateInputFieldFormat),
        week_end: isDaily ? weekStart.add(1, 'day').format(dateInputFieldFormat) : null,
        limit: 30,
      }),
    );
  };

  useEffect(() => {
    if (searchParams !== initialSearchState) {
      onRefresh?.(searchParams);
    }
  }, [searchParams]);

  return (
    <>
      <ContainerHeader className="weekly-planner">
        <span>Weekly Planner</span>
      </ContainerHeader>
      <Changes
        isOpen={isChangesOpen}
        onClose={() => {
          setIsChangesOpen(false);
          onRefresh && onRefresh();
        }}
      />
      <div className="nav d-flex justify-content-between bg-light p-2 mt-2 mb-2 banner">
        <div className="d-flex align-items-center ms-1">{isLoading && <ReactSpinner className="me-2" />}</div>
        <div className="d-flex">
          <ServiceColour isOpen={isColourEditOpen} onClose={() => setColourEditOpen(!isColourEditOpen)} />

          <div className="d-flex align-items-center ms-1">
            <Label className="fw-bold m-2">View:</Label>
            <ReactSelect
              id={'dataset-view-select'}
              name={'dataset-view-select'}
              options={viewOptions}
              value={viewOptions.find((option) => option.value === viewType)}
              defaultValue={viewOptions[0]}
              closeMenuOnSelect={true}
              onChange={(option) => changeView(option?.value)}
              menuPortalTarget={document.body}
              styles={{ menuPortal: (base) => ({ ...base, zIndex: 100 }) }}
            />
            <Label className="fw-bold m-2 ps-2">Range:</Label>
            <ReactSelect
              id={'dataset-range-select'}
              name={'dataset-range-select'}
              options={dateRangeOptions}
              value={dateRangeOptions.find((option) => option.value === rangeType)}
              defaultValue={dateRangeOptions[0]}
              closeMenuOnSelect={true}
              onChange={(option) => changeRange(option?.value)}
              menuPortalTarget={document.body}
              styles={{ menuPortal: (base) => ({ ...base, zIndex: 100 }) }}
            />
          </div>

          <WeekPicker onChange={onDateChange} />
          <Button size="sm" className="me-2" onClick={() => setIsSearchBarOpen(!isSearchBarOpen)}>
            <Icon className="me-2" icon={faMagnifyingGlass} />
            {isSearchBarOpen ? 'Hide' : 'Search'}
          </Button>

          <Dropdown isOpen={isViewOpen} toggle={() => setIsViewOpen(!isViewOpen)} direction="down" className="mx-2">
            <DropdownToggle size="sm" className={`settings-icon ${isViewOpen ? 'active' : ''}`}>
              <Icon icon={faGear} className="align-self-center" />
            </DropdownToggle>
            <DropdownMenu>
              <DropdownItem header>Planner Options</DropdownItem>
              <DropdownItem onClick={() => setColourEditOpen(true)}>Edit Service Colour</DropdownItem>
              <DropdownItem divider />
              <DropdownItem header className="fw-bold">
                View
              </DropdownItem>
              <DropdownItem header>Worker Details</DropdownItem>
              {renderItem(
                () => dispatch(toggleOption({ key: 'workerQualifications', toggle: !workerQualifications })),
                workerQualifications,
                'Qualifications',
              )}
              {renderItem(
                () => dispatch(toggleOption({ key: 'workerAvailability', toggle: !workerAvailability })),
                workerAvailability,
                'Availability',
              )}
              <DropdownItem header>Planner</DropdownItem>
              {renderItem(
                () => dispatch(toggleOption({ key: 'appointmentFlexibility', toggle: !appointmentFlexibility })),
                appointmentFlexibility,
                'Flexibility',
              )}
              {renderItem(
                () => dispatch(toggleOption({ key: 'showBreaks', toggle: !showBreaks })),
                showBreaks,
                'Breaks',
              )}
              {renderItem(() => onTeaBreakVisibilityChange(), showTeaBreaks, 'Tea Breaks')}

              {renderItem(
                () => dispatch(toggleOption({ key: 'showTravel', toggle: !showTravel })),
                showTravel,
                'Travel',
              )}

              <DropdownItem header>Totals</DropdownItem>
              {renderItem(
                () => dispatch(toggleOption({ key: 'workerAwardAlerts', toggle: !workerAwardAlerts })),
                workerAwardAlerts,
                'Award Alerts',
              )}
              {renderItem(
                () => dispatch(toggleOption({ key: 'totalSimple', toggle: !totalSimple })),
                totalSimple,
                'Simple',
              )}
              {renderItem(
                () => dispatch(toggleOption({ key: 'totalAppointments', toggle: !totalAppointments })),
                totalAppointments,
                'Appointments',
              )}
              {renderItem(
                () => dispatch(toggleOption({ key: 'totalPredicted', toggle: !totalPredicted })),
                totalPredicted,
                'Predicted Shift',
              )}
              {renderItem(
                () => dispatch(toggleOption({ key: 'totalDistance', toggle: !totalDistance })),
                totalDistance,
                'Distance',
              )}
            </DropdownMenu>
          </Dropdown>
          <Dropdown isOpen={isInfoOpen} toggle={() => setIsInfoOpen(!isInfoOpen)} direction="down" className="mx-1">
            <DropdownToggle size="sm" className={`settings-icon ${isInfoOpen ? 'active' : ''}`}>
              <Icon icon={faInfoCircle} className="align-self-center" />
            </DropdownToggle>
            <DropdownMenu>
              {renderItem(
                () => {
                  setShowInfoModal(true);
                  setShowInfoModalProperties({
                    header: 'On Hold',
                    body: 'hold',
                  });
                },
                onHoldCount > 0,
                `On Hold (${onHoldCount})`,
                false,
              )}
              {renderItem(
                () => {
                  setShowInfoModal(true);
                  setShowInfoModalProperties({
                    header: 'Running Late',
                    body: 'late',
                  });
                },
                runningLateCount > 0,
                `Running Late (${runningLateCount})`,
                false,
              )}
              {renderItem(
                () => {
                  setShowInfoModal(true);
                  setShowInfoModalProperties({
                    header: 'Exceptions',
                    body: 'exception',
                  });
                },
                exceptionCount > 0,
                `Exceptions (${exceptionCount})`,
                false,
              )}
            </DropdownMenu>
          </Dropdown>
        </div>
        <Modal
          isOpen={showInfoModal}
          setIsOpen={setShowInfoModal}
          header={modalProperties.header}
          scrollable
          size="xl"
          centered
        >
          {modalProperties.body === 'exception' && <AppointmentExceptions />}
          {modalProperties.body === 'late' && <UserExceptions />}
          {modalProperties.body === 'hold' && <HoldPeriods />}
        </Modal>
      </div>
      <Collapse isOpen={isSearchBarOpen}>
        <SearchBar fields={searchFields} initialValues={searchParams} storageKey="weekly-planner" onSubmit={search} />
      </Collapse>
    </>
  );
};

export default Banner;
