import React, { useState } from 'react';
import { useAppSelector } from 'hooks';
import { Card, CardBody, CardHeader, Col, Collapse, Row } from 'reactstrap';
import Icon, { faChevronDown, faChevronUp } from 'tsx/components/Icon';
import { formatDateString, formatDateTimeValue, formatDayString } from 'tsx/libs/dayjs';

import { selectAppointmentActivities } from './reducers/activities';

interface Props {
  className: string;
}

function AppointmentActivities({ className }: Props) {
  const [isOpen, setIsOpen] = useState(false);
  const rows = useAppSelector(selectAppointmentActivities);

  // Build sub-section of log details based on array of object values
  const buildDetails = (details: Array<{ field: string; from: string; to: string }>) => {
    return details.map(({ field, from, to }, index) => (
      <Row key={index} className="ms-4 mb-1">{`${field}: "${from}" to "${to}"`}</Row>
    ));
  };

  // Build rows of activities based on appointment activities within reducer.
  const buildRows = (day: string) => {
    const dayRows = rows.filter(({ date_time }) => day === formatDayString(date_time));
    return dayRows.map(({ date_time, details, type, user }, index) => {
      return (
        <Row key={index} className="mb-1">
          <Col sm={1}>{formatDateTimeValue('time', date_time)}</Col>
          <Col sm={3}>{user?.full_name ?? ''}</Col>
          <Col>
            <Row className="mb-1">{type}</Row>
            {details ? buildDetails(details) : ''}
          </Col>
        </Row>
      );
    });
  };

  // Create group of unique "days" to group activities together
  const dates = [...new Set(rows.map(({ date_time }) => formatDateString(date_time)))];
  const bodyRows = dates.map((date, index) => {
    return (
      <div key={index}>
        <Row className="fw-bold mb-1">{date}</Row>
        {buildRows(date)}
      </div>
    );
  });

  return (
    <div className={className}>
      <Card className="rounded-0">
        <CardHeader
          className="d-flex justify-content-between border-0 fw-bold"
          type={'button'}
          onClick={() => setIsOpen(!isOpen)}
        >
          <div>Activity Logs</div>
          <Icon icon={isOpen ? faChevronUp : faChevronDown} className="ms-2" />
        </CardHeader>
        <Collapse isOpen={isOpen}>
          <CardBody>{bodyRows}</CardBody>
        </Collapse>
      </Card>
    </div>
  );
}

export default AppointmentActivities;
