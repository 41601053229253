import React from 'react';
import { useAppSelector } from 'hooks';
import { Button as RSButton, ButtonProps as RSButtonProps } from 'reactstrap';

import { selectUserTags } from '~main/selectors/login';

export interface ButtonProps extends RSButtonProps {
  authTag?: string;
}

function Button({ authTag, ...props }: ButtonProps) {
  const tags = useAppSelector(selectUserTags);

  const visible = !authTag || (authTag && tags.includes(authTag));
  return <>{visible && <RSButton {...props} />}</>;
}

export default Button;
