import React, { useState } from 'react';
import { Button, Col, Input, Row } from 'reactstrap';

import { InputProps } from 'tsx/components/FormFields';
import * as FormInput from 'tsx/components/FormFields/Inputs';

function FormInputMobile({ id, value = {}, disabled, onChange }: InputProps) {
  const [override, setOverride] = useState<boolean>(false);
  return (
    <>
      <Row className="d-flex justify-content-between">
        <Col className="d-flex align-items-center">
          {override && (
            <Input
              id={id}
              name={id}
              disabled={disabled}
              value={value ?? ''}
              onChange={({ target: { name, value } }) => onChange && onChange(name, value)}
            />
          )}
          {!override && <FormInput.ReadOnly type="readonly" name={id} id={id} value={value} />}
        </Col>
        {!override && (
          <Col className="d-flex justify-content-end">
            <Button type="button" size="sm" color="success" onClick={() => setOverride(true)}>
              Override
            </Button>
          </Col>
        )}
      </Row>
    </>
  );
}

export default FormInputMobile;
