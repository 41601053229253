import { ExportProps } from 'tsx/types/exportProps';
import { selectLookupAppointmentServiceTypes } from 'tsx/features/appointments/reducers/serviceTypes';
import { selectAppointmentStatuses } from 'tsx/features/appointments/reducers/statuses';
import { selectClientReferrers } from '~main/reducers/clients/clientReferrers';
import { selectClientPackages } from '~main/reducers/clients/clientPackages';
import { formatDateString, formatTimeString } from 'tsx/libs/dayjs';

const fields: ExportProps = {
  reference_number: {
    type: 'text-like',
    caption: 'Reference Number',
    checked: true,
    field: 'reference_number',
    placeholder: 'Filter..',
    columnDef: {
      id: 'reference_number',
      accessorKey: 'reference_number',
      header: 'Reference Number',
    },
  },
  client_name: {
    type: 'text-like',
    caption: 'Client',
    checked: true,
    field: 'client.full_name',
    placeholder: 'Filter..',
    columnDef: {
      id: 'client.full_name',
      accessorKey: 'client.full_name',
      header: 'Client',
    },
  },
  care_worker: {
    type: 'text-like',
    caption: 'Care Worker',
    checked: true,
    field: 'user.full_name',
    placeholder: 'Filter..',
    columnDef: {
      id: 'user.full_name',
      accessorFn: (row) => row.user?.full_name ?? '',
      header: 'Care Worker',
    },
  },
  date: {
    type: 'date-range',
    field: 'date',
    caption: 'Date',
    checked: true,
    columnDef: {
      id: 'date',
      accessorKey: 'date',
      header: 'Date',
      cell: (info) => formatDateString(info.getValue()),
    },
  },
  start_time: {
    type: 'time-range',
    field: 'start_time',
    caption: 'Start Time',
    columnDef: {
      id: 'start_time',
      accessorKey: 'start_time',
      header: 'Start Time',
      cell: (info) => formatTimeString(info.getValue()),
    },
  },
  end_time: {
    type: 'time-range',
    field: 'end_time',
    caption: 'End Time',
    columnDef: {
      id: 'end_time',
      accessorKey: 'end_time',
      header: 'End Time',
      cell: (info) => formatTimeString(info.getValue()),
    },
  },
  status: {
    type: 'multi-lookup',
    field: 'status_id',
    columnField: 'status.name',
    caption: 'Status',
    placeholder: 'All',
    selectorOptions: selectAppointmentStatuses,
    columnDef: {
      id: 'status_id',
      accessorKey: 'status.name',
      header: 'Status',
    },
  },
  referrer: {
    type: 'multi-lookup',
    field: 'client.referrer_name',
    caption: 'Referrer',
    placeholder: 'All',
    selectorOptions: selectClientReferrers,
    columnDef: {
      id: 'client.referrer_name',
      accessorFn: (row) => row.client?.referrer_name ?? '',
      header: 'Referrer',
    },
  },
  client_package: {
    type: 'multi-lookup',
    field: 'package_fixed_id',
    columnField: 'package_fixed.name',
    caption: 'Package',
    placeholder: 'All',
    selectorOptions: selectClientPackages,
    columnDef: {
      id: 'package_fixed.name',
      accessorFn: (row) => row.package_fixed?.name ?? '',
      header: 'Package',
    },
  },
  service_type: {
    type: 'multi-lookup',
    field: 'service_type_id',
    columnField: 'service_type.name',
    caption: 'Service Type',
    placeholder: 'All',
    selectorOptions: selectLookupAppointmentServiceTypes,
    columnDef: {
      id: 'service_type.name',
      accessorFn: ({ service_type }) =>
        service_type !== undefined ? `${service_type.name}${service_type.deleted ? ' - DELETED' : ''}` : '',
      header: 'Service Type',
    },
  },
};

export default fields;
