import React, { CSSProperties, useEffect, useState } from 'react';
import { useAppSelector } from 'hooks';
import classNames from 'classnames';
import tinycolor from 'tinycolor2';

import { Appointment, Event } from '~weekly-planner/lib/common';

import Icon, {
  faBan,
  faCar,
  faCommentDollar,
  faFileInvoiceDollar,
  faLock,
  faPersonWalkingArrowRight,
} from '~components/Icon';
import AppointmentPopover from '~weekly-planner/components/Modal/AppointmentPopover';

import { selectServiceTypeColourById } from '~appointments/reducers/serviceTypes';

import { selectOpenAppointmentDetailsId, selectOptions } from '~weekly-planner/selectors';
import { selectChangeById } from '~weekly-planner/selectors/simulate';

interface ComponentProps {
  appointment: Appointment;
  events: Event[];
  className?: string;
  style?: CSSProperties;
}

const AppointmentCard: React.FC<ComponentProps> = ({ appointment, className, style }) => {
  const {
    key,
    id,
    client,
    start_time,
    end_time,
    status,
    flexibility,
    service_type,
    is_travel_exempt,
    is_cancelled,
    is_active_cancellation,
    is_leave_entry,
    is_shift_pay,
    is_fixed_pay,
    is_on_hold,
  } = appointment;
  const { full_name } = client;

  const [isPopOpen, setPopOpen] = useState(false);
  const poppedId = useAppSelector(selectOpenAppointmentDetailsId);
  const hasChanged = useAppSelector((state) => selectChangeById(state, key));
  const serviceTypeColour = useAppSelector((state) => selectServiceTypeColourById(state, service_type?.id));
  const { appointmentFlexibility } = useAppSelector(selectOptions);

  const contextTarget = `appointment-card-${key}`;

  useEffect(() => {
    const shouldPop = id && id === poppedId;
    if (shouldPop && !isPopOpen) setPopOpen(true);
    else if (!shouldPop && isPopOpen) setPopOpen(false);
  }, [poppedId]);

  const backgroundColor = serviceTypeColour ? serviceTypeColour : 'white';
  const appointmentCardStyle: CSSProperties = {
    backgroundColor,
    color: tinycolor(backgroundColor).isDark() ? 'white' : undefined,
    ...style,
  };

  const statusStripStyle: CSSProperties = {
    backgroundColor: status?.colour ?? undefined,
  };

  const cardClassName = classNames('appointment-card tooltip-container', {
    [`${className}`]: className !== undefined,
    changed: hasChanged,
    cancelled: is_cancelled,
    new: id === null,
    flexible: appointmentFlexibility && flexibility,
    'non-flexible': appointmentFlexibility && !flexibility,
    'shift-pay': is_shift_pay,
    'on-hold': is_on_hold,
  });

  const buildStatusIcons = () => {
    const iconRow = [];

    if (is_travel_exempt) {
      iconRow.push(
        <span className="fa-layers fa-fw" title="Travel exempt">
          <Icon icon={faCar} size="xs" />
          <Icon icon={faBan} size="lg" />
        </span>,
      );
    }
    if (is_active_cancellation) {
      iconRow.push(<Icon icon={faCommentDollar} title="Active Cancellation" />);
    }
    if (is_leave_entry) {
      iconRow.push(<Icon icon={faPersonWalkingArrowRight} title="Leave Entry" />);
    }
    if (is_shift_pay) {
      iconRow.push(<Icon icon={faFileInvoiceDollar} title="Shift Pay" />);
    }
    if (is_fixed_pay) {
      iconRow.push(<Icon icon={faLock} title="Fixed Pay" />);
    }

    return iconRow;
  };

  return (
    <>
      <div
        id={contextTarget}
        className={cardClassName}
        style={appointmentCardStyle}
        data-tooltip={`${full_name} (${start_time} - ${end_time})`}
        onClick={(e) => e.stopPropagation()}
        onContextMenu={(e) => e.preventDefault()}
      >
        <div className="status-strip" style={statusStripStyle} />
        <div className="appointment-info">
          <div>
            <strong>{full_name}</strong>
          </div>
          <div>
            {start_time} - {end_time}
          </div>
          <div>{buildStatusIcons()}</div>
        </div>
      </div>

      <AppointmentPopover isOpen={isPopOpen} target={contextTarget} appointment={appointment} />
    </>
  );
};

export default AppointmentCard;
