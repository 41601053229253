import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'hooks';
import { Card, CardBody, CardHeader, Col, Collapse, Row } from 'reactstrap';
import dayjs from 'dayjs';

import { AppointmentStatuses } from '~constants/maps';

import { dateInputFieldFormat, weeklyPlannerDayDisplayFormat } from '~libs/dayjs';

import Button from '~components/Button';
import ContainerHeader from '~components/ContainerHeader';
import Icon, { faChevronDown, faChevronUp } from '~components/Icon';
import Spinner from '~components/Spinner';

import { acknowledge, getAll } from '~appointments/actions/appointments';

import { selectAppointments } from '~appointments/reducers/appointments';

const UpcomingAppointments = () => {
  const dispatch = useAppDispatch();
  const { ACCEPTED, PENDING_CARER_APPROVAL } = AppointmentStatuses;

  const [loading, setLoading] = useState<boolean>(false);
  const [openTarget, setTarget] = useState<string>('');
  const toggle = (id: string) => {
    setTarget(openTarget === id ? '' : id);
  };

  const appointments = useAppSelector(selectAppointments);
  const count = appointments.length;

  const load = () => dispatch(getAll({ repeat: false, limit: 0, week_start: dayjs().format(dateInputFieldFormat) }));

  const acceptAppointment = async (id: number) => {
    setLoading(true);
    const { payload } = await dispatch(acknowledge({ id, status_id: ACCEPTED }));
    const { success } = payload;
    if (success) {
      setLoading(false);
      load();
    }
  };

  useEffect(() => {
    load();
  }, []);

  const renderAcceptSection = (status: number, apptId: number) => {
    switch (status) {
      case PENDING_CARER_APPROVAL: {
        return (
          <>
            <span className="section-title">Pending Accept</span>
            <p>This appointment is ready to be accepted.</p>
            <Button onClick={() => acceptAppointment(apptId)} disabled={loading}>
              {loading ? <Spinner size={15} loading={loading} /> : 'Accept'}
            </Button>
            <small>To reject this appointment, call the office.</small>
          </>
        );
      }
      case ACCEPTED: {
        return (
          <>
            <span className="section-title">Appointment Accepted</span>
            <p>This appointment has been accepted.</p>
            <small>To change anything for this appointment, call the office.</small>
          </>
        );
      }
    }
  };

  const renderRow = (title: string, value: string, nextLine?: boolean) => {
    return nextLine ? (
      <div className="row">
        <div className="row">
          <Col xs={4} className="fw-bold">
            {title}:
          </Col>
        </div>
        <div className="row">
          <div className="col">{value}</div>
        </div>
      </div>
    ) : (
      <div className="row">
        <Col xs={4} className="fw-bold">
          {title}:
        </Col>
        <div className="col">{value}</div>
      </div>
    );
  };

  const renderAppointmentCards = () => {
    return appointments.map(({ id, client, status, start_time, end_time, date, service_type, notes }) => {
      const isOpen = openTarget === id.toString();
      const displayDate = dayjs(date).format(weeklyPlannerDayDisplayFormat);

      const rowFields = [
        { label: 'Client', value: client.full_name },
        { label: 'Date', value: displayDate },
        { label: 'Times', value: `${start_time} - ${end_time}` },
        { label: 'Status', value: status.name },
        { label: 'Service Type', value: service_type.name },
        { label: 'Referrer', value: client.referrer_name },
        { label: 'Age', value: client.age },
        { label: 'Phone', value: client.phone },
        { label: 'Address', value: client.address },
        { label: 'Suburb', value: client.suburb },
        { label: 'Notes', value: notes, nextLine: true },
      ];

      return (
        <div key={`appt-card-${id}`} className="appointment-card">
          <Card>
            <CardHeader type="button" onClick={() => toggle(id.toString())} className="card-header">
              <div className="status-strip" style={{ backgroundColor: status.colour }} />
              <div className="header-info">
                <Row>
                  <Col className="header-title">{client.full_name}</Col>
                  <Col xs={4} className="text-end">
                    <span className="fw-bold status">{status.name}</span>
                    <Icon icon={isOpen ? faChevronUp : faChevronDown} className="ms-2" />
                  </Col>
                </Row>
                <Row>
                  {displayDate}, {start_time} - {end_time}
                </Row>
              </div>
            </CardHeader>
            <Collapse isOpen={isOpen}>
              <CardBody className="card-body">
                <Row>
                  <span className="section-title">Appointment Details</span>
                  {rowFields.map(({ label, value, nextLine }) => renderRow(label, value, nextLine))}
                </Row>
                <hr />
                <Row className="mt-3">
                  <div className="options">{renderAcceptSection(status.id, id)}</div>
                </Row>
              </CardBody>
            </Collapse>
          </Card>
        </div>
      );
    });
  };

  return (
    <div className="upcoming-appointments">
      <ContainerHeader>Upcoming Appointments ({count})</ContainerHeader>
      <div className="body">{renderAppointmentCards()}</div>
    </div>
  );
};

export default UpcomingAppointments;
