import { ColumnDef } from '@tanstack/react-table';

export const onHoldColumns: ColumnDef<any>[] = [
  {
    id: 'clientFullName',
    accessorKey: 'client.full_name', // Use dot notation to access nested properties
    header: 'Client',
    cell: (info) => info.getValue(),
  },
  {
    id: 'startDate',
    accessorKey: 'date_start',
    header: 'Start Date',
    cell: (info) => info.getValue(),
  },
  {
    id: 'endDate',
    accessorKey: 'date_end',
    header: 'End Date',
    cell: (info) => info.getValue() ?? 'Not Set',
  },
];
