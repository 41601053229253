import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'hooks';
import { Card, CardBody, CardHeader } from 'reactstrap';
import { useLocation, useNavigate } from 'react-router-dom';

import Spinner from '~components/Spinner';

import { authenticateSession } from '~main/actions/login';
import { removeAuthenticated } from '~main/reducers/login';
import { selectAuthenticated } from '~main/selectors/login';

const SessionCheck: React.FC = () => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const [isAuthenticated, setAuthenticated] = useState(false);
  const sessionAuthenticated = useAppSelector(selectAuthenticated);

  useEffect(() => {
    authenticate();
  }, []);

  useEffect(() => {
    if (isAuthenticated && sessionAuthenticated) {
      navigate(getTarget());
    }
  }, [isAuthenticated, sessionAuthenticated]);

  const getTarget = () => {
    const { state } = location;
    if (!state) return '/';

    const { from } = state;
    if (from) return from;
    return '/';
  };

  const authenticate = async () => {
    const { state } = location;
    if (!state) {
      navigate('/login');
      return;
    }
    const { sessionKey } = state;
    dispatch(removeAuthenticated());
    const { payload } = await dispatch(authenticateSession({ session: sessionKey }));
    if (payload?.success) setAuthenticated(true);
    else navigate('/login');
  };

  return (
    <div className="d-flex justify-content-center mt-5 login">
      <Card className="rounded">
        <CardHeader className="d-flex justify-content-center bg-menu-background">
          <img src="/assets/tpcare_logo.png" alt="Logo" />
        </CardHeader>
        <CardBody className="d-flex flex-column text-center">
          <Spinner loading={true} type="beat" className="mt-4" />
          <h2 className="mt-4">Authenticating</h2>
          <p className="mt-1 mb-1">You will be automatically navigated in a moment.</p>
          <p className="mt-1">Thank you for your patience</p>
        </CardBody>
      </Card>
    </div>
  );
};

export default SessionCheck;
