import { Action, ThunkAction, configureStore } from '@reduxjs/toolkit';

import appointmentsReducers from '~appointments/reducers';
import careWorkersReducers from '~care-workers/reducers';
import mainReducers from '~main/reducers';
import weeklyPlannerReducers from '~weekly-planner/reducers';

export const store = configureStore({
  reducer: {
    main: mainReducers,
    appointments: appointmentsReducers,
    careWorkers: careWorkersReducers,
    weeklyPlanner: weeklyPlannerReducers,
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;
