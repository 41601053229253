import React, { useState } from 'react';
import { useAppDispatch, useAppSelector } from 'hooks';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import { Dayjs } from 'dayjs';

import { dateInputFieldFormat } from '~libs/dayjs';
import { Worker } from '~weekly-planner/lib/common';

import Icon, { faCaretRight, faCommentSms, faXmark } from '~components/Icon';
import SMSDialog from '~components/SMSDialog';
import FormInputWorkerAvailability from '~appointments/components/FormInputWorkerAvailability';
import WorkerQualifications from '~weekly-planner/components/Common/WorkerQualifications';

import { sendSMS } from '~weekly-planner/actions/users';

import { focusWorker } from '~weekly-planner/reducers/weeklyPlanner';

import { selectFocusedWorkerId } from '~weekly-planner/selectors';

interface Props {
  worker: Worker;
  date: Dayjs;
}

const WorkerCard: React.FC<Props> = ({ worker, date }) => {
  const dispatch = useAppDispatch();
  const [isOpen, setIsOpen] = useState(false);
  const [isSMSOpen, setIsSMSOpen] = useState<boolean>(false);
  const { id, full_name, gender, age, qualifications, rostering_notes, hours_min, hours_max } = worker;

  const onSMSClose = () => {
    setIsSMSOpen(!isSMSOpen);
  };

  const focusWorkerId = useAppSelector(selectFocusedWorkerId);

  return (
    <>
      <div className="d-flex align-items-center">
        {focusWorkerId && focusWorkerId === parseInt(id) && (
          <div role="button" className="p-1 me-2" onClick={() => dispatch(focusWorker(null))} title="Unfocus worker">
            <Icon icon={faXmark} size="lg" color="red" />
          </div>
        )}

        <div role="button" onClick={() => setIsOpen(true)}>
          <span className="me-1">{full_name}</span>
          <Icon icon={faCaretRight} className="mt-1" />
        </div>
      </div>

      <Modal isOpen={isOpen} size="lg" toggle={() => setIsOpen(!isOpen)} className="worker-card">
        <SMSDialog isOpen={isSMSOpen} onClose={onSMSClose} user={worker} sendAction={sendSMS} />
        <ModalHeader className="bg-success text-white">Care Worker - Summary</ModalHeader>
        <ModalBody>
          <div className="p-2">
            <div className="d-flex justify-content-between align-middle">
              <div>
                <div className="fw-bold">{full_name}</div>
                <div>
                  {gender?.name} {age && ` (${age})`}
                </div>
              </div>
              <Icon
                icon={faCommentSms}
                size="2x"
                title="Send SMS"
                className="sms-icon"
                onClick={() => setIsSMSOpen(true)}
              />
            </div>

            <div>
              <div className="fw-bold mt-2">Qualifications</div>
              <div>
                <WorkerQualifications qualifications={qualifications} />
              </div>
            </div>
            <div>
              <div className="fw-bold mt-2">Rostering Notes</div>
              <div>{rostering_notes?.length > 0 ? rostering_notes : '-'}</div>
            </div>
            <div className="mt-2">
              <div>
                <b>Min Hours p/w:</b>
                <span className="ms-1">{hours_min}</span>
              </div>
              <div>
                <b>Max Hours p/w:</b>
                <span className="ms-1">{hours_max}</span>
              </div>
            </div>
            <div>
              <div className="fw-bold mt-2">Availability</div>
              <FormInputWorkerAvailability
                type="custom"
                id={id}
                name={id}
                value={{ date: date.format(dateInputFieldFormat), user_id: id }}
              />
            </div>
          </div>
        </ModalBody>
      </Modal>
    </>
  );
};

export default WorkerCard;
