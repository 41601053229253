import { createThunks } from '~libs/reduxUtils';
import { get, post } from '~libs/genericAction';
import { apiWeeklyPlanner } from '~constants/api';

export const typePrefix = 'weekly-planner-users';

const config = [
  {
    actionType: 'getAll',
    apiPath: `${apiWeeklyPlanner}/users`,
    method: get,
    actionParams: { limit: 0, care_worker: true, disabled: false },
  },
  {
    actionType: 'getAwardAlerts',
    apiPath: `${apiWeeklyPlanner}/users/award-alerts`,
    method: get,
    actionParams: { care_worker: true, disabled: false },
  },
  { actionType: 'getRunningLate', apiPath: `${apiWeeklyPlanner}/users/exceptions`, method: get },
  { actionType: 'sendSMS', apiPath: `${apiWeeklyPlanner}/users/sms`, method: post },
];

export const { getAll, getAwardAlerts, getRunningLate, sendSMS } = createThunks(typePrefix, config);
