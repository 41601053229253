import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';

dayjs.extend(isBetween);

export interface Worker {
  id: string;
  full_name: string;
  gender: { id: number; name: string };
  age: number;
  qualifications: string[];
  rostering_notes: string;
  hours_min: number;
  hours_max: number;
  events: { [day: string]: Event[] };
  availability: WorkerAvailability;
  is_qualified?: boolean;
  is_available?: boolean;
}

export interface Client {
  id: string;
  full_name: string;
  address: string;
  suburb: string;
  postcode: string;
  state: string;
  referrer_name: string;
  referrer_number: number;
  events: { [day: string]: Event[] };
  preferences?: { gender?: number; yes?: Array<number>; no?: Array<number> };
}

export interface WorkerAvailability {
  Monday?: Availability;
  Tuesday?: Availability;
  Wednesday?: Availability;
  Thursday?: Availability;
  Friday?: Availability;
  Saturday?: Availability;
  Sunday?: Availability;
}

export interface Availability {
  start?: string;
  end?: string;
}

export interface AssignableUser {
  id: number;
  name: string;
  is_qualified: boolean;
  is_available: boolean;
  is_preferred?: boolean;
  is_historical?: boolean;
}
export interface AwardAlert {
  tag: string;
  type: string;
  caption: string;
  message: string;
}
export interface AwardAlertVisibility {
  [key: string]: boolean;
}
export interface Appointment {
  key: number | null;
  id: number | null;
  date: string;
  user_id: string | number | null;
  repeat: boolean;
  parent_repeat_id: number | null;
  repeat_next_date?: string;
  start_time: string;
  end_time: string;
  duration: number;
  flexibility: boolean;
  qualification_level: string[];
  notes: string;
  status: {
    id: number;
    name: string;
    colour: string;
  };
  client: Client;
  service_type: {
    id: number;
    name: string;
    colour: string;
  };
  assignable_users?: AssignableUser[];
  user?: Worker;
  is_bidding: boolean;
  bidding_user_ids: Array<any>;
  rejected_user_ids: Array<any>;
  location_override?: {
    address: string;
    id: number;
    name: string;
    postcode: string;
    state: string;
    suburb: string;
  };
  is_cancelled?: boolean;
  is_active_cancellation?: boolean;
  is_travel_exempt?: boolean;
  is_leave_entry?: boolean;
  is_shift_pay?: boolean;
  is_fixed_pay?: boolean;
  is_on_hold?: boolean;
}

export interface Travel {
  from: {
    time: string | null;
    address: string;
  };
  to: {
    time: string | null;
    address: string;
  };
  type: 'start' | 'mid' | 'range' | 'end';
  distance: number;
  duration: number;
}

export interface Break {
  type: 'meal' | 'shift' | 'tea';
  from: {
    time: string;
  };
  to: {
    time: string;
  };
  duration: number;
}

export type Conflict = (AppointmentEvent | TravelEvent | BreakEvent | TeaBreak)[];

export interface EventTotals {
  [key: string]: number;
}

export interface AppointmentEvent {
  type: 'appointment';
  totals: EventTotals;
  data: Appointment;
}

export interface ConflictEvent {
  type: 'conflict';
  data: Conflict[];
}

export interface TravelEvent {
  type: 'travel';
  totals: EventTotals;
  data: Travel;
}

export interface BreakEvent {
  type: 'break';
  totals: EventTotals;
  data: Break;
}

export interface TeaBreak {
  type: 'tea_break';
  totals: EventTotals;
  data: Break;
}

export interface DayStats {
  count: number;
  duration: number;
  users: number;
  avg_duration: number;
  hours: number;
}

export interface Stats {
  daily?: Record<string, DayStats>;
  status: {
    [key: number]: string;
  };
}

export interface ServiceType {
  value: number;
  label: string;
  colour?: string;
}

export const DISTANCE_RANGE_LIMIT = 70;

export type Event = AppointmentEvent | ConflictEvent | TravelEvent | BreakEvent | TeaBreak;

export const calcIndexFromTime = (timeString: string, minInterval = 5) => {
  const formattedTimeString = timeString.replace(':', '');

  // Convert time string to hours and minutes
  const hours = parseInt(formattedTimeString.slice(0, -2), 10);
  const minutes = parseInt(formattedTimeString.slice(-2), 10);

  // Calculate index (variable minute intervals? default 12 segments per hour, 5 min intervals)
  return hours * 12 + (Math.ceil(minutes / 5) * 5) / minInterval;
};

export const getGridColumn = (start_time: string, end_time: string, offset = 0) => {
  const start = calcIndexFromTime(start_time) + offset;
  const end = calcIndexFromTime(end_time) + offset;

  return {
    gridColumn: `${start} / span ${end - start}`,
  };
};

export const getEventSum = (events: Event[] = [], key: string, type: 'appointment' | 'travel') => {
  const total = events
    .filter(({ type: eventType }: { type: string }) => eventType === type)
    .reduce((count: number, { data }: any) => {
      const value = data[key];
      return typeof value === 'number' ? count + value : count;
    }, 0);
  return total;
};

export const getEventRange = (events: Event[], key: string, type: 'appointment' | 'travel') => {
  const count = getEventSum(events, key, type);
  const conflicts = (events.filter(({ type }) => type === 'conflict').map(({ data }) => data) as Conflict[][]).map(
    (rows) => rows.map((row) => getEventSum(row, key, type)),
  );

  if (conflicts.length > 0) {
    const min = conflicts.reduce((count, values) => count + Math.min(...values), count);
    const max = conflicts.reduce((count, values) => count + Math.max(...values), count);
    return [min, max];
  }
  return [count];
};

export const getEventTotals = (events: Event[] = [], key: string) => {
  const total = events.reduce((count: number, { totals }: any) => {
    if (!totals) return count;
    const value = totals[key];
    return typeof value === 'number' ? count + value : count;
  }, 0);
  return total;
};

export const getEventTotalsRange = (events: Event[], key: string) => {
  const count = getEventTotals(events, key);
  const conflicts = (events.filter(({ type }) => type === 'conflict').map(({ data }) => data) as Conflict[][]).map(
    (rows) => rows.map((row) => getEventTotals(row, key)),
  );

  if (conflicts.length > 0) {
    const min = conflicts.reduce((count, values) => count + Math.min(...values), count);
    const max = conflicts.reduce((count, values) => count + Math.max(...values), count);
    return [min, max];
  }
  return [count];
};

export const calculateTimeMarkerPosition = (container: HTMLDivElement | null) => {
  if (!container) return 0;

  const now = dayjs();
  const minutesSinceMidnight = now.hour() * 60 + now.minute();
  const percentageOfDay = minutesSinceMidnight / 1440;

  const containerWidth = container?.offsetWidth || 0;
  const containerOffsetLeft = container?.offsetLeft || 0;

  return containerOffsetLeft + percentageOfDay * containerWidth;
};

export const getAppointmentSameTimeCount = ({ date, start_time }: Appointment, appointments: Appointment[]) =>
  appointments.reduce(
    (count: number, { date: cDate, start_time: cStart }) =>
      count + +dayjs(`${date} ${start_time}`).isSame(`${cDate} ${cStart}`),
    0,
  );

export const formatAvailabilityDisplayString = (availability: Availability): string => {
  const { start, end } = availability;
  if (!start || !end) return 'No availability';
  return `${formatTimeString(start, true)} - ${formatTimeString(end)}`;
};

export const formatTimeString = (time: string, isStart = false): string => {
  if (time === '000' || time === '000' || time === '2400') return isStart ? '00:00' : '24:00';

  const paddedTime = time.padStart(4, '0');

  const hours = paddedTime.slice(0, 2);
  const minutes = paddedTime.slice(2);

  return `${hours}:${minutes}`;
};

export const formatDistanceDisplayString = (maxDist: number) => {
  return maxDist > 0 ? `${Math.round(maxDist * 100) / 100} KMs` : 'Distance unavailable';
};

// Confirm that appt time fits within the user availability
export const isUserAvailable = (
  availability: Availability,
  start_time: string | null | undefined,
  end_time: string | null | undefined,
): boolean => {
  function convertToNumber(time: string): number {
    if (time === '24:00') return 0;

    if (time.includes(':')) {
      const [hours, minutes] = time.split(':').map(Number);
      return hours * 100 + minutes;
    }

    const paddedTime = time.padStart(4, '0');
    const hours = parseInt(paddedTime.slice(0, 2), 10);
    const minutes = parseInt(paddedTime.slice(2), 10);

    return hours * 100 + minutes;
  }

  if (!availability) return false;

  const { start, end } = availability;
  if (start === null || start == undefined || end === null || end === undefined) return false;
  if (!start_time || !end_time) return false;

  const apptStart = convertToNumber(start_time);
  const apptEnd = convertToNumber(end_time);

  const availableStart = convertToNumber(start);
  const availableEnd = convertToNumber(end === '000' || end === '0000' ? '2400' : end);

  const isAvailable = apptStart >= availableStart && apptEnd <= availableEnd;
  return isAvailable;
};

// take high estimate for distance if range conflict exists
export const maxDistance = (events: Event[] = []) => {
  if (events) {
    const distance = getEventRange(events, 'distance', 'travel');
    return distance.length > 1 ? Math.max(...distance) : distance[0];
  }
  return 0;
};

export const isInRange = (maxDist: number) => {
  return maxDist < DISTANCE_RANGE_LIMIT && maxDist > 0;
};

/**
 * Calculates a time from a mouse click event on timeline element
 *
 * @param  timeLine - The DOM element representing the timeline.
 * @param event - The mouse click event triggered on the timeline.
 * @param  [raw=false] - If true, returns a `dayjs` object with the calculated time. If false, returns the time as a string in "HH:00:00" format.
 * @returns If `raw` is false, returns a string in "HH:00:00" format. If `raw` is true, returns a `dayjs` object representing the calculated time.
 */
export const getTimeFromClick = (timeLine: Element, event: MouseEvent, raw = false): string | dayjs.Dayjs => {
  const offsetX = timeLine.getBoundingClientRect().left;
  const actualX = event.clientX - offsetX;
  const width = timeLine.scrollWidth ?? 1;
  const timePerPixel = 24 / width;
  const time = actualX * timePerPixel;

  if (raw) {
    const hours = Math.floor(time);
    const minutes = Math.round((time - hours) * 60);
    return dayjs().hour(hours).minute(minutes).second(0).millisecond(0);
  }

  const hours = Math.floor(time);
  return `${hours}:00:00`;
};
