import React from 'react';
import { Step as MuiStep, StepLabel, Stepper as MuiStepper } from '@mui/material';
import { Step } from './props';

/**
 * @property currentStep - The index of the current step in the wizard.
 * @property steps - An array of steps defining the wizard.
 */
interface ComponentProps {
  currentStep: number;
  steps: Step[];
}

/**
 * Renders a stepper for the wizard form.
 *
 * displays the current step of the wizard along with the titles of all defined steps.
 *
 * @param  props
 * @returns The rendered stepper component.
 */
export const Stepper: React.FC<ComponentProps> = ({ currentStep, steps }) => {
  return (
    <MuiStepper activeStep={currentStep} className="p-2">
      {[...steps].map(({ title, step }, index) => (
        <MuiStep key={step || index} completed={currentStep - 1 > +step}>
          <StepLabel>{title}</StepLabel>
        </MuiStep>
      ))}
    </MuiStepper>
  );
};

export default Stepper;
