import { RootState } from 'store';

const LoginState = (state: RootState) => state.main.login;
export const selectErrorResponse = (state: RootState) => LoginState(state).error;
export const selectAuthenticated = (state: RootState) => LoginState(state).authenticated;
export const selectForceRedirect = (state: RootState) => LoginState(state).forceRedirect;
export const selectCompanySettings = (state: RootState) => LoginState(state).settings;
export const selectCompanyDefaults = (state: RootState) => LoginState(state).defaults;
export const selectSystemConfig = (state: RootState) => LoginState(state).system;
export const selectAuthenticatedUser = (state: RootState) => LoginState(state).user;
export const selectUserTags = (state: RootState) => LoginState(state).tags ?? [];
export const selectLinkedAccounts = (state: RootState) =>
  LoginState(state).linkedAccounts?.map(({ id, company: { name } }) => ({ value: id, label: name }));
