import React, { useState } from 'react';
import { useAppSelector } from 'hooks';
import { Col, Input, Row } from 'reactstrap';

import Button from 'tsx/components/Button';
import { InputProps } from 'tsx/components/FormFields';
import * as FormInput from 'tsx/components/FormFields/Inputs';
import { Row as RowType } from '~libs/reduxUtils';

function FormInputEquipments({ id, disabled, value = {}, selectorOptions, onChange }: InputProps) {
  const [override, setOverride] = useState(false);
  let rows: Array<RowType> = [];
  if (selectorOptions) {
    rows = useAppSelector(selectorOptions);
  }

  const setChange = (target: { name: string; value: any }) => {
    const { name, value: targetValue } = target;

    const newValue = {
      ...value,
      [name]: parseInt(targetValue),
    };

    onChange && onChange(id, newValue);
  };

  const equipments = rows.map(({ id, name }, index) => {
    const reference = id.toString();
    const count = value !== null ? (value[reference] ?? '') : '';
    return (
      <Row key={index} className="d-flex align-items-center">
        {!disabled && override && (
          <Row className="mt-1 mb-1">
            <Col sm={2}>
              <Input
                type="number"
                name={reference}
                id={reference}
                value={count}
                onChange={({ target: { name, value } }) => {
                  setChange({ name, value });
                }}
              />
            </Col>
            <Col className="ms-2">
              <FormInput.ReadOnly type="readonly" name={reference} id={reference} value={name} />
            </Col>
          </Row>
        )}
        {(disabled || !override) && count !== '' && (
          <FormInput.ReadOnly type="readonly" name={reference} id={reference} value={`${count} x ${name}`} />
        )}
      </Row>
    );
  });

  return (
    <>
      <Row className="align-items-center">
        <Col className="d-flex align-items-center">
          <div>{equipments}</div>
        </Col>
        <Col sm={2}>
          {!disabled && !override && (
            <Button type="button" size="sm" color="success" onClick={() => setOverride(!override)}>
              Override
            </Button>
          )}
        </Col>
      </Row>
    </>
  );
}

export default FormInputEquipments;
