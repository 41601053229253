import React, { useState } from 'react';
import { useAppSelector } from 'hooks';

import TableView from '~components/TableView';
import ModalCard from '~components/ModalCard';

import { cancelledColumns, rescheduledColumns } from '~weekly-planner/forms/info-modal/appointmentExceptions/list';

import { selectExceptions } from '~weekly-planner/selectors/appointments';

const AppointmentExceptions: React.FC = () => {
  const { rescheduled, cancelled } = useAppSelector(selectExceptions);

  const [isRescheduledOpen, setIsRescheduledOpen] = useState(true);
  const [isCancelledOpen, setIsCancelledOpen] = useState(true);

  if (rescheduled.length < 1 || cancelled.length < 1) {
    return <p>No appointment exceptions.</p>;
  }

  const rescheduledAppointments = rescheduled || [];
  const cancelledAppointments = cancelled || [];

  return (
    <div className="container p-4">
      {rescheduledAppointments.length > 0 && (
        <ModalCard
          header="Rescheduled Appointments"
          isOpen={isRescheduledOpen}
          headerClassName="bg-info"
          setIsOpen={setIsRescheduledOpen}
          className="mb-4"
          allowOpen={true}
          allowHeaderOpen={true}
        >
          <TableView
            id="rescheduled-appointments"
            data={rescheduled}
            columns={rescheduledColumns}
            showHeader={true}
            emptyMessage="No rescheduled appointments."
          />
        </ModalCard>
      )}

      {cancelledAppointments.length > 0 && (
        <ModalCard
          header="Cancelled Appointments"
          isOpen={isCancelledOpen}
          setIsOpen={setIsCancelledOpen}
          className="mb-4"
          headerClassName="bg-warning"
          allowOpen={true}
          allowHeaderOpen={true}
        >
          <TableView
            id="cancelled-appointments"
            data={cancelled}
            columns={cancelledColumns}
            showHeader={true}
            emptyMessage="No cancelled appointments."
          />
        </ModalCard>
      )}
    </div>
  );
};

export default AppointmentExceptions;
