import React, { ReactNode, useMemo, useRef, useState } from 'react';
import { Formik, Form as FormikForm } from 'formik';

import generateYupSchema from '~libs/yupValidation';

import Steps from '~components/WizardForm/Steps';
import Footer from '~components/WizardForm/Footer';
import Stepper from '~components/WizardForm/Stepper';
import { Step } from './props';
import ErrorResponse from '~components/ErrorResponse';
import { RootState } from 'store';
import { useAppSelector } from 'hooks';

/**
 * Props for the WizardForm component.
 * @property steps - Array of form steps, each containing a FieldProps structure.
 * @property onStepChange - Callback triggered after a step change occurs.
 * @property beforeStepChange - Callback triggered before a step changes.
 * @property onComplete - Async callback triggered as the form is completing.
 * @property renderFooterButtons - Custom renderer for footer navigation buttons.
 * @property renderFormBody - Function to wrap the form body with additional components.
 */
interface ComponentProps {
  steps: Step[];
  onStepChange?: (step: number) => void;
  onComplete?: (formData: any) => Promise<boolean>;
  beforeStepChange?: (step: number, data: any) => Promise<boolean>;
  renderFooterButtons?: (
    handleNext: () => void,
    handlePrev: () => void,
    currentStep: number,
    stepCount: number,
  ) => React.ReactNode;
  initialValues: { [key: string]: number | string | null | undefined };
  renderFormBody?: (formBody: ReactNode) => ReactNode;
  errorSelector?: (state: RootState) => string | null | undefined;
}

/**
 * WizardForm component for managing multi-step forms.
 *
 * @param props
 * @returns The rendered WizardForm component.
 */
export const Form: React.FC<ComponentProps> = ({
  steps,
  onStepChange,
  renderFooterButtons,
  renderFormBody,
  beforeStepChange,
  onComplete,
  initialValues,
  errorSelector,
}) => {
  let errorResponse;
  if (errorSelector) errorResponse = useAppSelector(errorSelector);

  const [currentStep, setCurrentStep] = useState<number>(0);
  const [invalidFields, setInvalidFields] = useState<string[]>([]);
  const formikRef = useRef<any>(null);

  const stepCount = steps.length;
  const isStepValid = invalidFields.length === 0;
  const fieldDefinitions = currentStep < stepCount - 1 ? steps[currentStep].fields : {};
  const validationSchema = useMemo(() => generateYupSchema(fieldDefinitions), [fieldDefinitions]);
  const canSubmit = stepCount === steps.length && invalidFields.length < 1;

  const handleNext = async () => {
    const canProceed = await validateStep();
    if (canProceed && (beforeStepChange ? await beforeStepChange(currentStep, {}) : true)) {
      const nextStep = Math.min(currentStep + 1, stepCount - 1);
      setCurrentStep(nextStep);
      onStepChange?.(nextStep);
    }
  };

  const handlePrev = async () => {
    setInvalidFields([]);
    const canProceed = beforeStepChange ? await beforeStepChange(currentStep, {}) : true;
    if (canProceed) {
      const prevStep = Math.max(currentStep - 1, 0);
      setCurrentStep(prevStep);
      onStepChange?.(prevStep);
    }
  };

  const validateStep = async (): Promise<boolean> => {
    const stepSchema = generateYupSchema(steps[currentStep]?.fields);
    const values = formikRef.current?.values;

    try {
      await stepSchema.validate(values, { abortEarly: false });
      setInvalidFields([]);
      return true;
    } catch (error: any) {
      if (error.inner) {
        const errors = error.inner.map(({ path }: any) => path);
        setInvalidFields(errors);
      }
      return false;
    }
  };

  const handleYupValidation = async (values: any) => {
    try {
      await validationSchema.validate(values, { abortEarly: false });
      setInvalidFields([]);
    } catch (err: any) {
      if (err.inner) {
        const errors = err.inner.map((e: any) => e.path);
        setInvalidFields(errors);
      }
    }
  };

  const submitForm = async (
    setSubmitting: (isSubmitting: boolean) => void,
    values: { [key: string]: string | number | null | undefined },
  ) => {
    try {
      if (canSubmit) {
        setSubmitting(true);
        await onComplete?.(values);
        setSubmitting(false);
      }
    } catch (error) {
      // Console error introduced when unknown type of error thrown.
      // This should be moved to page statistics/logging when implemented
      console.error('Unknown error occurred:', error);
    }
  };

  const formBody = (
    <>
      <ErrorResponse message={errorResponse} />
      <Formik
        initialValues={initialValues}
        validate={handleYupValidation}
        innerRef={formikRef}
        onSubmit={(values, { setSubmitting }) => submitForm(setSubmitting, values)}
      >
        {(context) => (
          <FormikForm className="wizard-form-content">
            <Steps
              authTag=""
              context={context}
              currentStep={currentStep}
              steps={steps}
              stepCount={stepCount}
              invalidFields={invalidFields}
            />
            <Footer
              currentStep={currentStep}
              handleNext={handleNext}
              handlePrev={handlePrev}
              isStepValid={isStepValid}
              renderFooterButtons={renderFooterButtons}
              stepCount={stepCount}
              isSubmitting={context.isSubmitting}
              handleSubmit={context.handleSubmit}
            />
          </FormikForm>
        )}
      </Formik>
    </>
  );

  return (
    <div className="wizard-form">
      <Stepper currentStep={currentStep} steps={steps} />
      {renderFormBody ? renderFormBody(formBody) : formBody}
    </div>
  );
};

export default Form;
