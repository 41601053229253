import React, { useEffect } from 'react';
import { useAppSelector } from 'hooks';

import { useStorage } from '~libs/localstorage';

import ContainerBanner from '~components/ContainerBanner';
import { faBell } from '~components/Icon';

import { selectSystemConfig } from '~main/selectors/login';

const STICKY_TAG = 'Fixed';

const AnnouncementBanner: React.FC = () => {
  const { content, last_updated } = useAppSelector(selectSystemConfig) ?? {};

  const [config, setConfig] = useStorage('system-config', { isClosed: false, lastUpdated: null });

  useEffect(() => {
    if (last_updated && last_updated !== config.lastUpdated) {
      setConfig({ isClosed: false, lastUpdated: last_updated });
    }
  }, [last_updated]);

  if (!content) return null;
  const { heading, status, text = '' } = content ?? {};

  // Only allow the announcement to be closed if the configuration allows it. Can be collapsible or persistent
  let onClear;
  if (status !== STICKY_TAG) {
    onClear = () => setConfig({ ...config, isClosed: true });
  }

  return (
    <>
      {!config.isClosed && (
        <ContainerBanner
          title={heading}
          messages={[text]}
          className="system-announcement"
          icon={faBell}
          iconSize="2x"
          onClear={onClear}
        />
      )}
    </>
  );
};

export default AnnouncementBanner;
