import { createSlice } from '@reduxjs/toolkit';
import { RootState } from 'store';
import { LoadingState } from '~libs/reduxUtils';

interface State {
  loading: LoadingState;
  error: string | null | undefined;
  quickMenuNarrow: boolean;
  viewType?: 'feature' | null;
  isDesktopView: boolean;
}

const initialState: State = {
  loading: 'idle',
  error: null,
  quickMenuNarrow: false,
  isDesktopView: true,
};

// Main slice, connecting API actions to redux state.
export const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    toggleQuickMenu(state, action) {
      const { payload } = action;
      state.quickMenuNarrow = payload;
    },
    setViewType(state, action) {
      const { payload } = action;
      state.viewType = payload;
    },
    setIsDesktopView(state, action) {
      const { payload } = action;
      state.isDesktopView = payload;
    },
  },
});


export const selectQuickMenuNarrow = ({ main }: RootState) => main.app.quickMenuNarrow;
export const selectViewType = ({ main }: RootState) => main.app.viewType;
export const selectIsDesktopView = ({main}: RootState) => main.app.isDesktopView;

export const { toggleQuickMenu, setViewType, setIsDesktopView } = appSlice.actions;

export default appSlice.reducer;
