import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'hooks';
import { HexColorPicker } from 'react-colorful';
import { Button, Col, Input, InputGroup, InputGroupText, Modal, ModalBody, ModalHeader, Row } from 'reactstrap';
import { ColumnDef } from '@tanstack/react-table';

import { useStorage } from '~libs/localstorage';
import { ServiceType } from '~weekly-planner/lib/common';

import Icon, { faCheck, faCircle, faEdit, faSquare } from '~components/Icon';
import Select from '~components/Select';
import Spinner from '~components/Spinner';
import TableView from '~components/TableView';

import { getAll as getAllServiceTypes, getColours, upsertColour } from '~appointments/actions/serviceTypes';

import {
  selectAppointmentServiceTypeColours,
  selectAppointmentServiceTypes,
} from '~appointments/reducers/serviceTypes';

interface ComponentProps {
  isOpen: boolean;
  onClose: () => void;
}

const ServiceColour: React.FC<ComponentProps> = ({ isOpen = false, onClose }) => {
  const [selectedColour, setSelectedColour] = useState<string>('');
  const [selectedService, setSelectedService] = useState<ServiceType>();
  const [hasUpdated, setHasUpdated] = useState<boolean>(false);
  const [submitting, setSubmitting] = useState<boolean>(false);

  const dispatch = useAppDispatch();
  const serviceTypes = useAppSelector(selectAppointmentServiceTypes);
  const currentColours = useAppSelector(selectAppointmentServiceTypeColours);

  const [recentColours, setRecentColours] = useStorage('recent-colours', []);

  const addRecentColour = (parsedColour: string) => {
    setRecentColours((previousColours: string[]) => {
      if (previousColours.includes(parsedColour)) return previousColours;
      if (previousColours.length >= 8) {
        return [...previousColours.slice(1), parsedColour];
      }
      return [...previousColours, parsedColour];
    });
  };

  const renderRecent = Array.from({ length: 8 }, (_, i) => {
    const indexColour = recentColours[i];
    const colour = indexColour ?? 'ffffff';
    return (
      <Icon
        key={i}
        icon={faSquare}
        className="colour-box"
        style={{ color: `#${colour}` }}
        onClick={() => setSelectedColour(colour)}
      />
    );
  });

  const columns: ColumnDef<any>[] = [
    {
      id: 'id',
      accessorKey: 'id',
      cell: (info) => `${info.getValue()}`,
      enableHiding: true,
    },
    {
      id: 'name',
      accessorKey: 'name',
      header: 'Service Name',
      cell: ({ row: { original } }) => {
        const { id, name, colour } = original;
        return (
          <span
            className="service-title"
            onClick={() => {
              setSelectedService({
                value: id,
                label: name,
                colour,
              });
              setSelectedColour(`${colour}`);
            }}
          >
            {name} <Icon className="edit-icon ps-1" icon={faEdit} />
          </span>
        );
      },
    },
    {
      id: 'colour',
      accessorKey: 'colour',
      header: 'Colour',
      cell: ({ row }) => {
        const colour = `#${row.original.colour}`;
        return (
          <span>
            <Icon icon={faCircle} className="me-1" style={{ color: colour }} />
            <span>{colour}</span>
          </span>
        );
      },
    },
  ];

  const serviceOptions: ServiceType[] = useMemo(() => {
    return serviceTypes
      .filter(({ colour }) => !colour)
      .map(({ id, name, colour }) => ({
        value: id,
        label: name,
        colour,
      }));
  }, [serviceTypes]);

  const handleChange = useCallback(
    (value: number) => {
      setHasUpdated(false);
      const option = serviceOptions.find((option) => option.value === value);
      if (option && selectedService?.value !== option.value) {
        setSelectedService(option);
        setSelectedColour(option.colour ? `${option.colour}` : '');
      }
    },
    [serviceOptions, selectedService],
  );

  const handleColourChange = useCallback((colour: string) => {
    setSelectedColour(colour.replace(/^#/, ''));
    setHasUpdated(false);
  }, []);

  const isValidHexColour = (colour: string) => {
    return /^([0-9A-Fa-f]{6})$/i.test(colour);
  };

  const invalidColour = !isValidHexColour(selectedColour);
  const disabledButton = !selectedService || invalidColour || submitting;

  const onSubmit = useCallback(async () => {
    setSubmitting(true);
    if (selectedService) {
      const parsedColour = selectedColour.startsWith('#') ? selectedColour.slice(1) : selectedColour;
      const { payload } = await dispatch(upsertColour({ id: selectedService.value, colour: parsedColour }));
      setSelectedService((prev) => prev && { ...prev, colour: parsedColour });
      addRecentColour(parsedColour);
      setHasUpdated(payload?.success ?? false);
    }
    setSubmitting(false);
  }, [selectedService, selectedColour]);

  useEffect(() => {
    dispatch(getColours());
    dispatch(getAllServiceTypes());
  }, [hasUpdated]);

  return (
    <Modal size="xl" isOpen={isOpen} className="service-colour">
      <ModalHeader className="bg-success text-white btn-close-white" data-bs-theme="dark" toggle={onClose}>
        Edit Service Type Colour
      </ModalHeader>
      <ModalBody className="colour-body">
        <Row>
          <Col sm={'8'} className="me-4 h-100">
            <div className="colour-table">
              <TableView id="colours" data={currentColours as []} columns={columns} />
            </div>
          </Col>
          <Col>
            <div>
              Select Service:
              <Select
                id={`select-service`}
                name={`select-service`}
                options={serviceOptions}
                onChange={(_name, value) => {
                  handleChange(value);
                }}
                value={selectedService?.value}
              />
            </div>
            <div className="colour-panel d-flex-column ">
              <Row className="m-2">
                <Col sm={3}>Current:</Col>
                <Col>
                  {selectedService?.colour ? (
                    <>
                      <Icon icon={faCircle} className="mx-1" style={{ color: `#${selectedService.colour}` }} />
                      <span>#{selectedService.colour}</span>
                    </>
                  ) : (
                    '-'
                  )}
                </Col>
              </Row>
              <Row className="m-2">
                <Col sm={3} className="align-self-center">
                  <span>New: </span>
                </Col>
                <Col>
                  <InputGroup>
                    <InputGroupText>#</InputGroupText>
                    <Input
                      className="d-inline"
                      type="text"
                      invalid={invalidColour}
                      onChange={({ target: { value } }) => {
                        handleColourChange(value);
                      }}
                      value={selectedColour}
                    ></Input>
                  </InputGroup>
                </Col>
              </Row>
              <div className="picker-section">
                <HexColorPicker color={selectedColour} onChange={handleColourChange} className="picker" />
                <Row className="recent">
                  <Col xs="auto" className="row">
                    <div className="colour-boxes">{renderRecent}</div>
                  </Col>
                </Row>
                <Col className="align-self-center mt-2">
                  <Button onClick={onSubmit} disabled={disabledButton}>
                    {selectedService?.colour ? 'Update' : 'Add'}
                    <Spinner loading={submitting} size={5} type="beat" />
                  </Button>
                  {hasUpdated === true && <Icon className="check-icon" icon={faCheck} />}
                </Col>
              </div>
            </div>
          </Col>
        </Row>
      </ModalBody>
    </Modal>
  );
};

export default ServiceColour;
