import { createSlice, isFulfilled, isPending, isRejected } from '@reduxjs/toolkit';
import { RootState } from 'store';
import { LoadingState } from '~libs/reduxUtils';

import { typePrefix, getAll } from '~appointments/actions/userAppointments';

interface appointmentRow {
  id: number;
  client: { full_name: string; suburb: string };
  [key: string]: string | number | object;
}

interface appointmentState {
  loading: LoadingState;
  error: string | null | undefined;
  rows: Array<appointmentRow>;
  total?: number;
}

const initialState: appointmentState = {
  loading: 'idle',
  error: null,
  rows: [],
  total: 0,
};

// Main slice, connecting API actions to redux state.
export const userAppointmentsSlice = createSlice({
  name: 'user-appointments',
  initialState,
  reducers: {
    clearUserAppointments(state) {
      state.rows = initialState.rows;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAll.fulfilled, (state, action) => {
      state.loading = 'fulfilled';
      const { rows, total } = action.payload.data;
      state.rows = rows;
      state.total = total;
    });
    // Default matching for loading cases, pending when action is being called
    builder.addMatcher(isFulfilled, (state, { type }) => {
      if (type.startsWith(`${typePrefix}/`)) state.error = null;
    });
    builder.addMatcher(isPending, (state, { type }) => {
      if (type.startsWith(`${typePrefix}/`)) state.loading = 'pending';
    });
    builder.addMatcher(isRejected, (state, action) => {
      if (action.type.startsWith(`${typePrefix}/`)) {
        state.loading = 'declined';
        state.error = action.error.message;
      }
    });
  },
});

// Selectors, performing common selection tasks for this slice.
export const selectUserAppointments = ({ appointments }: RootState) => appointments.userAppointments.rows;
export const selectUserAppointmentsTotal = ({ appointments }: RootState) => appointments.userAppointments.total;

export const { clearUserAppointments } = userAppointmentsSlice.actions;

export default userAppointmentsSlice.reducer;
