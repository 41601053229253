import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'store';

const UnsavedState = (state: RootState) => state.weeklyPlanner.unsaved;

export const selectAll = (state: RootState) => UnsavedState(state).rows;
export const selectAllChanges = (state: RootState) => UnsavedState(state).appointments;

export const selectAllChangesCount = (state: RootState) => Object.values(UnsavedState(state).appointments).length;

export const selectLoading = (state: RootState) => UnsavedState(state).loading.full;
const selectRowsLoading = (state: RootState) => UnsavedState(state).loading.rows;

export const selectRowLoading = createSelector(
  [selectRowsLoading, (_: RootState, id: number) => id],
  (rows, id) => rows[id] ?? 'idle',
);
