import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'hooks';
import ReactSelect, { SingleValue } from 'react-select';
import { Input, Label } from 'reactstrap';
import dayjs from 'dayjs';

import { CareWorkerFilterOptions, FilterOptions, RadioOptions } from '~constants/maps';

import { AssignableUser, Worker } from '~weekly-planner/lib/common';
import { buildAssignChange } from '~weekly-planner/lib/simulate';

import Button from '~components/Button';
import ConfirmDialog from '~components/ConfirmDialog';
import { ReadOnly, Select, Switch } from '~components/FormFields/Inputs';
import Icon, { faCommentSms, faMinus, faPlus, faXmark } from '~components/Icon';
import UnallocatedCard from '~weekly-planner/components/Card/UnallocatedCard';

import { endBidding, startBidding } from '~appointments/actions/appointments';
import { getAssignableUsers } from '~weekly-planner/actions/appointments';
import { simulate } from '~weekly-planner/actions/unsaved';

import { filterUsers, orderUsers } from '~weekly-planner/reducers/users/users';
import {
  focusAppointment,
  focusWorker,
  mergeBiddingWorkers,
  toggleBidding,
  updateBiddingWorkers,
} from '~weekly-planner/reducers/weeklyPlanner';

import { selectFocusedAppointment, selectFocusedWorkerId } from '~weekly-planner/selectors';
import { selectAssignableUsers } from '~weekly-planner/selectors/appointments';
import { selectAllSimulatedChanges, selectFilteredSimulatedWorkers } from '~weekly-planner/selectors/simulate';
import { selectAllChangesCount } from '~weekly-planner/selectors/unsaved';

const FocusBar: React.FC = () => {
  const dispatch = useAppDispatch();
  const workers = useAppSelector(selectFilteredSimulatedWorkers);
  const changesCount = useAppSelector(selectAllChangesCount);
  const focused = useAppSelector(selectFocusedAppointment);
  const focusWorkerId = useAppSelector(selectFocusedWorkerId);
  const assignableUsers = useAppSelector(selectAssignableUsers);
  const data = useAppSelector(selectAllSimulatedChanges);

  const { appointment, isAvailabilityView, isBiddingView, newBidders } = focused || {};
  const { key: focusKey, bidding_user_ids, is_bidding } = appointment || {};

  const filterOptions = Object.values(FilterOptions).map((option) => ({
    value: option,
    label: option,
  }));

  const radioOptionsArray: { id: string; value: 'Times' | 'Distance' }[] = [
    { id: 'timesOption', value: RadioOptions.TIMES },
    { id: 'distanceOption', value: RadioOptions.DISTANCE },
  ];

  const miscFilterOptions = Object.values(CareWorkerFilterOptions).map((option) => ({
    value: option,
    label: option,
  }));

  const multiSelectFilterMap: Partial<Record<string, keyof AssignableUser>> = {
    Available: 'is_available',
    Preferred: 'is_preferred',
    Historical: 'is_historical',
  };

  const [multiSelectValue, setMultiSelectValue] = useState<any[]>([]);
  const [isTimes, setIsTimes] = useState<boolean>(isAvailabilityView);
  const [isBidding, setIsBidding] = useState<boolean>(isBiddingView);
  const [confirmBiddingOpen, setConfirmBiddingOpen] = useState(false);
  const [cancelBiddingOpen, setCancelBiddingOpen] = useState(false);
  const [selectOptions, setSelectOptions] = useState<AssignableUser[]>([]);

  useEffect(() => {
    if (!assignableUsers.length) return;

    const sortedUsers = [...assignableUsers].sort((a, b) => {
      if (a.is_available !== b.is_available) return a.is_available ? -1 : 1;
      if (a.is_qualified !== b.is_qualified) return a.is_qualified ? -1 : 1;
      return a.name.localeCompare(b.name);
    });

    const selectOptions = sortedUsers.map(({ id, name, is_qualified, is_available }) => ({
      id,
      name: `${name}${!is_qualified ? ' (NQ)' : ''}${!is_available ? ' (NA)' : ''}`,
      is_qualified,
      is_available,
    }));

    setSelectOptions(selectOptions);
  }, [assignableUsers, focusWorkerId]);

  useEffect(() => {
    if (!appointment) return;
    dispatch(getAssignableUsers({ id: appointment.key }));
  }, [appointment, dispatch]);

  const getOptionStyle = (option: any) => {
    let backgroundColor = 'white';
    if (!option.is_available) backgroundColor = 'lightpink';
    else if (!option.is_qualified) backgroundColor = 'lightyellow';

    return {
      backgroundColor,
      color: 'black',
    };
  };

  const allocateWorker = () => {
    if (!focusWorkerId || !appointment) return;
    const change = buildAssignChange(data, [{ ...appointment, user: undefined, user_id: focusWorkerId }]);
    dispatch(simulate({ ...change, week_start: dayjs(appointment.date).weekday(0) }));
  };

  const handleFocusWorkerChanged = (value: any) => {
    dispatch(focusWorker(value ?? undefined));
  };

  // reset filters if focused appointment changes
  useEffect(() => {
    if (appointment) {
      handleSelectChange('is_qualified', filterOptions[0]);
      radioChanged(isAvailabilityView ? 'Times' : 'Distance');
      handleMultiSelectChange([]);
    }
  }, [focused?.appointment?.id, isAvailabilityView]);

  useEffect(() => {
    dispatch(toggleBidding({ isBiddingView: isBidding }));
  }, [isBidding]);

  // pass key & relevant id collection to reducer for filtering rows
  const handleSelectChange = (
    key: keyof AssignableUser,
    option: SingleValue<{
      value: 'All' | 'Yes' | 'No';
      label: 'All' | 'Yes' | 'No';
    }>,
  ) => {
    let userIds = [];

    if (option?.value !== FilterOptions.ALL) {
      userIds = assignableUsers
        .filter(
          (user) => typeof user[key] === 'boolean' && (option?.value === FilterOptions.YES ? user[key] : !user[key]),
        )
        .map((x) => x.id);

      dispatch(filterUsers({ key, value: userIds }));
    } else {
      dispatch(filterUsers({ key, value: null }));
    }
  };

  const radioChanged = (value: 'Times' | 'Distance') => {
    setIsTimes(value === 'Times');
    dispatch(orderUsers({ availability: value === 'Times', distance: value === 'Distance' }));
  };

  const handleMultiSelectChange = (selectedOptions: any) => {
    setMultiSelectValue(selectedOptions);
    const selectedOptionValues = new Set(selectedOptions.map((opt: any) => opt.value));

    const filters: Record<string, number[] | null> = {};

    Object.entries(multiSelectFilterMap).forEach(([option, key]) => {
      if (!key) return;

      filters[key] = selectedOptionValues.has(option)
        ? assignableUsers.filter((user) => user[key] === true).map((x) => x.id)
        : null;
    });

    dispatch(filterUsers(filters));
  };

  const refreshWorkers = (isChecked: boolean) => {
    dispatch(updateBiddingWorkers({ workerIds: workers.map((worker: Worker) => worker.id), isChecked }));
  };

  const onConfirmBidding = (confirm: boolean) => {
    setConfirmBiddingOpen(false);
    if (confirm) {
      dispatch(
        startBidding({
          id: focusKey,
          biddingUserIds: [...(bidding_user_ids ?? []), ...(newBidders ?? [])],
        }),
      );
      dispatch(mergeBiddingWorkers(null));
    }
  };

  const onCancelBidding = (confirm: boolean) => {
    setCancelBiddingOpen(false);
    if (confirm) {
      dispatch(endBidding({ id: focusKey }));
      //TODO: fix state / reducer to render is_bidding = false changes
    }
  };

  return (
    <div className="focus-bar-container">
      <ConfirmDialog
        isOpen={confirmBiddingOpen}
        messages={[
          ...((bidding_user_ids?.length ?? 0) > 0 && (newBidders?.length ?? 0) > 0
            ? [
                `You are about to update a bidding event with ${newBidders?.length ?? 0} new workers (${(newBidders?.length ?? 0) + (bidding_user_ids?.length ?? 0)} total)`,
              ]
            : [`You are about to create a bidding event for ${newBidders?.length ?? 0} workers. Are you sure?`]),
          ...(changesCount !== 0
            ? [`You have ${changesCount} unsaved changes. We recommend saving before creating a bidding event`]
            : []),
        ]}
        onClose={(confirm) => onConfirmBidding(confirm)}
      />
      <ConfirmDialog
        isOpen={cancelBiddingOpen}
        messages={[
          `Cancel bidding for Appointment #${focusKey}? Awaiting response from ${bidding_user_ids?.length} workers`,
        ]}
        onClose={(confirm) => onCancelBidding(confirm)}
      />
      <div className="d-flex align-items-center">
        {focused && (
          <div
            role="button"
            className="p-1"
            onClick={() => dispatch(focusAppointment(null))}
            title="Unfocus appointment"
          >
            <Icon icon={faXmark} size="lg" color="red" />
          </div>
        )}
        <div className="w-100">{appointment && <UnallocatedCard appointment={appointment} showStatus={true} />}</div>
      </div>
      <div className="d-flex align-items-center justify-content-between ms-3 me-3 p-2">
        <Label className="fw-bold m-1">Assign worker:</Label>
      </div>
      <div className="ms-3 me-3 p-2">
        <Select
          key={focusWorkerId || null}
          id={`allocate-user`}
          name={`allocate-user`}
          type="select"
          placeholder="Worker"
          value={focusWorkerId}
          options={selectOptions}
          className={'form-input-multi-select'}
          getOptionStyle={getOptionStyle}
          onChange={(_id, value) => handleFocusWorkerChanged(value)}
        />
      </div>
      <div className="d-flex align-items-center ms-3 me-3 p-2">
        <Button size="sm" color="success" className="w-100" disabled={!focusWorkerId} onClick={allocateWorker}>
          Allocate
        </Button>
      </div>
      <div className="pt-2 border-top">
        <div className="d-flex align-items-center justify-content-between ms-3 me-3 p-2">
          <Label className="fw-bold m-1">Qualified:</Label>
          <ReactSelect
            id={'qualified-filter'}
            name={`qualified-filter`}
            options={filterOptions}
            defaultValue={filterOptions[0]}
            isClearable={false}
            isSearchable={false}
            closeMenuOnSelect={true}
            onChange={(option) => handleSelectChange('is_qualified', option)}
          />
        </div>
        <div className="d-flex align-items-center justify-content-between ms-3 me-3 p-2">
          <Label className="fw-bold m-1">Show:</Label>
        </div>
        <div className="ms-3 me-3 p-2">
          <ReactSelect
            isMulti
            id={`misc-filter`}
            name={`misc-filter`}
            options={miscFilterOptions}
            value={multiSelectValue}
            classNamePrefix={'form-input-multi-select'}
            placeholder={'All'}
            onChange={(option) => handleMultiSelectChange(option)}
          />
        </div>
        <br />
        <Label className="fw-bold ms-3 me-3 ps-2">Highlight:</Label>
        <div className="d-flex align-items-center justify-content-between ms-3 me-3 p-2">
          {radioOptionsArray.map((option) => (
            <Label key={option.id} className="me-2">
              <Input
                type="radio"
                name="radioGroup"
                value={option.value}
                checked={isTimes === (option.value === 'Times')}
                onChange={() => radioChanged(option.value)}
                className="ms-3 me-3"
              />
              {option.value}
            </Label>
          ))}
        </div>
      </div>
      <div className="pt-2 border-top">
        <div className="d-flex align-items-center justify-content-between ms-3 me-3 p-2">
          <Label className="fw-bold m-1">Bidding:</Label>
          <Switch
            type="switch"
            id="unallocated_bidding_mode"
            name="unallocated_bidding_mode"
            value={isBidding ?? ''}
            onChange={() => setIsBidding(!isBidding)}
            className="d-flex m-1"
          />
        </div>
        {isBidding && (
          <>
            <div className="d-flex align-items-center justify-content-between ms-3 me-3 p-2">
              <Label className="m-1">Selected:</Label>
              <ReadOnly
                type="readonly"
                id="bidding-count"
                name="bidding-count"
                value={newBidders?.length ?? 0}
              ></ReadOnly>
            </div>
            <div className="d-flex align-items-center justify-content-between ms-3 me-3 p-2">
              <Button size="sm" color="success" onClick={() => refreshWorkers(true)}>
                <Icon className="me-2" icon={faPlus} />
                Select All
              </Button>
              <Button size="sm" color="warning" onClick={() => refreshWorkers(false)}>
                <Icon className="me-2" icon={faMinus} />
                Clear All
              </Button>
            </div>
            <div className="d-flex align-items-center ms-3 me-3 p-2">
              <Button
                size="sm"
                color="success"
                className="w-100"
                onClick={() => setConfirmBiddingOpen(true)}
                disabled={!newBidders?.length}
              >
                <Icon className="me-2" icon={faCommentSms} />
                {is_bidding ? 'Amend Bidding' : 'Start Bidding'}
              </Button>
            </div>
            {is_bidding && (
              <div className="d-flex align-items-center ms-3 me-3 p-2">
                <Button size="sm" color="danger" className="w-100" onClick={() => setCancelBiddingOpen(true)}>
                  <Icon className="me-2" icon={faXmark} />
                  Cancel Bidding
                </Button>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default React.memo(FocusBar);
