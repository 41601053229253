import { combineReducers } from '@reduxjs/toolkit';

import activities from './activities';
import appointmentsReducer from './appointments';
import cancellationReasons from './cancellationReasons';
import careQualificationLevels from './careQualificationLevels';
import locations from './locations';
import repeatFrequencies from './repeatFrequencies';
import serviceTypes from './serviceTypes';
import statuses from './statuses';
import userAppointments from './userAppointments';

export const appointments = {
  activities,
  appointments: appointmentsReducer,
  cancellationReasons,
  careQualificationLevels,
  locations,
  repeatFrequencies,
  serviceTypes,
  statuses,
  userAppointments,
};

export default combineReducers(appointments);
