import React, { useCallback, useEffect, useRef } from 'react';
import { useAppDispatch, useAppSelector } from 'hooks';
import { Col, Container, Row } from 'reactstrap';
import dayjs from 'dayjs';
import { throttle } from 'lodash';

import { weeklyPlannerDayDisplayFormat } from '~libs/dayjs';

import Button from '~components/Button';
import Icon, { faEye } from '~components/Icon';
import Spinner from '~components/Spinner';

import { getAll as getRejected } from '~weekly-planner/actions/appointments/rejected';

import { clear, updateParams } from '~weekly-planner/reducers/appointments/rejected';
import { focusWorker } from '~weekly-planner/reducers/weeklyPlanner';

import {
  selectAll,
  selectHasLoaded,
  selectLoading,
  selectParams,
} from '~weekly-planner/selectors/appointments/rejected';

interface ComponentProps {
  weekStart: string;
}

const Rejected: React.FC<ComponentProps> = React.memo(({ weekStart: wpWeekStart }) => {
  const dispatch = useAppDispatch();
  const params = useAppSelector(selectParams);
  const loading = useAppSelector(selectLoading);
  const hasLoaded = useAppSelector(selectHasLoaded);
  const rejectedAppointments = useAppSelector(selectAll);

  const isLoading = loading === 'pending';
  const { limit, week_start } = params;

  // Load on scroll
  const containerRef = useRef<HTMLDivElement>(null);
  const onScroll = useCallback(
    throttle(async () => {
      if (!containerRef.current || isLoading) return;

      const { scrollHeight, scrollTop, clientHeight } = containerRef.current;
      const nearBottom = scrollHeight - (scrollTop + clientHeight) < 50;

      if (!isLoading && nearBottom && limit <= rejectedAppointments.length) {
        dispatch(updateParams({ ...params, limit: limit + 30 }));
      }
    }, 200),
    [limit, dispatch, isLoading],
  );

  useEffect(() => {
    const container = containerRef.current;
    if (!container) return;

    container.addEventListener('scroll', onScroll, { passive: true });
    return () => container.removeEventListener('scroll', onScroll);
  }, [onScroll]);

  // Fetch data on param change
  useEffect(() => {
    if (!isLoading && week_start && limit) {
      dispatch(getRejected(params));
    }
  }, [params]);

  // Update params on date change
  useEffect(() => {
    if (!hasLoaded || wpWeekStart !== week_start) {
      dispatch(updateParams({ week_start: wpWeekStart, limit: 30 }));
    }
  }, [hasLoaded, wpWeekStart, week_start]);

  // unmount & clear reducer
  useEffect(() => {
    return () => {
      dispatch(clear([]));
    };
  }, []);

  const renderResults = () => {
    return rejectedAppointments.map(({ client, user, date }, index) => {
      return (
        <Row key={index} className="underline">
          <Col sm={3}>{dayjs(date).format(weeklyPlannerDayDisplayFormat)}</Col>
          <Col>{user?.full_name}</Col>
          <Col>{client.full_name}</Col>
          <Col sm={1} className="text-center">
            <Button size="sm" className="bg-white text-dark border-0" onClick={() => dispatch(focusWorker(user?.id))}>
              <Icon icon={faEye} />
            </Button>
          </Col>
        </Row>
      );
    });
  };

  const hasData = rejectedAppointments.length > 0;

  return (
    <div ref={containerRef} className="card-container">
      <Container className="rejected-container inner-content limited">
        {hasData ? (
          <>
            <Row className="fw-bold row underline flex-nowrap">
              <Col sm={3}>Day</Col>
              <Col>Worker</Col>
              <Col>Client</Col>
              <Col sm={1} />
            </Row>

            {renderResults()}
          </>
        ) : (
          hasLoaded && <p className="no-result">No Rejected Appointments Found.</p>
        )}
        <Spinner loading={isLoading} className="p-5" />
      </Container>
    </div>
  );
});

Rejected.displayName = 'Rejected';
export default Rejected;
