import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'store';
const UnallocatedState = (state: RootState) => state.weeklyPlanner.unallocated;

const selectRows = (state: RootState) => UnallocatedState(state).rows;
export const selectLoading = (state: RootState) => UnallocatedState(state).loading;
export const selectHasLoaded = (state: RootState) => UnallocatedState(state).hasLoaded;
export const selectParams = (state: RootState) => UnallocatedState(state).params;
export const selectCount = (state: RootState) => UnallocatedState(state).count;
export const selectOptions = (state: RootState) => UnallocatedState(state).options;

export const selectAll = createSelector([selectRows], (rows) =>
  rows.map((row) => ({ ...row, key: row.id ?? row.parent_repeat_id })),
);
