import React from 'react';

interface ComponentProps {
  logo?: string;
  altText: string;
}

/**
 * CompanyLogo component renders the company logo or the company name as fallback text.
 *
 * If the `logo` prop is provided, it renders the logo image from the URL. Otherwise, it displays
 * the `altText` as the company name.
 *
 * @component
 * @param  props
 * @returns The rendered CompanyLogo component.
 */
const CompanyLogo: React.FC<ComponentProps> = ({ logo, altText }) => {
  const IMAGE_URL = process.env.REACT_APP_TPONE_LOGO_URL;
  return (
    <div className="company-logo">
      {logo ? (
        <img src={`${IMAGE_URL}//${logo}`} alt={`Company Logo ${altText}`} className="company-logo-img" />
      ) : (
        altText && <span className="company-name">{altText}</span>
      )}
    </div>
  );
};

export default CompanyLogo;
