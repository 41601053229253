import { AuthTags } from './authTags';
import { getTP1Urls } from './routes-tp1';

export const APPLICATION_TITLE = process.env.REACT_APP_TITLE ?? 'Turnpoint Assist';

type MenuItem = {
  caption: string;
  link?: string;
  children?: Array<MenuItem>;
  authTag?: string;
};

const headerMenu = (user: any, settings: any, companyDefaults: any): Array<MenuItem> => {
  const tp1urls = getTP1Urls(user, settings, companyDefaults);

  return [
    {
      caption: 'Home',
      link: tp1urls.Home,
    },
    {
      caption: 'Appointments',
      authTag: AuthTags.APPOINTMENTS.VIEWALL,
      children: [
        { caption: 'Appointments', link: '/appointments' },
        { caption: 'Repeats', link: '/appointments/repeats' },
        { caption: 'Weekly Planner', link: tp1urls.APPOINTMENTS.WeeklyPlanner },
        { caption: 'Timesheets', link: tp1urls.APPOINTMENTS.Timesheets },
      ],
    },
    {
      caption: 'Daily Timesheets',
      authTag: AuthTags.APPOINTMENTS.TIMESHEETS.VIEW,
      children: [
        { caption: 'Daily Timesheets', link: tp1urls.APPOINTMENTS.Timesheets },
        { caption: 'My Appointments', link: '/appointments' },
        { caption: 'My Availability', link: tp1urls.APPOINTMENTS.AvailabilityEditor },
      ],
    },
    {
      caption: 'Client Info',
      authTag: AuthTags.CLIENTS.INFO.VIEW,
      children: [
        { caption: 'View All', link: tp1urls.CLIENTINFO.ViewAll },
        { caption: 'Incidents', link: tp1urls.CLIENTINFO.Incidents },
        { caption: 'SOAP Notes', link: tp1urls.CLIENTINFO.SOAPNotes },
      ],
    },
    {
      caption: 'Clients',
      authTag: AuthTags.COMPANY.CLIENTS.VIEW,
      children: [
        { caption: 'Clients', link: tp1urls.COMPANYCRM.Clients },
        { caption: 'Add Note', link: tp1urls.COMPANYCRM.AddNote, authTag: AuthTags.COMPANY.CLIENTNOTES.VIEW },
        { caption: 'Notes Search', link: tp1urls.COMPANYCRM.NotesSearch, authTag: AuthTags.COMPANY.CLIENTNOTES.VIEW },
        {
          caption: 'On Hold Periods',
          link: tp1urls.COMPANYCRM.OnHoldPeriods,
          authTag: AuthTags.COMPANY.CLIENTHOLDPERIODS.VIEW,
        },
      ],
    },
    {
      caption: 'Care Workers',
      authTag: AuthTags.USERS.VIEWALL,
      children: [
        { caption: 'Care Workers', link: tp1urls.CAREWORKERS.CareWorkers },
        {
          caption: 'Standard Availability',
          link: tp1urls.CAREWORKERS.StandardAvailability,
          authTag: AuthTags.USERS.AVAILABILITY.VIEW,
        },
        { caption: 'User Admin', link: tp1urls.CAREWORKERS.UserAdmin, authTag: AuthTags.USERS.ADMIN.VIEW },
      ],
    },
    {
      caption: 'Equipment',
      authTag: AuthTags.SETTINGS.EQUIPMENT.VIEW,
      children: [
        { caption: 'Stock Levels', link: tp1urls.EQUIPMENT.StockLevels },
        { caption: 'Care Worker Usage', link: tp1urls.EQUIPMENT.CareWorkerUsage },
        { caption: 'Medications', link: tp1urls.EQUIPMENT.Medications },
      ],
    },
    {
      caption: 'Invoices',
      authTag: AuthTags.INVOICES.VIEW,
      children: [
        { caption: 'Invoice Export', link: tp1urls.INVOICES.InvoiceExport },
        { caption: 'All Invoices', link: tp1urls.INVOICES.ClientInvoices },
        { caption: 'NDIS Invoices', link: tp1urls.INVOICES.NDISInvoices, authTag: AuthTags.INVOICES.NDIS.VIEW },
        { caption: 'Generate', link: tp1urls.INVOICES.GenerateNDISInvoices, authTag: AuthTags.INVOICES.NDIS.VIEW },
      ],
    },
    {
      caption: 'Payroll',
      authTag: AuthTags.PAYROLL.VIEW,
      children: [
        { caption: 'Payroll Export', link: tp1urls.PAYROLL.PayrollExport },
        { caption: 'Payroll Entries', link: tp1urls.PAYROLL.PayrollEntries },
        { caption: 'Appointment Status', link: tp1urls.PAYROLL.AppointmentStatus },
        { caption: 'New Payroll Item', link: tp1urls.PAYROLL.NewPayrollItem },
      ],
    },
    {
      caption: 'Leave Requests',
      authTag: AuthTags.LEAVEREQUESTS.VIEW,
      children: [
        { caption: 'New Request', link: tp1urls.LEAVEREQUESTS.NewRequest },
        { caption: 'Pending Requests', link: tp1urls.LEAVEREQUESTS.PendingRequests },
        { caption: 'All Requests', link: tp1urls.LEAVEREQUESTS.AllRequests },
      ],
    },
    {
      caption: 'Documents',
      authTag: AuthTags.DOCUMENTS.VIEW,
      children: [
        { caption: 'All Documents', link: tp1urls.DOCUMENTS.AllDocuments },
        { caption: 'New Document', link: tp1urls.DOCUMENTS.NewDocument, authTag: AuthTags.SETTINGS.ADMIN.VIEW },
        { caption: 'Folder Admin', link: tp1urls.DOCUMENTS.FolderAdmin, authTag: AuthTags.SETTINGS.ADMIN.VIEW },
      ],
    },
    {
      caption: 'Other',
      authTag: AuthTags.SETTINGS.VIEW,
      children: [
        { caption: 'Tech Support', link: tp1urls.SETTINGSCONFIG.TechSupport },
        { caption: 'Task Types', link: tp1urls.SETTINGSCONFIG.TaskTypes },
        { caption: 'Packages', link: tp1urls.SETTINGSCONFIG.Packages, authTag: AuthTags.SETTINGS.ADMIN.VIEW },
        { caption: 'Service Types', link: tp1urls.SETTINGSCONFIG.ServiceTypes, authTag: AuthTags.SETTINGS.ADMIN.VIEW },
        {
          caption: 'Billing / Pay Rules',
          link: tp1urls.SETTINGSCONFIG.BillingPayRules,
          authTag: AuthTags.SETTINGS.ADMIN.VIEW,
        },
        { caption: 'Referrers', link: tp1urls.SETTINGSCONFIG.Referrers, authTag: AuthTags.SETTINGS.ADMIN.VIEW },
        { caption: 'Events Admin', link: tp1urls.SETTINGSCONFIG.EventsAdmin, authTag: AuthTags.SETTINGS.ADMIN.VIEW },
        { caption: 'System Activity', link: tp1urls.REPORTS.SystemActivity },
        { caption: 'Location Map', link: tp1urls.CAREWORKERS.LocationMap },
      ],
    },
  ];
};

export default headerMenu;
