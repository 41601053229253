import React, { Fragment, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'hooks';
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Label } from 'reactstrap';

import Icon, { faEye, faEyeSlash } from '~components/Icon';

import { toggleAwardAlertVisibility } from '~weekly-planner/reducers/awardAlerts';

import {
  selectAwardAlertsVisibilities,
  selectAwardAlertTypes,
} from '~weekly-planner/selectors/appointments/awardAlerts';

const AwardAlertViewMenu: React.FC = () => {
  const dispatch = useAppDispatch();
  const [isViewOpen, setIsViewOpen] = useState(false);
  const alertTypes = useAppSelector(selectAwardAlertTypes);
  const alertVisibilities = useAppSelector(selectAwardAlertsVisibilities);

  const renderItem = (onClick: (value?: any) => void, iconToggle: boolean, label: any) => (
    <DropdownItem toggle={false} onClick={onClick}>
      <Icon icon={iconToggle ? faEye : faEyeSlash} className="mt-1" />
      <Label className="ms-2 mb-0">{label}</Label>
    </DropdownItem>
  );

  return (
    <Dropdown isOpen={isViewOpen} toggle={() => setIsViewOpen(!isViewOpen)} direction="down">
      <DropdownToggle tag="span" className="action-view">
        <Icon icon={faEye} onClick={() => setIsViewOpen(!isViewOpen)} />
      </DropdownToggle>
      <DropdownMenu>
        <DropdownItem header>View Options</DropdownItem>
        {alertTypes.map((alert) => (
          <Fragment key={alert.tag}>
            {renderItem(
              () => {
                dispatch(
                  toggleAwardAlertVisibility({
                    key: alert.tag,
                    toggle: !(alertVisibilities?.[alert.tag] ?? true),
                  }),
                );
              },
              alertVisibilities?.[alert.tag] ?? true,
              alert.caption,
            )}
          </Fragment>
        ))}
      </DropdownMenu>
    </Dropdown>
  );
};

export default AwardAlertViewMenu;
