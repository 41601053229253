import React, { PropsWithChildren, ReactNode, useCallback, useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import Icon, { faChevronLeft, faChevronRight } from 'tsx/components/Icon';

interface Props extends PropsWithChildren {
  className?: string;
  sidePanel?: ReactNode;
  isCollapsed?: boolean;
  setIsCollapsed?: (toggle: boolean) => void;
  canCollapse?: boolean;
  canResize?: boolean;
}

const ContainerPanel: React.FC<Props> = ({
  className,
  sidePanel,
  children,
  isCollapsed,
  setIsCollapsed,
  canCollapse = true,
  canResize = true,
}) => {
  const ref = useRef<HTMLDivElement | null>(null);
  const [isSizing, setSizing] = useState(false);
  const [isCollapsedUncontrolled, setIsCollapsedUncontrolled] = useState(false);
  const [width, setWidth] = useState<number | undefined>(undefined);

  const start = () => setSizing(true);
  const stop = () => setSizing(false);
  const toggle = () => {
    if (isCollapsed && setIsCollapsed) setIsCollapsed(!isCollapsed);
    else setIsCollapsedUncontrolled(!isCollapsedUncontrolled);
  };

  const onSize = useCallback(
    (event: MouseEvent) => {
      if (isSizing && ref.current) {
        const { clientX: x } = event;
        setWidth(x - ref.current.getBoundingClientRect().left);
      }
    },
    [isSizing],
  );

  useEffect(() => {
    window.addEventListener('mousemove', onSize);
    window.addEventListener('mouseup', stop);
    return () => {
      window.removeEventListener('mousemove', onSize);
      window.removeEventListener('mouseup', stop);
    };
  }, [onSize, stop]);

  const containerClass = classNames('container-panel', {
    [`${className}`]: className !== undefined,
    row: true,
  });

  const isCollapsedOpen = typeof isCollapsed == 'boolean' ? isCollapsed : isCollapsedUncontrolled;

  const sideClass = classNames('col col-sm-3 side-panel', {
    collapse: isCollapsedOpen,
    'no-gap': !canCollapse,
    'me-2': !canResize && !isCollapsedOpen && canCollapse,
  });

  return (
    <div className={containerClass}>
      <div className={sideClass} ref={ref} style={{ width }} onMouseDown={(e) => e.preventDefault()}>
        <div className="content">{sidePanel}</div>
        {canResize && <div className="sizer" onMouseDown={start} />}
        {canCollapse && (
          <div className="toggle" onClick={toggle}>
            <Icon className="icon" icon={!isCollapsedOpen ? faChevronLeft : faChevronRight} />
          </div>
        )}
      </div>
      <div className="main-panel col">{children}</div>
    </div>
  );
};

export default ContainerPanel;
