import React from 'react';
import { Button } from 'reactstrap';

/**
 * Props for the Footer component.
 * @property [renderFooterButtons] - Optional custom renderer for footer buttons.
 * @property handleNext - Function to handle moving to the next step.
 * @property handlePrev - Function to handle moving to the previous step.
 * @property currentStep - The index of the current step.
 * @property stepCount - Total number of steps in the wizard.
 * @property isStepValid - Indicates whether the current step is valid.
 */
interface ComponentProps {
  renderFooterButtons?: (
    handleNext: () => void,
    handlePrev: () => void,
    currentStep: number,
    stepCount: number,
  ) => React.ReactNode;
  handleNext: () => Promise<void>;
  handlePrev: () => Promise<void>;
  currentStep: number;
  stepCount: number;
  isStepValid: boolean;
  isSubmitting: boolean;
  handleSubmit: (event?: any) => void;
}

/**
 * Renders the footer buttons for the wizard form.
 *
 * This component can display either custom footer buttons through the
 * `renderFooterButtons` prop or default "Previous" and "Next" buttons.
 *
 * @param  props
 * @returns The rendered footer buttons.
 */
export const Footer: React.FC<ComponentProps> = ({
  renderFooterButtons,
  handleNext,
  handlePrev,
  currentStep,
  stepCount,
  isStepValid,
  isSubmitting,
  handleSubmit,
}) => {
  const canSubmit = !isStepValid || isSubmitting;

  return (
    <div className="wizard-footer">
      {renderFooterButtons ? (
        renderFooterButtons(handleNext, handlePrev, currentStep, stepCount)
      ) : (
        <div className="footer-buttons-wrapper">
          {currentStep > 0 && (
            <Button color="secondary" onClick={handlePrev} className="previous-button">
              Previous
            </Button>
          )}

          {currentStep === stepCount - 1 ? (
            <Button color="success" disabled={canSubmit} type="button" onClick={handleSubmit} className="submit-button">
              Submit
            </Button>
          ) : (
            <Button type="button" color="info" onClick={handleNext} className="next-button">
              Next
            </Button>
          )}
        </div>
      )}
    </div>
  );
};

export default Footer;
