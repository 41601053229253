import React, { useState } from 'react';
import { useAppSelector } from 'hooks';

import TableView from '~components/TableView';
import ModalCard from '~components/ModalCard';

import { onHoldColumns } from '~weekly-planner/forms/info-modal/onHold/list';

import { selectOnHold } from '~weekly-planner/selectors/appointments';

const HoldPeriods: React.FC = () => {
  const clientHoldPeriods = useAppSelector(selectOnHold);

  if (!clientHoldPeriods || !clientHoldPeriods.length) {
    return <p>No on hold periods.</p>;
  }

  const [isOnHoldOpen, setIsOnHoldOpen] = useState(true);

  return (
    <div className="container p-4">
      {clientHoldPeriods.length > 0 && (
        <ModalCard
          header="Clients On Hold"
          headerClassName="bg-info"
          isOpen={isOnHoldOpen}
          setIsOpen={setIsOnHoldOpen}
          className="mb-4"
          allowOpen={true}
          allowHeaderOpen={true}
        >
          <TableView
            id="on-hold-appointments"
            data={clientHoldPeriods}
            columns={onHoldColumns}
            showHeader={true}
            emptyMessage="No clients on hold."
          />
        </ModalCard>
      )}
    </div>
  );
};

export default HoldPeriods;
