import React from 'react';

import { InputProps } from '~components/FormFields';
import WorkerQualifications from '~weekly-planner/components/Common/WorkerQualifications';

function WizardReadOnlyQualifications({ value }: InputProps) {
  const { qualification_level } = value;

  return qualification_level?.length !== 0 ? (
    <WorkerQualifications qualifications={qualification_level} isCondensed={false} isWrapped={true} />
  ) : (
    <span>No default qualifications requirements</span>
  );
}

export default WizardReadOnlyQualifications;
