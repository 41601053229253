import React from 'react';
import { AdvancedMarker, APIProvider, Map, MapCameraChangedEvent, Pin } from '@vis.gl/react-google-maps';

import ContainerHeader from '~components/ContainerHeader';

interface Point {
  key: string;
  location: google.maps.LatLngLiteral;
}

const CareWorkerMap = () => {
  const mapsKey = process.env.REACT_APP_GOOGLE_MAPS_KEY ?? ''; // TODO: Add to default ENV

  const locations: Point[] = [
    { key: 'museum', location: { lat: -37.803014, lng: 144.971735 } },
    { key: 'albertPark', location: { lat: -37.850292, lng: 144.969808 } },
  ];

  const RenderMarkers = (props: { points: Point[] }) => {
    return (
      <>
        {props.points.map((point: Point) => {
          return (
            <AdvancedMarker key={point.key} position={point.location} clickable={true}>
              <Pin background={'#FBBC04'} glyphColor={'#000'} borderColor={'#000'} />
            </AdvancedMarker>
          );
        })}
      </>
    );
  };

  return (
    <div className="care-worker-map">
      <ContainerHeader>Care Workers Map - Current Location</ContainerHeader>
      <div className="map-wrapper">
        <APIProvider apiKey={mapsKey} onLoad={() => console.log('React Maps provider loaded')}>
          <Map
            defaultCenter={{ lat: -37.804073, lng: 144.971594 }}
            defaultZoom={10}
            disableDefaultUI={true}
            mapId="DEMO_MAP_ID" // TODO: Generate map ID in api console
            onCameraChanged={(ev: MapCameraChangedEvent) =>
              console.log('camera changed:', ev.detail.center, 'zoom:', ev.detail.zoom)
            }
          >
            <RenderMarkers points={locations} />
          </Map>
        </APIProvider>
      </div>
    </div>
  );
};

export default CareWorkerMap;
