import { buildSlice, CommonState, Row } from '~libs/reduxUtils';

import { typePrefix, getAll, getAssignableUsers, getExceptions, getOnHold } from '~weekly-planner/actions/appointments';
import { AssignableUser } from '~weekly-planner/lib/common';

const initialState: CommonState<Row> & {
  assignableUsers: Array<AssignableUser>;
  exceptions: { rescheduled: []; cancelled: [] };
  onHold: [];
} = {
  loading: 'idle',
  error: null,
  rows: [],

  assignableUsers: [],
  exceptions: { rescheduled: [], cancelled: [] },
  onHold: [],
};

const slice = buildSlice(typePrefix, initialState, {
  extraReducers: (builder) => {
    builder.addCase(getAssignableUsers.fulfilled, (state, action) => {
      state.loading = 'fulfilled';
      state.assignableUsers = action.payload.data;
    });
    builder.addCase(getExceptions.fulfilled, (state, action) => {
      state.loading = 'fulfilled';
      state.exceptions = action.payload.data;
    });
    builder.addCase(getOnHold.fulfilled, (state, action) => {
      state.loading = 'fulfilled';
      state.onHold = action.payload.data;
    });
  },
  commonConfig: { getAll },
});

export const { clear, updateParams } = slice.actions;

export default slice.reducer;
