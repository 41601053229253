import { createSlice, isPending, isRejected } from '@reduxjs/toolkit';
import { RootState } from 'store';
import { LoadingState } from '~libs/reduxUtils';

import { typePrefix, getAll, getOne } from '~appointments/actions/activities';

interface ActivityRow {
  id: number;
  date_time: string;
  user: {
    full_name: string;
    [key: string]: string;
  };
  details: Array<{ field: string; from: string; to: string }> | null;
  type: string;
}

interface ActivityState {
  loading: LoadingState;
  error: string | null | undefined;
  rows: Array<ActivityRow>;
  row?: ActivityRow;
}

const initialState: ActivityState = {
  loading: 'idle',
  error: null,
  rows: [],
};

// Main slice, connecting API actions to redux state.
export const activitiesSlice = createSlice({
  name: 'appointment-activities',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getAll.fulfilled, (state, action) => {
      state.loading = 'fulfilled';
      state.rows = action.payload.data;
    });
    builder.addCase(getOne.fulfilled, (state, action) => {
      state.loading = 'fulfilled';
      state.row = action.payload.data;
    });
    // Default matching for loading cases, pending when action is being called
    builder.addMatcher(isPending, (state, { type }) => {
      if (type.startsWith(`${typePrefix}/`)) state.loading = 'pending';
    });
    builder.addMatcher(isRejected, (state, action) => {
      if (action.type.startsWith(`${typePrefix}/`)) {
        state.loading = 'declined';
        state.error = action.error.message;
      }
    });
  },
});

// Selectors, performing common selection tasks for this slice.
export const selectAppointmentActivities = ({ appointments }: RootState) => appointments.activities.rows;

export default activitiesSlice.reducer;
