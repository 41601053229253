import { createSelector, createSlice, isPending, isRejected } from '@reduxjs/toolkit';
import { RootState } from 'store';
import { LoadingState } from '~libs/reduxUtils';

import { typePrefix, getOne, getAll } from '~care-workers/actions/userDepartments';

interface UserDepartmentRow {
  id: number;
  name?: string;
  company_id?: number;
}

interface UserDepartmentsState {
  loading: LoadingState;
  error: string | null | undefined;
  rows: Array<UserDepartmentRow>;
  row?: UserDepartmentRow;
}

const initialState: UserDepartmentsState = {
  loading: 'idle',
  error: null,
  rows: [],
};

// Main slice, connecting API actions to redux state.
export const userDepartmentsSlice = createSlice({
  name: 'user-departments',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getAll.fulfilled, (state, action) => {
      state.loading = 'fulfilled';
      state.rows = action.payload.data;
    });
    builder.addCase(getOne.fulfilled, (state, action) => {
      state.loading = 'fulfilled';
      state.row = action.payload.data;
    });
    // Default matching for loading cases, pending when action is being called
    builder.addMatcher(isPending, (state, { type }) => {
      if (type.startsWith(`${typePrefix}/`)) state.loading = 'pending';
    });
    builder.addMatcher(isRejected, (state, action) => {
      if (action.type.startsWith(`${typePrefix}/`)) {
        state.loading = 'declined';
        state.error = action.error.message;
      }
    });
  },
});

// Selectors, performing common selection tasks for this slice.
export const selectUserDepartments = ({ careWorkers }: RootState) => careWorkers.userDepartments.rows;
export const selectUserDepartmentByID = createSelector(
  [selectUserDepartments, (_: RootState, id?: number) => id],
  (rows, id) => rows.find(({ id: rowId }) => id === rowId),
);

export default userDepartmentsSlice.reducer;
