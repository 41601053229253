import { ColumnDef } from '@tanstack/react-table';
import { formatDateString } from 'tsx/libs/dayjs';

const commonColumns: ColumnDef<any>[] = [
  {
    header: 'Client',
    accessorKey: 'client_name', // Direct access to client_name in the data
  },
  {
    header: 'Time',
    accessorKey: 'start_time', // Direct access to start_time in the data
  },
];

const notLoggedInColumns: ColumnDef<any>[] = [
  {
    header: 'Client',
    accessorKey: 'full_name', // Direct access to full_name in the data
  },
  {
    header: 'Last Login',
    accessorKey: 'last_login',
    cell: (info) => (info.getValue() ? formatDateString(info.getValue()) : 'Never Logged In'), // Format last login date
  },
];

export { notLoggedInColumns, commonColumns };
