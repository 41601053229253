import React, { CSSProperties, forwardRef, ReactNode, useEffect, useRef } from 'react';
import { useDraggable } from '@dnd-kit/core';

interface ComponentProps {
  id: number;
  data: any;
  children: ReactNode;
  style?: CSSProperties;
}

const DraggableItem = forwardRef<HTMLDivElement, ComponentProps>(({ id, data, children, style }, ref) => {
  const elementRef = useRef<HTMLDivElement | null>(null);

  const { attributes, listeners, setNodeRef, isDragging } = useDraggable({
    id,
    data,
  });

  // Function to capture computed styles of the element
  useEffect(() => {
    if (elementRef.current) {
      data.children = children;
    }
  }, [elementRef, data, children]);

  const dragStyle = {
    ...style,
    opacity: isDragging ? 0.5 : 1,
    touchAction: 'none',
  };

  return (
    <div
      ref={(node) => {
        setNodeRef(node);
        elementRef.current = node;
        if (typeof ref === 'function') ref(node);
        else if (ref) (ref as React.MutableRefObject<HTMLDivElement | null>).current = node;
      }}
      {...attributes}
      {...listeners}
      style={dragStyle}
    >
      {children}
    </div>
  );
});

DraggableItem.displayName = 'DraggableItem';
export default DraggableItem;
