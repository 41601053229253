import React, { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from 'hooks';
import { Collapse, Table } from 'reactstrap';
import { InputProps } from 'tsx/components/FormFields';
import Icon, { faHourglassEnd, faHourglassStart } from 'tsx/components/Icon';
import { formatWeekday } from 'tsx/libs/dayjs';

import { getAvailability } from '../../care-workers/actions';
import { selectUserAvailability } from '../../care-workers/reducers/users';

const FormInputWorkerAvailability: React.FC<InputProps> = ({ value = {}, id }) => {
  // Check for change
  const dispatch = useAppDispatch();
  const availableDays = useAppSelector(selectUserAvailability);
  const { date, user_id, repeat, repeat_start } = value;

  const checkDate = repeat ? repeat_start : date;
  const isOpen = !isNaN(parseInt(user_id)) && checkDate.length > 0;

  useEffect(() => {
    // If user and date selected, check for leave requests in relevant period
    if (isOpen) {
      dispatch(
        getAvailability({
          id: user_id,
          appointment_date: checkDate,
        }),
      );
    }
  }, [date, user_id, repeat_start]);

  const headerClassName = 'ps-2 pe-2 border-bottom';
  const headerCols = [<th key={0} className={headerClassName}></th>];

  const dayShifts: string[][] = Array.from({ length: 7 }, () => []);

  const renderCols = () => {
    // Find the maximum number of shifts across all days
    const rowCount = Math.max(...dayShifts.map((shifts) => shifts.length)) / 2;

    const tableRows = [];

    // Setup rows by highest count
    for (let i = 0; i < rowCount; i++) {
      const row = [];
      row.push(<td key={`col-${i}`}></td>);
      for (let day = 0; day < 7; day++) {
        const shifts = dayShifts[day];
        const start = shifts[i * 2];
        const end = shifts[i * 2 + 1];

        if (i === 0) {
          row[0] = (
            <td key={`col-${i}`} title="Shift Start-End">
              <Icon icon={faHourglassStart} /> - <Icon icon={faHourglassEnd} />
            </td>
          );
        }

        // Render the time for each day in the current row
        row.push(
          <td key={day}>
            {start && (
              <>
                {start} - {end}
              </>
            )}
          </td>,
        );
      }

      tableRows.push(<tr key={i}>{row}</tr>);
    }

    return tableRows;
  };

  for (let index = 1; index <= 7; index++) {
    const weekDay = formatWeekday(index);

    // Create header starting from Monday
    headerCols.push(
      <th key={index} className={headerClassName}>
        {weekDay}
      </th>,
    );

    // Build availability based on current available days in selector
    const availableDay = availableDays && availableDays[weekDay as keyof typeof availableDays];
    const timeList: string[] = [];

    // If day is split
    if (Array.isArray(availableDay)) {
      availableDay.forEach((split) => timeList.push(split.start, split.end));
      dayShifts[index - 1].push(...timeList);
    }

    // If not split & not empty
    else if (availableDay) {
      let { start, end } = availableDay;
      if (!start || start === '00:00') start = '';
      if (!end || end === '00:00') end = '';

      if (start.length > 0) start = start.padStart(4, '0');
      if (end.length > 0) end = end.padStart(4, '0');

      dayShifts[index - 1].push(start, end);
    }
  }

  return (
    <Collapse isOpen={isOpen}>
      <Table className="text-center" data-id={id}>
        <thead>
          <tr>{headerCols}</tr>
        </thead>
        <tbody>{renderCols()}</tbody>
      </Table>
    </Collapse>
  );
};

export default FormInputWorkerAvailability;
