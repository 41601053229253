import { createSelector, createSlice, isPending, isRejected } from '@reduxjs/toolkit';
import { CommonState, Row } from '~libs/reduxUtils';
import { typePrefix, getAll, getOne } from '~main/actions/clients/clientPackages';
import { RootState } from 'store';

const initialState: CommonState<Row> = {
  loading: 'idle',
  error: null,
  rows: [],
  row: { id: -1 },
};

// Main slice, connecting API actions to redux state.
export const clientPackagesSlice = createSlice({
  name: 'client-packages',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getAll.fulfilled, (state, action) => {
      state.loading = 'fulfilled';
      state.rows = action.payload.data;
    });
    builder.addCase(getOne.fulfilled, (state, action) => {
      state.loading = 'fulfilled';
      state.row = action.payload.data;
    });

    builder.addMatcher(isPending, (state, { type }) => {
      if (type.startsWith(`${typePrefix}/`)) state.loading = 'pending';
    });
    builder.addMatcher(isRejected, (state, action) => {
      if (action.type.startsWith(`${typePrefix}/`)) {
        state.loading = 'declined';
        state.error = action.error.message;
      }
    });
  },
});

export const selectClientPackages = ({ main }: RootState) => main.clientPackages.rows;

export const selectLookupClientPackages = createSelector(selectClientPackages, (rows) =>
  rows.map(({ id, name }) => ({
    id,
    name,
  })),
);

export default clientPackagesSlice.reducer;
