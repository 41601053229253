import { AuthTags } from '~constants/authTags';

import CareWorkerMap from '~dashboard/components/CareWorkerMap';
import UpcomingAppointments from '~dashboard/components/UpcomingAppointments';
import WelcomeMessage from '~dashboard/components/WelcomeMessage';

export const common = [
  {
    id: 'welcome',
    authTag: AuthTags.DASHBOARD.VIEW,
    component: WelcomeMessage,
  },
  {
    id: 'upcoming',
    authTag: AuthTags.DASHBOARD.UPCOMINGAPPOINTMENTS.VIEW,
    component: UpcomingAppointments,
  },
  {
    id: 'workerMap',
    authTag: AuthTags.DASHBOARD.VIEW,
    component: CareWorkerMap,
  },
];
