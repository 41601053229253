import React from 'react';

import { FieldProp } from './index';
import FormField from './Field';

import { formatDateTimeValue } from 'tsx/libs/dayjs';
import { getNestedValue } from 'tsx/libs/records';

import { Row } from '~libs/reduxUtils';

export interface Definition extends FieldProp {
  key: string;
}

interface ComponentProps {
  authTag?: string;
  definitions: Array<Definition>;
  row?: Row;
  values: { [key: string]: string | number | boolean | object | null };
  invalidFields?: string[];
  size?: 'sm' | 'lg';
  setFieldValue?: any;
  setFieldTouched?: any;
  touched?: any;
}

const FormFields: React.FC<ComponentProps> = ({
  authTag,
  definitions,
  row,
  values,
  invalidFields = [],
  size = 'lg',
  setFieldValue,
  setFieldTouched,
  touched,
}) => {
  // Build fields systematically based on form fields
  const fields = definitions
    .sort((a, b) => (a.order ?? 0) - (b.order ?? 0))
    .map(
      (
        {
          key,
          type,
          className,
          field,
          show,
          description,
          deriveDescription,
          link,
          deriveLink,
          warning,
          deriveWarning,
          initialOption,
          deriveValue,
          selectorParams,
          defaultValue,
          dependencies,
          ...definition
        },
        index,
      ) => {
        const onChange = async (name: string, value: any) => {
          setFieldValue(name, value);
          setFieldTouched(name, true);

          if (definition.onChange) {
            const changeValues = await definition.onChange({
              ...values,
              [name]: value,
            });

            if (changeValues) {
              Object.entries(changeValues).forEach(([key, value]) => setFieldValue(key, value));
            }
          }
        };

        const id = field ?? key;
        const functionValues = { ...values, touched };

        // Declare and derive value based on ID
        const dateTimeTypes = ['date', 'time'];
        let value = values[id];
        if (defaultValue !== undefined && value === undefined) value = defaultValue;
        if (id.includes('.') && row) value = getNestedValue(id, row);
        if (type && dateTimeTypes.includes(type)) value = formatDateTimeValue(type, value);
        if (dependencies && dependencies.length > 0) {
          const dependant: any = {};
          dependencies.forEach((id: string) => {
            dependant[id] = values[id];
          });

          value = dependant;
        }
        if (deriveValue) value = deriveValue(functionValues);

        // extract & parse initialOption for select lists
        if (initialOption && row && initialOption.name === '') {
          initialOption = row[initialOption.id];
        }

        return (
          <FormField
            {...definition}
            authTag={authTag}
            key={index}
            type={type}
            size={size}
            id={id}
            fieldId={key}
            value={value}
            selectorParams={typeof selectorParams === 'function' ? selectorParams(functionValues) : selectorParams}
            initialOption={initialOption}
            show={typeof show === 'function' ? show(functionValues) : show}
            description={typeof deriveDescription === 'function' ? deriveDescription(functionValues) : description}
            link={typeof deriveLink === 'function' ? deriveLink(functionValues) : link}
            warning={typeof deriveWarning === 'function' ? deriveWarning(functionValues) : warning}
            invalid={invalidFields.includes(id)}
            className={`p-2 ps-4 pe-4${className !== undefined ? ` ${className}` : ''}`}
            handleChange={onChange}
            setFieldValue={setFieldValue}
          />
        );
      },
    );

  return <>{fields}</>;
};

export default FormFields;
