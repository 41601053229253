import { createSlice, isPending, isRejected } from '@reduxjs/toolkit';
import { RootState } from 'store';
import { CommonState, Row } from '~libs/reduxUtils';
import { typePrefix, getAll, getOne } from '~main/actions/taskClassifications';

const initialState: CommonState<Row> = {
  loading: 'idle',
  error: null,
  rows: [],
  row: { id: -1 },
};

// Main slice, connecting API actions to redux state.
export const taskClassificationsSlice = createSlice({
  name: 'task-classifications',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getAll.fulfilled, (state, action) => {
      state.loading = 'fulfilled';
      state.rows = action.payload.data;
    });
    builder.addCase(getOne.fulfilled, (state, action) => {
      state.loading = 'fulfilled';
      state.row = action.payload.data;
    });
    // Default matching for loading cases, pending when action is being called
    builder.addMatcher(isPending, (state, { type }) => {
      if (type.startsWith(`${typePrefix}/`)) state.loading = 'pending';
    });
    builder.addMatcher(isRejected, (state, action) => {
      if (action.type.startsWith(`${typePrefix}/`)) {
        state.loading = 'declined';
        state.error = action.error.message;
      }
    });
  },
});

// Selectors, performing common selection tasks for this slice.
export const selectTaskClassifications = ({ main }: RootState) => main.taskClassifications.rows;

export default taskClassificationsSlice.reducer;
