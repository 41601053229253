import { createSlice, isFulfilled, isPending, isRejected } from '@reduxjs/toolkit';
import { RootState } from 'store';
import { CommonState, Row } from '~libs/reduxUtils';

import { typePrefix, getAll, getOne, getDistance } from '~appointments/actions/locations';

interface LocationState extends CommonState<Row> {
  distance?: { distance: number; duration: number };
}

const initialState: LocationState = {
  loading: 'idle',
  error: null,
  rows: [],
  row: { id: -1 },
};

// Main slice, connecting API actions to redux state.
export const locationsSlice = createSlice({
  name: 'service-types',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getAll.fulfilled, (state, action) => {
      state.loading = 'fulfilled';
      state.rows = action.payload.data;
    });
    builder.addCase(getOne.fulfilled, (state, action) => {
      state.loading = 'fulfilled';
      state.row = action.payload.data;
    });
    builder.addCase(getDistance.fulfilled, (state, action) => {
      state.loading = 'fulfilled';
      state.distance = action.payload.data;
    });
    // Default matching for loading cases, pending when action is being called
    builder.addMatcher(isFulfilled, (state, { type }) => {
      if (type.startsWith(`${typePrefix}/`)) {
        state.loading = 'fulfilled';
        state.error = null;
      }
    });
    builder.addMatcher(isPending, (state, { type }) => {
      if (type.startsWith(`${typePrefix}/`)) state.loading = 'pending';
    });
    builder.addMatcher(isRejected, (state, action) => {
      if (action.type.startsWith(`${typePrefix}/`)) {
        state.loading = 'declined';
        state.error = action.error.message;
      }
    });
  },
});

// Selectors, performing common selection tasks for this slice.
const LocationsState = (state: RootState) => state.appointments.locations;
export const selectLocations = (state: RootState) => LocationsState(state).rows;
export const selectLocationsLoading = (state: RootState) => LocationsState(state).loading;
export const selectLocationDistance = (state: RootState) => LocationsState(state).distance;
export const selectErrorResponse = (state: RootState) => LocationsState(state).error;

export default locationsSlice.reducer;
