import { createThunks } from '~libs/reduxUtils';
import { get, post } from '~libs/genericAction';
import { apiWeeklyPlanner } from '~constants/api';

export const typePrefix = 'weekly-planner';

const config = [
  { actionType: 'publish', apiPath: `${apiWeeklyPlanner}/publish`, method: post },
  { actionType: 'getPublishEstimates', apiPath: `${apiWeeklyPlanner}/publish/estimates`, method: get },
];

export const { publish, getPublishEstimates } = createThunks(typePrefix, config);
