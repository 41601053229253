export const validateUserMobile: (values: any) => any = ({ mobile }) => {
  if (!mobile) {
    return true;
  }

  const ausMobileRegex = /^(?:(61))? ?(0?[4])\)? ?(\d\d(?:[- ](?=\d{3})|(?!\d\d[- ]?\d[- ]))\d\d[- ]?\d[- ]?\d{3})$/;

  if (!ausMobileRegex.test(mobile)) {
    return false;
  }

  return true;
};
