import React, { useState } from 'react';
import { useAppSelector } from 'hooks';
import { Link } from 'react-router-dom';
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';

import Icon, { faSignOut, faUser } from '~components/Icon';

import { selectAuthenticatedUser, selectLinkedAccounts } from '~main/selectors/login';

/**
 * UserMenu component displays a user dropdown menu for user details, account switching, and logout.
 *
 * @component
 * @returns The rendered UserMenu component.
 */
const UserMenu: React.FC = () => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const user = useAppSelector(selectAuthenticatedUser);
  const linkedAccounts = useAppSelector(selectLinkedAccounts);

  const userFullName = user ? `${user.first_name} ${user.surname}` : '';
  const REDIRECT_URL = process.env.REACT_APP_TPONE_URL;

  const toggleDropdown = () => setDropdownOpen(!dropdownOpen);
  const canAccountSwitch = (linkedAccounts?.length ?? 0) > 1;
  return (
    <div className="d-flex flex-row align-items-center menu-user ">
      <div className="d-flex me-3 ">
        <Dropdown isOpen={dropdownOpen} toggle={toggleDropdown}>
          <DropdownToggle nav caret className="menu-link">
            <>
              <Icon icon={faUser} />
              <span className="ms-2 ">{userFullName}</span>
            </>
          </DropdownToggle>
          <DropdownMenu>
            <DropdownItem text className="small  fw-bold">
              Name
            </DropdownItem>
            <DropdownItem text className="small ps-4">
              {userFullName}
            </DropdownItem>
            <DropdownItem text className="small  fw-bold">
              Company
            </DropdownItem>
            <DropdownItem text className="small ps-4">
              {user?.company.name ?? ''}
            </DropdownItem>
            <DropdownItem divider />
            {canAccountSwitch && <DropdownItem header>Switch Account</DropdownItem>}
            {linkedAccounts?.map(({ value, label }, index) => (
              <DropdownItem
                key={index}
                onClick={() => window.location.replace(`${REDIRECT_URL}/user-linked-account-swap.asp?swapID=${value}`)}
              >
                <span className="">{label}</span>
              </DropdownItem>
            ))}
            {canAccountSwitch && <DropdownItem divider />}
            <Link className="menu-logout" to="/logout">
              <DropdownItem>
                <div className="d-flex justify-content-between w-100">
                  <span>Log out </span>
                  <Icon icon={faSignOut} />
                </div>
              </DropdownItem>
            </Link>
          </DropdownMenu>
        </Dropdown>
      </div>
    </div>
  );
};

export default UserMenu;
