import React, { useEffect, useState } from 'react';
import { Table as BTable } from 'reactstrap';
import {
  ColumnDef,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  PaginationState,
  RowData,
  SortingState,
  useReactTable,
} from '@tanstack/react-table';
import classNames from 'classnames';
import Icon, { faSortDown, faSortUp, IconProp } from 'tsx/components/Icon';

import Spinner from './Spinner';

declare module '@tanstack/react-table' {
  // Known linting issue with tanstack and unused references when extending ColumnMeta
  // eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars
  interface ColumnMeta<TData extends RowData, TValue> {
    link: string | ((values: any) => string);
    hoverIcon: IconProp;
  }
}

export interface TableViewRow {
  id: number;
  [key: string]: string | number | object | boolean;
}
export type TableViewRows = Array<TableViewRow>;

interface TableProps {
  id: string;
  columns: ColumnDef<any>[];
  data: TableViewRows;
  isLoading?: boolean;
  allowPagination?: boolean;
  allowSort?: boolean;
  manualSort?: boolean;
  initialSortState?: SortingState;
  onSort?: (params: SortingState) => void;
  onLoadMore?: () => void;
  emptyIcon?: IconProp;
  emptyMessage?: string;
  showHeader?: boolean;
  showCount?: boolean;
  totalCount?: number;
}

const TableView: React.FC<TableProps> = ({
  id,
  data = [],
  columns,
  isLoading = false,
  allowPagination = false,
  allowSort = false,
  manualSort = false,
  initialSortState = [],
  onSort,
  onLoadMore,
  emptyIcon,
  emptyMessage = '',
  showHeader = true,
  showCount = false,
  totalCount,
}) => {
  const [sorting, setSorting] = useState<SortingState>(initialSortState);
  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 50,
  });

  useEffect(() => {
    if (manualSort && sorting !== initialSortState) {
      onSort && onSort(sorting);
    }
  }, [sorting]);

  const table = useReactTable({
    data,
    columns,
    state: { pagination, sorting, columnVisibility: { id: false } },
    onPaginationChange: setPagination,
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    enableSorting: allowSort,

    // noisy if enabled
    // debugTable: true,
    // debugHeaders: true,
    // debugColumns: true,
  });

  const { getRowCount, getAllColumns, getHeaderGroups, getRowModel } = table;

  const rowCount = getRowCount();
  const colCount = getAllColumns().length;

  return (
    <>
      {showCount && !isLoading && (
        <div className="d-flex justify-content-end me-2 fw-bold">
          Showing {rowCount} {totalCount && totalCount > 0 ? `of ${totalCount} total` : ''} rows
        </div>
      )}
      <BTable data-id={id} size="sm" striped={rowCount > 0 && !isLoading} responsive>
        {showHeader && (
          <thead>
            {getHeaderGroups().map(({ id, headers }) => (
              <tr key={id}>
                {headers.map(({ id, column, colSpan, getContext }) => {
                  const { columnDef, getToggleSortingHandler, getNextSortingOrder, getCanSort, getIsSorted } = column;

                  const className = classNames('d-flex justify-content-between', {
                    selectable: getCanSort(),
                  });

                  return (
                    <th key={id} colSpan={colSpan} className="border-info">
                      <div
                        className={className}
                        onClick={getToggleSortingHandler()}
                        title={
                          getCanSort()
                            ? getNextSortingOrder() === 'asc'
                              ? 'Sort ascending'
                              : getNextSortingOrder() === 'desc'
                                ? 'Sort descending'
                                : 'Clear sort'
                            : undefined
                        }
                      >
                        {flexRender(columnDef.header, getContext())}{' '}
                        {{
                          asc: <Icon icon={faSortUp} className="ms-2 me-2" />,
                          desc: <Icon icon={faSortDown} className="ms-2 me-2" />,
                        }[getIsSorted() as string] ?? null}
                      </div>
                    </th>
                  );
                })}
              </tr>
            ))}
          </thead>
        )}
        <tbody>
          {isLoading && (
            <tr>
              <td colSpan={colCount}>
                <Spinner loading={isLoading} className="mt-5" />
              </td>
            </tr>
          )}
          {rowCount === 0 && !isLoading && <Empty length={colCount} icon={emptyIcon} message={emptyMessage} />}
          {getRowModel().rows.map(({ id, getVisibleCells, original }) => {
            const { isCustom = false, getCustomRow } = original;
            if (isCustom) {
              return getCustomRow({ id, colCount, rowCount });
            } else {
              return (
                <tr key={id} className="align-middle on-hover">
                  {getVisibleCells().map(({ id, column, getContext }) => {
                    let link;
                    let hoverIcon;
                    if (column.columnDef.meta) {
                      ({ link, hoverIcon } = column.columnDef.meta);
                      link = typeof link === 'function' ? link(original) : link;
                    }

                    return (
                      <td key={id} className="pt-2 pb-2">
                        {link && (
                          <a href={link} className="link">
                            {flexRender(column.columnDef.cell, getContext())}
                          </a>
                        )}
                        {!link && flexRender(column.columnDef.cell, getContext())}
                        {hoverIcon && <Icon icon={hoverIcon} className="hover ms-2" size="1x" />}
                      </td>
                    );
                  })}
                </tr>
              );
            }
          })}
        </tbody>
        {rowCount > 0 && allowPagination && (
          <tfoot>
            <tr>
              <td className="p-3 text-center selectable fw-bold" colSpan={colCount} onClick={onLoadMore}>
                LOAD MORE
              </td>
            </tr>
          </tfoot>
        )}
      </BTable>
    </>
  );
};

const Empty: React.FC<any> = ({ length, icon, message }) => {
  return (
    <>
      <tr>
        <td colSpan={length}>
          <div className="p-5 text-center">
            {icon !== undefined && (
              <div>
                <Icon size="2x" icon={icon} />
              </div>
            )}
            <div className="mt-3">{message}</div>
          </div>
        </td>
      </tr>
    </>
  );
};

export default TableView;
