import { ColumnDef } from '@tanstack/react-table';
import dayjs from 'dayjs';
import { weeklyPlannerDayDisplayFormat } from 'tsx/libs/dayjs';

// Columns for Rescheduled Appointments
export const rescheduledColumns: ColumnDef<any>[] = [
  {
    header: 'Client',
    accessorKey: 'client.full_name', // Direct access to client full name
  },
  {
    header: 'New Date',
    accessorKey: 'date', // Direct access to the rescheduled date
    cell: (info) => dayjs(info.getValue() as string).format(weeklyPlannerDayDisplayFormat), // Formatting the date
  },
  {
    header: 'User',
    accessorKey: 'user.full_name', // Direct access to user full name
  },
];

// Columns for Cancelled Appointments
export const cancelledColumns: ColumnDef<any>[] = [
  {
    header: 'Client',
    accessorKey: 'client.full_name',
  },
  {
    header: 'Cancelled Date',
    accessorKey: 'date', // Direct access to the cancelled date
    cell: (info) => dayjs(info.getValue() as string).format(weeklyPlannerDayDisplayFormat), // Formatting the date
  },
  {
    header: 'User',
    accessorKey: 'user.full_name',
  },
];
