import React, { useState } from 'react';
import { useAppDispatch, useAppSelector } from 'hooks';
import { Col, Modal, ModalBody, ModalFooter, ModalHeader, Row, Spinner } from 'reactstrap';

import { Params } from '~libs/reduxUtils';

import { appointmentEditFields as appointmentFields } from '~appointments/forms/appointment/edit';

import Button from '~components/Button';
import ErrorResponse from '~components/ErrorResponse';
import Icon, { faCaretRight } from '~components/Icon';
import ModalCard from '~components/ModalCard';

import { bulkUpdate } from '~appointments/actions/appointments';

import { selectErrorResponse } from '~appointments/reducers/appointments';
import { clear } from '~weekly-planner/reducers/unsaved';

import { selectAllChanges } from '~weekly-planner/selectors/unsaved';

type Props = {
  isOpen?: boolean;
  onClose: () => void;
};

const Changes: React.FC<Props> = ({ isOpen = false, onClose }: Props) => {
  const dispatch = useAppDispatch();
  const changes = useAppSelector(selectAllChanges);
  const errorMessage = useAppSelector(selectErrorResponse);
  const [isSaving, setIsSaving] = useState<boolean>(false);

  const hasError = errorMessage ? errorMessage.length > 0 : false;

  const onSave = async (changes: any) => {
    setIsSaving(true);
    const data: Params[] = Object.entries(changes).map(([, change]: [string, any]) => change);
    const { payload } = await dispatch(bulkUpdate(data));

    setIsSaving(false);
    if (payload?.success) {
      dispatch(clear());
      onClose();
    }
  };

  const renderChanges = (changes: any) =>
    Object.entries(changes).map(([id, change], index) => (
      <div key={index} className="mb-2">
        <Row className="fw-bold mb-1">Appt #{id}</Row>
        {renderChange(change)}
      </div>
    ));

  const renderChange = (change: any) =>
    Object.entries(change)
      .filter(([key]) => key !== 'id')
      .map(([key, value]: any, index) => {
        return (
          <Row key={index} sm={4}>
            <Col className="text-end ">{appointmentFields[key]?.caption ?? key}</Col>
            <Col sm={1} className="text-center">
              <Icon icon={faCaretRight} />
            </Col>
            <Col>{value}</Col>
          </Row>
        );
      });

  const modalProps = {
    className: 'border-0 rounded-0 mt-1 mb-1',
    headerClassName: 'rounded-0',
    bodyClassName: 'modal-body-scrollable',
  };

  return (
    <Modal isOpen={isOpen} size="lg">
      <ModalHeader className="bg-success text-white">Review Changes</ModalHeader>
      <ModalBody>
        <div className="mb-4 pb-2 border-2 border-bottom border-success">
          Please confirm the following changes to save...
        </div>
        <ErrorResponse message={errorMessage} />
        <ModalCard {...modalProps} header="Show changes">
          {renderChanges(changes)}
        </ModalCard>
      </ModalBody>
      <ModalFooter className="align-items-center flex-column">
        <div>
          <Button size="sm" disabled={isSaving || hasError} color="success" onClick={() => onSave(changes)}>
            {isSaving && <Spinner type="grow" size="sm" className="ms-4 me-4" />}
            {!isSaving && <span>Save</span>}
          </Button>
          <Button size="sm" className="ms-2 ps-4 pe-4" onClick={() => onClose()}>
            Close
          </Button>
        </div>
      </ModalFooter>
    </Modal>
  );
};

export default Changes;
