import React, { ReactNode } from 'react';
import { DndContext, closestCenter, DndContextProps, DragOverlay, DragStartEvent, DragEndEvent } from '@dnd-kit/core';

interface ComponentProps extends Partial<DndContextProps> {
  children: React.ReactNode;
  onDragEnd?: (event: DragEndEvent) => void;
}

const DndWrapper: React.FC<ComponentProps> = ({
  children,
  onDragEnd,
  collisionDetection = closestCenter,
  modifiers = [],
}) => {
  const [activeItem, setActiveItem] = React.useState<any>(null);

  const handleDragStart = (event: DragStartEvent) => {
    setActiveItem(event.active.data.current);
  };

  const handleDragEnd = (event: DragEndEvent) => {
    setActiveItem(null);

    const { active, over } = event;
    if (!active || !over) return;

    // Retrieve the custom onItemDragEnd function from the active item’s data
    const { onItemDragEnd } = active.data.current || {};
    if (typeof onItemDragEnd === 'function') {
      onItemDragEnd(event);
    }

    // Call the global onDragEnd if provided
    if (onDragEnd) {
      onDragEnd(event);
    }
  };

  return (
    <DndContext
      onDragStart={handleDragStart}
      onDragEnd={handleDragEnd}
      collisionDetection={collisionDetection}
      modifiers={modifiers}
    >
      {children}
      <DragOverlay className="dnd-overlay" dropAnimation={null}>
        {renderDragOverlay(activeItem)}
      </DragOverlay>
    </DndContext>
  );
};

function renderDragOverlay(activeItem: any): ReactNode {
  if (activeItem) return activeItem.children;
  else return null;
}

export default DndWrapper;
