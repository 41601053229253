import React, { useState } from 'react';
import { NavLink as RouterNavLink } from 'react-router-dom';
import { useAppSelector } from 'hooks';
import classNames from 'classnames';
import {
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  Nav,
  Navbar,
  NavItem,
  NavLink,
  UncontrolledDropdown,
} from 'reactstrap';

import headerMenu from '~constants/headerMenu';
import CompanyLogo from '~components/CompanyLogo';
import UserMenu from '~main/UserMenu';

import {
  selectAuthenticatedUser,
  selectCompanyDefaults,
  selectCompanySettings,
  selectUserTags,
} from '~main/selectors/login';

/**
 * Header component renders the main navigation bar, including company logo, menu items, and user menu.
 *
 * fetches user authentication details, company settings, and user tags and renders
 * navigation menu based on user authorization and company settings.
 *
 * @component
 * @returns The rendered Header component.
 */
const Header: React.FC = () => {
  const [openMenuIndex, setOpenMenuIndex] = useState<number | null>(null);
  const user = useAppSelector(selectAuthenticatedUser);
  const companyDefaults = useAppSelector(selectCompanyDefaults);
  const tags = useAppSelector(selectUserTags);
  const settings = useAppSelector(selectCompanySettings);

  return (
    <Navbar className="menu">
      <div className="d-flex flex-nowrap justify-content-between align-items-center w-100 ">
        <div className="d-flex align-items-center">
          <CompanyLogo logo={user?.company.logo} altText={user?.company.name ?? ''} />
          <Nav>
            {headerMenu(user, settings, companyDefaults).map(
              ({ caption, link = '', children = [], authTag = null }, index) => {
                const isAuthorized = !authTag || tags.includes(authTag);
                if (!isAuthorized) return null;

                const headerClass = classNames('menu-link', {
                  [`active`]: openMenuIndex === index,
                });

                // avoid rendering empty headers if user has no authTags
                const authorizedChildren = children.filter(({ authTag }) => !authTag || tags.includes(authTag));
                if (!authorizedChildren.length && !link) return null;

                if (authorizedChildren.length > 0) {
                  return (
                    <UncontrolledDropdown
                      nav
                      inNavbar
                      onMouseEnter={() => setOpenMenuIndex(index)}
                      onMouseLeave={() => setOpenMenuIndex(null)}
                      toggle={() => setOpenMenuIndex(openMenuIndex !== index ? index : null)}
                      isOpen={openMenuIndex === index}
                      key={index}
                    >
                      <DropdownToggle nav caret className={headerClass}>
                        {caption}
                      </DropdownToggle>
                      <DropdownMenu>
                        {authorizedChildren.map(({ caption, link }, childIndex) => (
                          <DropdownItem key={childIndex} tag={RouterNavLink} to={link} className="menu-dropdown-item">
                            {caption}
                          </DropdownItem>
                        ))}
                      </DropdownMenu>
                    </UncontrolledDropdown>
                  );
                }

                return (
                  <NavItem key={index}>
                    <NavLink href={link} className="menu-link">
                      {caption}
                    </NavLink>
                  </NavItem>
                );
              },
            )}
          </Nav>
        </div>
        <div className="d-flex align-items-center">
          <UserMenu />
        </div>
      </div>
    </Navbar>
  );
};

export default Header;
