import { RootState } from 'store';

const ConflictsState = (state: RootState) => state.weeklyPlanner.conflicts;

export const selectAll = (state: RootState) => ConflictsState(state).rows;
export const selectCount = (state: RootState) => ConflictsState(state).count;
export const selectLoading = (state: RootState) => ConflictsState(state).loading;
export const selectHasLoaded = (state: RootState) => ConflictsState(state).hasLoaded;
export const selectParams = (state: RootState) => ConflictsState(state).params;
export const selectOptions = (state: RootState) => ConflictsState(state).options;
