import React, { CSSProperties } from 'react';

import { Client } from '~weekly-planner/lib/common';

interface ComponentProps {
  data: Client;
  style?: CSSProperties;
}

const ClientCell: React.FC<ComponentProps> = ({ data: client, style }) => {
  const { full_name } = client;
  return (
    <div className="worker-cell" style={style}>
      <div className="d-flex flex-column justify-content-between w-100">
        <div>
          <div className="px-2 mb-2">
            <div className="d-flex align-items-center">
              <div>{full_name}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ClientCell;
