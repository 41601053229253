import { apiAllowances } from '~constants/api';
import { get } from '~libs/genericAction';
import { createThunks } from '~libs/reduxUtils';

import { typePrefix as prefix } from '~care-workers/actions';

export const typePrefix = `${prefix}-allowances`;

const config = [{ actionType: 'getAll', apiPath: apiAllowances, method: get, actionParams: { limit: 0 } }];

export const { getAll } = createThunks(typePrefix, config);
