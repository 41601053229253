import React, { useState } from 'react';
import { useAppSelector } from 'hooks';
import classNames from 'classnames';
import dayjs, { Dayjs } from 'dayjs';
import { DesktopTimePicker } from '@mui/x-date-pickers';

import { InputProps } from '~components/FormFields';

import { defaultMinuteInterval, timeValueFormat } from '~libs/dayjs';

import { selectCompanySettings } from '~main/selectors/login';

const Time: React.FC<InputProps> = ({ id, value, placeholder, disabled = false, className, onChange }) => {
  const [error, setError] = useState(false);

  // Check company settings for all allowed intervals in minutes (COMPANY/APPLICATION WIDE)
  let minutes = defaultMinuteInterval;
  const settings = useAppSelector(selectCompanySettings);
  if (settings?.SYSTEM_2022_IntervalHops) minutes = parseInt(settings.SYSTEM_2022_IntervalHops as string);

  let timeValue = null;
  if (value !== undefined && value !== '') timeValue = dayjs(value, timeValueFormat);

  const setChange = (value: Dayjs | null) => {
    onChange && onChange(id, value?.format(timeValueFormat));
  };

  const timeClass = classNames('form-control', {
    [`${className}`]: className !== undefined,
  });

  const validateTime = (value: Dayjs | null) => {
    const newMinsValue = value?.minute();
    if (newMinsValue === undefined || isNaN(newMinsValue) || newMinsValue % minutes !== 0) {
      setError(true);
    } else setError(false);
  };

  return (
    <DesktopTimePicker
      value={timeValue}
      disabled={disabled}
      className={timeClass}
      label={placeholder}
      timeSteps={{ minutes: minutes }}
      slotProps={{ textField: { size: 'small', error: error } }}
      onChange={(value) => {
        validateTime(value);
        setChange(value);
      }}
      onError={(error, value) => {
        if (error) setError(true);
        else validateTime(value);
      }}
    />
  );
};

export default Time;
