import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'hooks';
import { Button, Col, Container, Progress, Row } from 'reactstrap';
import dayjs, { Dayjs } from 'dayjs';

import { dateDisplayFormat, dateInputFieldFormat, isInSameOrAfterWeek } from '~libs/dayjs';
import { buildAssignChange } from '~weekly-planner/lib/simulate';

import WeekPicker from '~components/WeekPicker';
import ChangesDialog from '~weekly-planner/components/Modal/Changes';
import PublishDialog from '~weekly-planner/components/Modal/Publish';

import { getSettings } from '~main/actions/login';
import { getImportAppointments } from '~weekly-planner/actions/appointments/unallocated';
import { simulate } from '~weekly-planner/actions/unsaved';

import { clearError } from '~appointments/reducers/appointments';

import { selectCompanySettings } from '~main/selectors/login';
import { selectAllSimulatedChanges } from '~weekly-planner/selectors/simulate';
import { selectAllChangesCount } from '~weekly-planner/selectors/unsaved';

interface ComponentProps {
  weekStart: string;
  onRefresh?: () => void;
}

const SaveLoad: React.FC<ComponentProps> = ({ weekStart, onRefresh }) => {
  const dispatch = useAppDispatch();

  const changesCount = useAppSelector(selectAllChangesCount);
  const settings = useAppSelector(selectCompanySettings);
  const data = useAppSelector(selectAllSimulatedChanges);

  const clearAppointmentsError = () => dispatch(clearError());

  const [isChangesOpen, setIsChangesOpen] = useState(false);
  const [IsPublishOpen, setIsPublishOpen] = useState(false);
  const [importDate, setImportDate] = useState<string>(weekStart);
  const [importError, setImportError] = useState<boolean>(false);
  const [importMessage, setImportMessage] = useState<string>('');
  const [isImporting, setIsImporting] = useState(false);

  useEffect(() => {
    dispatch(getSettings());
  }, []);

  useEffect(() => {
    onDateChange(dayjs(importDate));
  }, [weekStart]);

  const onDateChange = async (value: Dayjs) => {
    setImportDate(value.format(dateInputFieldFormat));
    setImportError(isInSameOrAfterWeek(value, weekStart));
  };

  const onImport = async () => {
    setIsImporting(true);
    setImportMessage('Importing');

    const { payload } = await dispatch(getImportAppointments({ week_start: weekStart, import_week_start: importDate }));
    const { success, data: targets } = payload;

    if (success && targets) {
      setImportMessage('Simulating');

      const change = buildAssignChange(data, targets);
      await dispatch(simulate({ ...change, week_start: weekStart }));
    }

    setIsImporting(false);
  };

  const currrentPublishDate = settings.published_date as string;

  return (
    <Container className="card-container">
      <div className="save-load-container">
        <Row className="underline content">
          <Row>
            <Col sm={12} className="row-header">
              Unsaved Changes:
            </Col>
          </Row>
          <Row className="row-center">
            <span>{changesCount ? `${changesCount} pending` : 'No pending changes'}</span>
          </Row>
          <ChangesDialog
            isOpen={isChangesOpen}
            onClose={() => {
              setIsChangesOpen(false);
              onRefresh && onRefresh();
              clearAppointmentsError();
            }}
          />
          <Row className="row-center">
            <Button size="sm" disabled={changesCount === 0} color="success" onClick={() => setIsChangesOpen(true)}>
              Save
            </Button>
            <small>* Saving will finalise all pending changes made from the planner</small>
          </Row>
          <PublishDialog
            isOpen={IsPublishOpen}
            onClose={() => {
              setIsPublishOpen(false);
              onRefresh && onRefresh();
              clearAppointmentsError();
            }}
            originalDate={currrentPublishDate}
          />
        </Row>

        <Row className="underline content">
          <Row>
            <Col sm={12} className="row-header">
              Current Published Date:
            </Col>
          </Row>
          <Row className="row-center">
            <span>{dayjs(currrentPublishDate).format(dateDisplayFormat)}</span>
          </Row>
          <Row className="row-center">
            <Button size="sm" className="publish" onClick={() => setIsPublishOpen(true)}>
              Change
            </Button>
            <small>* Changing this will release all saved appointments up to new date</small>
          </Row>
        </Row>

        <Row className="content">
          <Row>
            <Col sm={12} className="row-header">
              Import Allocations From:
            </Col>
          </Row>
          <Row className="row-center">
            <Col sm={12} className="d-flex justify-content-center">
              <WeekPicker onChange={(value) => onDateChange(value)} showQuickIcons={false} showSelected={false} />
            </Col>
            {importError && (
              <Col sm={12} className="text-danger text-center">
                Selected week can only be in the past!
              </Col>
            )}
          </Row>
          <Row className="row-center">
            <Button size="sm" className="import" onClick={onImport} disabled={importError || isImporting}>
              Import
            </Button>
            <small>* Allocations will only apply to unallocated appts</small>
          </Row>
          {isImporting && (
            <Row>
              <Progress className="text-center" color="success" style={{ height: '25px' }} value={100} animated>
                {importMessage}
              </Progress>
            </Row>
          )}
        </Row>
      </div>
    </Container>
  );
};

export default SaveLoad;
