import React, { useState } from 'react';
import { useAppSelector } from 'hooks';

import TableView from '~components/TableView';
import ModalCard from '~components/ModalCard';

import { commonColumns, notLoggedInColumns } from '~weekly-planner/forms/info-modal/userExceptions/list';
import { selectRunningLate } from '~weekly-planner/selectors/users';

const UserExceptions: React.FC = () => {
  const { appointmentsBeforeNow, appointmentsNow, notLoggedIn } = useAppSelector(selectRunningLate);

  if (
    (!appointmentsBeforeNow || appointmentsBeforeNow.length === 0) &&
    (!appointmentsNow || appointmentsNow.length === 0) &&
    !notLoggedIn
  ) {
    return <p>No user Exceptions</p>;
  }

  const runningLateAppointments = appointmentsBeforeNow.filter((appointment) => appointment.status?.name === 'Pending');
  const otherAppointments = appointmentsBeforeNow.filter((appointment) => appointment.status?.name !== 'Pending');

  const [isRunningLateOpen, setIsRunningLateOpen] = useState(true);
  const [isNotLoggedInOpen, setIsNotLoggedInOpen] = useState(true);
  const [isOtherAppointmentsOpen, setIsOtherAppointmentsOpen] = useState(true);
  const [isAppointmentsNowOpen, setIsAppointmentsNowOpen] = useState(true);

  return (
    <div className="container p-4">
      {runningLateAppointments.length > 0 && (
        <ModalCard
          header="Running Late"
          isOpen={isRunningLateOpen}
          setIsOpen={setIsRunningLateOpen}
          headerClassName="bg-warning"
          className="mb-4"
          allowOpen={true}
          allowHeaderOpen={true}
        >
          <TableView
            id="running-late-appointments"
            data={runningLateAppointments}
            columns={commonColumns}
            showHeader={true}
            emptyMessage="No running late appointments."
          />
        </ModalCard>
      )}

      {notLoggedIn.length > 0 && (
        <ModalCard
          header="Not Logged In"
          isOpen={isNotLoggedInOpen}
          setIsOpen={setIsNotLoggedInOpen}
          headerClassName="bg-warning"
          className="mb-4"
          allowOpen={true}
          allowHeaderOpen={true}
        >
          <TableView
            id="not-logged-in"
            data={notLoggedIn}
            columns={notLoggedInColumns}
            showHeader={true}
            emptyMessage="No clients not logged in."
          />
        </ModalCard>
      )}

      {otherAppointments.length > 0 && (
        <ModalCard
          header="Other Appointments"
          isOpen={isOtherAppointmentsOpen}
          setIsOpen={setIsOtherAppointmentsOpen}
          headerClassName="bg-warning"
          className="mb-4"
          allowOpen={true}
          allowHeaderOpen={true}
        >
          <TableView
            id="other-appointments"
            data={otherAppointments}
            columns={commonColumns}
            showHeader={true}
            emptyMessage="No other appointments."
          />
        </ModalCard>
      )}

      {appointmentsNow.length > 0 && (
        <ModalCard
          header="Appointments Now"
          isOpen={isAppointmentsNowOpen}
          setIsOpen={setIsAppointmentsNowOpen}
          className="mb-4"
          allowOpen={true}
          allowHeaderOpen={true}
        >
          <TableView
            id="appointments-now"
            data={appointmentsNow}
            columns={commonColumns}
            showHeader={true}
            emptyMessage="No appointments now."
          />
        </ModalCard>
      )}
    </div>
  );
};

export default UserExceptions;
