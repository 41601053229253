import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'hooks';
import { Link } from 'react-router-dom';
import { Collapse } from 'reactstrap';
import { SortingState } from '@tanstack/react-table';
import dayjs from 'dayjs';

import { AuthTags } from '~constants/authTags';
import { AppointmentStatuses } from '~constants/maps';

import { dateInputFieldFormat } from '~libs/dayjs';
import { useAllSearchParams, useSearch } from '~libs/searchParams';

import exportFields from '~appointments/forms/appointments/export';
import { allColumns, commonColumns } from '~appointments/forms/appointments/list';
import { allFields, commonFields } from '~appointments/forms/appointments/search';

import Button from '~components/Button';
import ContainerBanner from '~components/ContainerBanner';
import ContainerHeader from '~components/ContainerHeader';
import ErrorResponse from '~components/ErrorResponse';
import ExportDialog from '~components/ExportDialog';
import { SearchBar } from '~components/FormFields/Search';
import Icon, { faBusinessTime, faFileExcel, faInfoCircle, faMagnifyingGlass, faPlus } from '~components/Icon';
import TableView from '~components/TableView';

import { exportAll, getAll, getEstimate } from '~appointments/actions/appointments';
import { getAll as getServiceTypes } from '~appointments/actions/serviceTypes';
import { getAll as getStatuses } from '~appointments/actions/statuses';
import { getOne as getUser } from '~care-workers/actions';
import { getOne as getClient } from '~main/actions/clients';
import { getAll as getClientPackages } from '~main/actions/clients/clientPackages';
import { getAll as getClientReferrers } from '~main/actions/clients/clientReferrers';
import { setTitle } from '~main/actions/login';

import {
  clearAppointments,
  clearMessage,
  selectAppointmentLoading,
  selectAppointmentRowEstimate,
  selectAppointments,
  selectAppointmentsTotal,
  selectErrorResponse,
} from '~appointments/reducers/appointments';

import { selectUserTags } from '~main/selectors/login';

const Appointments: React.FC = () => {
  const dispatch = useAppDispatch();

  const tags = useAppSelector(selectUserTags);
  const REDIRECT_URL = process.env.REACT_APP_TPONE_URL;
  const authTagUpdate = AuthTags.APPOINTMENTS.UPDATE;
  const authTagViewAll = AuthTags.APPOINTMENTS.VIEWALL;
  const authTagExport = AuthTags.APPOINTMENTS.DOWNLOAD;

  const initialSortState: SortingState = [
    {
      id: 'last_updated',
      desc: true,
    },
  ];

  const initialSearchState: any = {
    date: { gte: dayjs().format(dateInputFieldFormat) },
    status_id: Object.entries(AppointmentStatuses)
      .map(([, value]) => (value < 8 ? value : false))
      .filter(Boolean),
  };

  // Check for simplified/all view, match "Care Worker" view, to be removed upon close of TP1. (╯°□°）╯︵ ┻━┻
  const hasViewAll = tags.includes(AuthTags.APPOINTMENTS.VIEWALL);

  const [searchBarOpen, setSearchBarOpen] = useState<boolean>(false);
  const [searchParams, setSearchParams] = useSearch('search', initialSearchState);
  const searchParamValues = useAllSearchParams();

  const [exportDialogOpen, setExportDialogOpen] = useState<boolean>(false);
  const [infoMessages, setInfoMessages] = useState<string[]>(['Showing future appointments only']);

  const [params, setParams] = useState({
    repeat: false,
    sort: initialSortState.map(({ id, desc }) => `${id}.${desc ? 'desc' : 'asc'}`),
    limit: 100,
  });

  useEffect(() => {
    setTitle('Appointments');
    // Get appointments on mount
    load();

    // Get lookups on mount
    dispatch(
      getServiceTypes({
        deleted: {
          or: [true, false],
        },
        sort: ['deleted', 'name'],
      }),
    );
    dispatch(getStatuses());
    dispatch(getClientPackages({ sort: ['name'], deleted: false }));
    dispatch(getClientReferrers({ sort: ['name'], deleted: false }));

    // Look for special search param values and override if exists
    const { user_id, client_id } = searchParamValues;
    const overrideSearches = [];
    if (user_id) {
      overrideSearches.push({ key: 'user.full_name', response_key: 'full_name', id: user_id, action: getUser });
    }
    if (client_id) {
      overrideSearches.push({ key: 'client.full_name', response_key: 'full_name', id: client_id, action: getClient });
    }
    if (overrideSearches.length > 0) overrideSearchValuebyIDs(overrideSearches);

    // Clean up component on unmount, clear reducer, etc.
    return () => {
      dispatch(clearAppointments());
      dispatch(clearMessage());
    };
  }, []);

  useEffect(() => {
    if (searchParams !== initialSearchState) {
      setInfoMessages([]);
      load();
    }
  }, [searchParams]);

  const overrideSearchValuebyIDs = async (ids: any) => {
    const searchValues: any = {};
    for (const criteria of ids) {
      const { key, response_key, id, action } = criteria;
      const { payload } = await dispatch(action({ id }));
      if (payload?.data) searchValues[key] = payload?.data[response_key];
    }
    if (Object.keys(searchValues).length > 0) search({ ...searchParams, ...searchValues });
  };

  const load = (loadParams: { [key: string]: any } = {}) => {
    const newParams = {
      ...params,
      ...loadParams,
    };

    // Keep search and load params separate as search can be cleared. Don't want to retain values when nothing is passed back.
    dispatch(getAll({ ...newParams, ...searchParams }));
    setParams(newParams);
  };

  const sort = (params: SortingState = []) => {
    load({ sort: params.map(({ id, desc }) => `${id}.${desc ? 'desc' : 'asc'}`) });
  };

  const download = (params: { [key: string]: any }, onProgress: (value: any) => void) =>
    exportAll({ params, onProgress });

  const search = (values: { [key: string]: any }) => setSearchParams(values);
  const estimate = async (values: { [key: string]: any }) => dispatch(getEstimate(values));

  const appointments = useAppSelector(selectAppointments);
  const appointmentsTotal = useAppSelector(selectAppointmentsTotal);
  const appointmentRowEstimate = useAppSelector(selectAppointmentRowEstimate);
  const errorResponse = useAppSelector(selectErrorResponse);
  const appointmentLoading = useAppSelector(selectAppointmentLoading);
  const isLoading = appointmentLoading === 'pending';

  return (
    <div className="p-2">
      <ContainerHeader icon={faBusinessTime} iconSize="1x" className="info">
        Appointments
      </ContainerHeader>
      <ErrorResponse message={errorResponse} />
      <div className="nav d-flex justify-content-between bg-light p-2 mt-2 mb-2">
        <div>
          <Button authTag={authTagUpdate} size="sm" color="success" className="ms-2" href={'/appointments/new'}>
            <Icon className="me-2" icon={faPlus} />
            Add new (once off)
          </Button>
          <Button authTag={authTagUpdate} size="sm" color="warning" className="ms-2" href={'/appointments/repeats/new'}>
            <Icon className="me-2" icon={faPlus} />
            Add Repeat Appointment
          </Button>
          <Button authTag={authTagViewAll} size="sm" color="warning" className="ms-2" href={'/appointments/repeats'}>
            Repeat Appointments
          </Button>
          <Link to={`${REDIRECT_URL}/mail-merge-appointments.asp`}>
            <Button size="sm" className="ms-2">
              Expand View
            </Button>
          </Link>
        </div>
        <div>
          <Button size="sm" className="me-2" onClick={() => setSearchBarOpen(!searchBarOpen)}>
            <Icon className="me-2" icon={faMagnifyingGlass} />
            {searchBarOpen ? 'Hide' : 'Search'}
          </Button>
          <Button
            authTag={authTagExport}
            size="sm"
            className="me-2"
            onClick={() => setExportDialogOpen(!exportDialogOpen)}
          >
            <Icon className="me-2" icon={faFileExcel} />
            Export
          </Button>
        </div>
      </div>
      <ExportDialog
        isOpen={exportDialogOpen}
        fields={exportFields}
        openingValues={searchParams}
        estimate={appointmentRowEstimate}
        onClose={() => setExportDialogOpen(false)}
        onEstimate={estimate}
        onDownload={(params, onProgress) => download(params, onProgress)}
      />
      {infoMessages.length > 0 && (
        <ContainerBanner
          className="m-2"
          color="info"
          icon={faInfoCircle}
          iconSize="2x"
          messages={infoMessages}
          onClear={() => setInfoMessages([])}
        />
      )}
      <Collapse isOpen={searchBarOpen}>
        <SearchBar
          fields={hasViewAll ? allFields : commonFields}
          initialValues={searchParams}
          storageKey="appointments"
          onSubmit={search}
        />
      </Collapse>
      <TableView
        id="appointments"
        data={appointments as []}
        columns={hasViewAll ? allColumns : commonColumns}
        isLoading={isLoading}
        emptyIcon={faBusinessTime}
        emptyMessage={'No appointments found, please add one!'}
        allowSort={true}
        manualSort={true}
        onSort={sort}
        allowPagination={true}
        onLoadMore={() => load({ limit: params.limit + 50 })}
        showCount={true}
        totalCount={appointmentsTotal}
      />
    </div>
  );
};

export default Appointments;
