import dayjs, { Dayjs } from 'dayjs';
import { dateInputFieldFormat, getTime } from 'tsx/libs/dayjs';
import { SimulateParams } from '../actions/unsaved';
import { Appointment } from './common';

const findWorkerbyId = (oldData: any[], workerId: number) => oldData.find(({ id }) => id === workerId);

export const extractEvent = (parent: any, value: any, type = 'appointment', key = 'key'): any => {
  // If top level, process events object
  if ('events' in parent) return extractEvent(Object.values(parent.events), value, type, key);
  return parent
    .map((child: any) => {
      // If worker level, events is in the child object
      if ('events' in child) return extractEvent(Object.values(child.events), value, type, key);

      // If an array of events, process the "group" of events
      if (Array.isArray(child)) return extractEvent(child, value, type, key);

      // If the type matches what was passed, return the data
      if (child.type === type) return child.data;

      // If the data is an array and does not match the type, process the "group" of events
      if (Array.isArray(child.data)) return extractEvent(child.data, value, type, key);
      return false;
    })
    .filter(Boolean)
    .flat(1)
    .find((event: any) => event[key] === value);
};

export const getPredictedTime = (
  width: number,
  position: number,
  segments: number,
  time: string,
  interval: number,
  date?: string,
) => {
  if (!date) date = dayjs().format(dateInputFieldFormat);
  const offsetPixels = width / segments;
  const offsetMinutes = Math.ceil(position / offsetPixels) * interval;
  return getTime(time, offsetMinutes, date);
};

export const buildWeeklyChange = (
  data: any[],
  oldCell: string,
  newCell: string,
  startDate: Dayjs,
  appointment: any,
) => {
  const [oldWorkerId, oldDay] = oldCell.split('-').map(Number);
  const [newWorkerId, newDay] = newCell.split('-').map(Number);

  const previous = {
    data,
    workerId: oldWorkerId,
    day: oldDay,
  };

  const current = {
    workerId: newWorkerId,
    day: newDay,
  };

  return buildMoveChange(previous, current, appointment, startDate);
};

export const buildDailyChange = (data: any[], oldRow: string, newRow: string, appointment: Appointment) => {
  const oldWorkerId = Number(oldRow);
  const newWorkerId = Number(newRow);

  const previous = {
    data,
    workerId: oldWorkerId,
  };

  const current = {
    workerId: newWorkerId,
  };

  return buildMoveChange(previous, current, appointment);
};

export const buildAllocateAppointmentChange = (data: any[], newCell: string, appointment: Appointment) => {
  const newWorkerId = newCell.split('-').map(Number)[0];

  const parsedAppt = { ...appointment, user_id: newWorkerId };
  return buildAssignChange(data, [parsedAppt]);
};

export const buildAssignChange = (data: any[], appointments: Appointment[], unassign = false) => {
  const workerIds = [...new Set(appointments.map(({ user_id }) => user_id))];
  const workers = workerIds.map((id) => findWorkerbyId(data, Number(id))).filter(Boolean);

  return {
    type: 'assign',
    workers,
    // Purposeful unused var, using destructure to omit key from returning data
    // eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars
    data: appointments.map(({ assignable_users, user_id, user, ...appointment }) => ({
      ...appointment,
      user: unassign ? null : user,
      user_id: unassign ? null : user_id,
    })),
  };
};

const buildMoveChange = (
  previous: { data: any[]; workerId: number; day?: number },
  current: { workerId: number; day?: number },
  appointment: any,
  date?: Dayjs,
) => {
  const { data: oldData, workerId: oldId, day: oldDay } = previous;
  const { workerId: newId, day: newDay } = current;

  const data = { ...appointment, user: undefined, user_id: newId };
  if (date && oldDay !== undefined && newDay !== undefined) {
    const day = oldDay !== newDay ? newDay : oldDay;
    data.date = date.add(day, 'days').format(dateInputFieldFormat);
  }

  const origin = findWorkerbyId(oldData, oldId);
  const workers = {
    ...(newId !== oldId ? { origin } : {}),
    target: findWorkerbyId(oldData, newId),
  };

  return {
    type: 'move',
    workers,
    data,
  } as SimulateParams;
};
