import { SearchPropType } from '~components/FormFields/Search';

export const prepareSearchParam = (type: SearchPropType, field: string | string[], value: any) => {
  switch (type) {
    case 'text-like': {
      if (field instanceof Array) {
        return {
          or: buildParam(field, { like: `%${value}%` }),
        };
      }
      return buildParam(field, { like: `%${value}%` });
    }
    case 'multi-lookup': {
      if (!(field instanceof Array)) {
        return {
          [field]: value,
        };
      }
      return {
        or: buildParam(field, value),
      };
    }
    case 'time-range': {
      const result: { gte?: string; lte?: string } = {};
      if (value?.gte?.length > 0) result.gte = `${value.gte}`;
      if (value?.lte?.length > 0) result.lte = `${value.lte}`;
      return buildParam(field, result);
    }
    case 'date-range': {
      const result: { gte?: string; lte?: string } = {};
      if (value?.gte?.length > 0) result.gte = value.gte;
      if (value?.lte?.length > 0) result.lte = value.lte;
      return buildParam(field, result);
    }
    default: {
      return buildParam(field, value);
    }
  }
};

const buildParam = (field: string | string[], value: any) => {
  const param: { [key: string]: any } = {};
  if (field instanceof Array) {
    field.forEach((key) => {
      param[key] = value;
    });
  } else param[field] = value;
  return param;
};
