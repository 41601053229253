import React, { useEffect, useMemo, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'hooks';
import { useNavigate, useParams } from 'react-router-dom';

import AppointmentBanner from '~appointments/components/AppointmentBanner';
import AppointmentDeclined from '~appointments/components/AppointmentDeclined';
import AppointmentForm from '~appointments/components/AppointmentForm';
import ConfirmDialog from '~components/ConfirmDialog';
import ContainerLoading from '~components/ContainerLoading';
import { FieldProps } from '~components/FormFields';

import { get } from '~appointments/forms';

import { useAllSearchParams } from '~libs/searchParams';

import { AuthTags } from '~constants/authTags';

import { getAll as getActivities } from '~appointments/actions/activities';
import { create, getOne, getSignature, update } from '~appointments/actions/appointments';
import { getAll as getCareQualificationLevels } from '~appointments/actions/careQualificationLevels';
import { getAll as getLocations } from '~appointments/actions/locations';
import { getAll as getRepeatFrequencies } from '~appointments/actions/repeatFrequencies';
import { getAll as getServiceTypes } from '~appointments/actions/serviceTypes';
import { getAll as getClients } from '~main/actions/clients';
import { getAll as getEquipments } from '~main/actions/equipments';
import { getAll as getLeaveCodes } from '~main/actions/leaveCodes';
import { setTitle } from '~main/actions/login';
import { getAll as getMedications } from '~main/actions/medications';
import { getAll as getPaymentTypes } from '~main/actions/paymentTypes';
import { getAll as getTaskClassifications } from '~main/actions/taskClassifications';
import { getAll as getTaskTypes } from '~main/actions/taskTypes';

import {
  clearAppointment,
  clearChildAppointment,
  clearCopyAppointment,
  selectAppointmentLoading,
  selectChildAppointment,
  selectCopyAppointment,
  selectCurrentAppointment,
} from '~appointments/reducers/appointments';
import { selectAppointmentServiceTypes } from '~appointments/reducers/serviceTypes';
import { clearUserAppointments } from '~appointments/reducers/userAppointments';

import { selectAuthenticatedUser, selectCompanySettings, selectUserTags } from '~main/selectors/login';

interface ComponentProps {
  isNew?: boolean;
  isTransport?: boolean;
  forceRepeat?: boolean;
}

const Appointment: React.FC<ComponentProps> = ({ isNew = false, isTransport = false, forceRepeat = false }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const searchParamValues = useAllSearchParams();

  const REDIRECT_URL = process.env.REACT_APP_TPONE_URL;

  // Make sure the component is associated with company settings,
  // if data changes the component will need to re-render to check permissions
  useAppSelector(selectCompanySettings);
  const authenticatedUser = useAppSelector(selectAuthenticatedUser);

  // Validation fields and confirmation messages to display
  const [changedValues, setChangedValues]: [any, any] = useState({});
  const [confirmMessages, setConfirmMessages]: [string[], any] = useState([]);
  const [confirmOpen, setConfirmOpen]: [boolean, any] = useState(false);
  const [isRedirecting, setIsRedirecting]: [boolean, any] = useState(false);
  const [fields, setFields]: [FieldProps, any] = useState({});
  // Link record from selector to component
  const appointment = useAppSelector(selectCurrentAppointment);
  const copyValues = useAppSelector(selectCopyAppointment);
  const childValues = useAppSelector(selectChildAppointment);
  const appointmentLoading = useAppSelector(selectAppointmentLoading);
  const serviceTypes = useAppSelector(selectAppointmentServiceTypes);
  const tags = useAppSelector(selectUserTags);

  useEffect(() => {
    // Get appointment by ID on mount
    if (!isNew) load();
    else {
      setTitle('New Appointment');
      // Lookups for new appointments only
      // Get clients on mount
      dispatch(getClients());

      // Look for clone Appointment ID and copy row
      const { copy_id, parent_id } = searchParamValues;
      if (copy_id) {
        dispatch(getOne({ id: copy_id, isTemplate: true }));
      } else if (parent_id) {
        dispatch(getOne({ id: parent_id, isParent: true }));
      }
    }

    dispatch(getRepeatFrequencies());

    // Get qualification levels on mount
    dispatch(getCareQualificationLevels({ appt_match: true }));

    dispatch(getLeaveCodes());
    dispatch(getPaymentTypes());

    // Get task classifications and types
    dispatch(getTaskClassifications());
    dispatch(getTaskTypes({ disabled: false, sort: ['classification', 'name'] }));

    // Get medications
    dispatch(getMedications({ disabled: false, sort: ['name'] }));

    // Get equipments
    dispatch(getEquipments({ disabled: false, sort: ['name'] }));

    // Get locations on mount
    dispatch(getLocations({ sort: ['name'], deleted: false }));

    dispatch(
      getServiceTypes({
        deleted: { or: [true, false] },
        sort: ['deleted', 'name'],
      }),
    );

    // Clean up component on unmount, clear reducer, etc.
    return () => {
      dispatch(clearAppointment());
      dispatch(clearUserAppointments());

      if (isNew) {
        dispatch(clearCopyAppointment());
        dispatch(clearChildAppointment());
      }
    };
  }, []);

  useEffect(() => {
    if (appointment && !isNew) {
      // Redirect GROUP sessions to TPONE, TAV is not handling this type yet.
      if (appointment?.is_group_session === true) {
        setIsRedirecting(true);
        window.location.replace(`${REDIRECT_URL}/appointment-edit.asp?eid=${id}`);
      }

      setTitle(`Appt #${appointment?.reference_number ?? ''}, ${appointment?.client?.full_name ?? ''}`);
      dispatch(getClients({ id: appointment.client_id }));
    }
  }, [appointment]);

  const load = () => {
    dispatch(getOne({ id }));
    dispatch(getSignature({ id }));
    dispatch(getActivities({ parent_id: id, sort: ['id', 'date_time'] }));
  };

  const onConfirm = (confirmed: boolean, values: any) => {
    setConfirmOpen(false);
    setConfirmMessages([]);

    if (confirmed) save(values);
  };

  const canSave = () => {
    if (appointment) {
      const { exported_payroll, exported_invoices } = appointment;
      if (!authenticatedUser?.accounts_access_enabled && (exported_payroll || exported_invoices)) return false;
    }
    return true;
  };

  const onSave = async (values: any) => {
    if (validateConfirm(values)) save(values);
    else {
      setChangedValues(values);
      setConfirmOpen(true);
    }
  };

  const save = async (values: any) => {
    if (isNew) {
      // childValues contains parent_id (which doesn't have a form field), so must be retained when creating a single appt from parent. Legacy garbage (╯°□°）╯︵ ┻━┻
      const { payload } = childValues
        ? await dispatch(create({ ...childValues, ...values }))
        : await dispatch(create({ ...values, repeat: isRepeat }));
      if (payload?.data?.id) navigate(`/appointments/${payload.data.id}`);
    } else {
      const { payload } = await dispatch(
        update({
          id,
          ...values,
        }),
      );
      // Scroll to top of container on success and failure.
      if (payload?.success) load();
      window.scrollTo(0, 0);
    }
  };

  const validateConfirm = (values: any) => {
    const definitions = fieldDefinitions.filter(({ show }) => {
      if (typeof show === 'function') return show(values);
      if (show === undefined) return true;
      return show;
    });

    let valid = true;
    definitions.forEach(({ key }) => {
      const definition = fieldDefinitions.find(({ key: fieldKey }) => fieldKey === key);

      if (definition?.validateConfirm) {
        const result = definition.validateConfirm(values);
        if (!result.valid) {
          valid = false;
          if (result.message) setConfirmMessages([...confirmMessages, result.message]);
        }
      }
    });

    return valid;
  };

  const isLoading = appointmentLoading === 'pending';
  const isRepeat = appointment?.repeat ?? forceRepeat;
  const isDeclined = appointment?.declined;
  const isPendingDeclined = isDeclined && appointment?.chargeable_cancellation_actioned === false;
  const canViewActivities = tags.includes(AuthTags.APPOINTMENTS.ACTIVITIES.VIEW);

  // Determine field definitions to use
  useEffect(() => {
    if (serviceTypes.length > 0) {
      const updatedFields = Object.entries(get({ isNew, isRepeat, ...appointment })).map(([key, props]) => ({
        key,
        disabled: !canSave(),
        ...props,
      }));

      setFields(updatedFields);
    }
  }, [serviceTypes]);

  useEffect(() => {
    const newFields = get({ isNew, isRepeat, ...appointment });
    setFields(newFields);
  }, [isNew, isRepeat, appointment]);

  const fieldDefinitions = Object.entries(fields).map(([key, props]) => {
    return {
      key,
      disabled: !canSave(),
      ...props,
    };
  });

  // Override values used within forms based on type of appointment
  const defaultValues = {
    transport: isTransport,
  };

  const overrideValues = useMemo(() => {
    const { parent_id } = searchParamValues;
    if (parent_id) {
      return {
        ...childValues,
        ...searchParamValues,
      };
    }

    return {
      ...(isNew ? copyValues : {}),
      ...searchParamValues,
      repeat: isRepeat,
    };
  }, [isNew, copyValues, isRepeat, childValues]);

  const buttonProps = {
    authTag: AuthTags.APPOINTMENTS.UPDATE,
    color: 'success',
    className: 'ms-4 me-4 ps-4 pe-4',
    children: `${isNew ? 'Add' : 'Update'} Appointment`,
  };

  return (
    <div className="p-2">
      <ConfirmDialog
        isOpen={confirmOpen}
        messages={confirmMessages}
        onClose={(confirm) => onConfirm(confirm, changedValues)}
      />
      <AppointmentBanner
        appointment={appointment}
        isNew={isNew}
        isTransport={isTransport}
        isRepeat={isRepeat}
        isDeclined={isDeclined}
      />
      {isPendingDeclined && (
        <AppointmentDeclined
          appointment={appointment}
          onAcknowledge={() => save({ chargeable_cancellation_actioned: true })}
        />
      )}
      <ContainerLoading loading={isLoading || isRedirecting} bgColor="white" color="black" isTransparent={true}>
        <AppointmentForm
          authTag={AuthTags.APPOINTMENTS.UPDATE}
          fields={fieldDefinitions}
          defaultValues={defaultValues}
          overrideValues={overrideValues}
          appointment={appointment}
          canSave={canSave}
          save={onSave}
          onConfirm={onConfirm}
          isNew={isNew}
          buttonProps={buttonProps}
          showActivityLogs={!isNew && canViewActivities}
        />
      </ContainerLoading>
    </div>
  );
};

export default Appointment;
