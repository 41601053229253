import { createThunks } from '~libs/reduxUtils';
import { get } from '~libs/genericAction';
import { apiWeeklyPlannerAppointmentUnallocated } from '~constants/api';
import { typePrefix as prefix } from '~weekly-planner/actions/appointments';

export const typePrefix = `${prefix}-unallocated`;

const thunkConfig = [
  { actionType: 'getAll', apiPath: apiWeeklyPlannerAppointmentUnallocated, method: get },
  { actionType: 'getImportAppointments', apiPath: `${apiWeeklyPlannerAppointmentUnallocated}/import`, method: get },
];

export const { getAll, getImportAppointments } = createThunks(typePrefix, thunkConfig);
