import React, { useEffect, useState } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

import Button from 'tsx/components/Button';
import { MultiCheckbox } from 'tsx/components/FormFields/Inputs';

import { formatDateString, formatTimeString } from 'tsx/libs/dayjs';
import { Row } from '~libs/reduxUtils';

type Props = {
  isOpen?: boolean;
  onClose: (confirm: boolean, ids: string[]) => void;
  rows: Row[];
};

function AppointmentCancellationConfirm({ isOpen = false, rows, onClose }: Props) {
  const [values, setValues] = useState<string[]>([]);

  useEffect(() => {
    if (isOpen) setValues(rows.map(({ id }) => id.toString()));
  }, [isOpen]);

  const id = 'existing-appointment-ids';
  const options = rows.map(({ id, date, start_time, end_time, user }) => ({
    id,
    name: `Cancel appointment on ${formatDateString(date)} (${formatTimeString(start_time)} - ${formatTimeString(end_time)})${user ? ` for ${user.full_name}` : ''}`,
  }));

  return (
    <Modal isOpen={isOpen} size="lg">
      <ModalHeader className="bg-danger text-white">Confirmation Required</ModalHeader>
      <ModalBody>
        <div className="mb-4 pb-2 border-2 border-bottom border-danger">
          <div>
            <b>FUTURE</b> appointments linked to this repeat appointment...
          </div>
          <div>
            Please select the following to be <b>cancelled</b>
          </div>
        </div>
        <MultiCheckbox
          type="multi-checkbox"
          id={id}
          name={id}
          value={values}
          onChange={(_, value) => setValues(value)}
          options={options}
          colSize={2}
        />
      </ModalBody>
      <ModalFooter className="align-items-center flex-column">
        <div className="mb-1">
          <div>Please note:</div>
          <ul>
            <li>
              Individual SMS alerts <b>will NOT</b> be set to workers as a part of this process
            </li>
            <li>All appointments cancelled in bulk will be marked as non-chargeable</li>
          </ul>
        </div>
        <div>
          <Button size="sm" color="danger" className="ps-4 pe-4 me-2" onClick={() => onClose(true, values)}>
            Confirm
          </Button>
          <Button size="sm" className="ps-4 pe-4" onClick={() => onClose(false, [])}>
            Back
          </Button>
        </div>
      </ModalFooter>
    </Modal>
  );
}

export default AppointmentCancellationConfirm;
