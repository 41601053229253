import React from 'react';

import { Time } from '~components/FormFields/Inputs';

interface ComponentProps {
  id: string;
  value: { [key: string]: any };
  disabled?: boolean;
  inline?: boolean;
  onChange: (name: string, value: any) => void;
  onKeyDown: (key: string) => void;
}

const TimeRange: React.FC<ComponentProps> = ({ id, value = {}, disabled = false, inline = true, onChange }) => {
  const startKey = 'gte';
  const endKey = 'lte';

  const setChange = (name: string, targetValue: string) => {
    onChange && onChange(id, { ...value, [name]: targetValue });
  };

  return (
    <div className={`${inline ? 'd-flex' : 'w-100 ps-3'}`}>
      <div className={`${!inline ? 'mt-1 mb-1' : ''} d-flex  align-items-center${disabled ? ' bg-disabled' : ''}`}>
        <span className="me-2">from</span>
        <Time
          type="time"
          name={startKey}
          id={startKey}
          className={`${disabled ? ' bg-disabled' : ''}`}
          value={value[startKey] ?? ''}
          onChange={setChange}
          disabled={disabled}
        />
      </div>
      <div className={`${!inline ? 'mt-1 mb-1' : ''} d-flex align-items-center`}>
        <span className="me-2">to</span>
        <Time
          type="time"
          name={endKey}
          id={endKey}
          className={`${disabled ? ' bg-disabled' : ''}`}
          value={value[endKey] ?? ''}
          onChange={setChange}
          disabled={disabled}
        />
      </div>
    </div>
  );
};

export default TimeRange;
