import { createThunks, Params } from '~libs/reduxUtils';
import { get } from '~libs/genericAction';
import { apiWeeklyPlannerAppointments, apiWeeklyPlanner, apiClients } from '~constants/api';

export const typePrefix = 'weekly-planner-appointments';

const thunkConfig = [
  { actionType: 'getAll', apiPath: apiWeeklyPlannerAppointments, method: get, actionParams: { limit: 0 } },
  { actionType: 'getExceptions', apiPath: `${apiWeeklyPlanner}/appointments/exceptions`, method: get },
  { actionType: 'getOnHold', apiPath: `${apiClients}/hold-periods`, method: get },

  {
    actionType: 'getAssignableUsers',
    apiPath: (params: Params) => `${apiWeeklyPlannerAppointments}/${params.id}/assignable-users`,
    method: get,
  },
];

export const { getAll, getExceptions, getOnHold, getAssignableUsers } = createThunks(typePrefix, thunkConfig);
