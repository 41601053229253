import React, { CSSProperties, ReactNode } from 'react';
import { useAppSelector } from 'hooks';
import classNames from 'classnames';
import { Dayjs } from 'dayjs';

import { dateInputFieldFormat } from '~libs/dayjs';
import { Client, Worker, WorkerAvailability } from '~weekly-planner/lib/common';

import Spinner from '~components/Spinner';
import { Cell } from './index';

import { selectFocusedWorkerId } from '~weekly-planner/selectors';
import { selectRowLoading } from '~weekly-planner/selectors/unsaved';

interface ComponentProps {
  headerCell: ReactNode;
  dataObj: Worker | Client;
  days: Dayjs[];
  date: Dayjs;
  style?: CSSProperties;
}

const Row: React.FC<ComponentProps> = ({ headerCell, dataObj, days, date, style }) => {
  const loading = useAppSelector((state) => selectRowLoading(state, parseInt(dataObj.id)));
  const focusedWorkerId = useAppSelector(selectFocusedWorkerId);

  const getDayAvailability = (day: Dayjs) => {
    if (!('availability' in dataObj) || !day || !dataObj?.availability) return null;

    const dayName = day.format('dddd');
    return dataObj?.availability[dayName as keyof WorkerAvailability] ?? {};
  };

  const className = classNames('worker-row', {
    focus: focusedWorkerId && parseInt(dataObj.id) === focusedWorkerId,
  });
  const isLoading = loading === 'pending';

  return (
    <div className={className}>
      {isLoading && <Spinner loading={isLoading} style={style} />}
      {headerCell}
      {days.map((day, index) => (
        <Cell
          style={{ ...style, gridColumn: index + 2 }}
          key={`${dataObj.id}-${index}`}
          id={`${dataObj.id}-${index}`}
          events={dataObj.events[day.format(dateInputFieldFormat)]}
          availability={getDayAvailability(day)}
          date={day.format(dateInputFieldFormat)}
          startDate={date.weekday(0)}
        />
      ))}
    </div>
  );
};

export default Row;
