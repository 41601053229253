import { apiTaskTypes } from '~constants/api';
import { get } from '~libs/genericAction';
import { createThunks, Params } from '~libs/reduxUtils';

export const typePrefix = 'tasks/types';

const config = [
  { actionType: 'getAll', apiPath: apiTaskTypes, method: get, actionParams: { limit: 0 } },
  { actionType: 'getOne', apiPath: (params: Params) => `${apiTaskTypes}/${params.id}`, method: get },
];

export const { getAll, getOne } = createThunks(typePrefix, config);
