import dayjs from 'dayjs';

import {
  buildAllocateAppointmentChange,
  buildDailyChange,
  buildWeeklyChange,
  getPredictedTime,
} from 'tsx/features/weekly-planner/lib/simulate';

import { simulate } from 'tsx/features/weekly-planner/actions/unsaved';
import { timeValueFormat } from 'tsx/libs/dayjs';
import { DragEndEvent } from '@dnd-kit/core';

export const MAX_PIPS = 288;
export const PIP_INTERVALS = 5; // Each pip represents x number of minutes

export const onWeeklyAppointmentDragEnd = (event: DragEndEvent) => {
  const { active, over } = event;
  const { current } = active?.data ?? {};
  if (!over || !current) return;

  const { containerId: oldCell, appointment, data, startDate, dispatch } = current;

  const newCell = over.id;

  if (oldCell === newCell) return;

  const change = buildWeeklyChange(data, oldCell, newCell.toString(), startDate, appointment);
  dispatch(simulate({ ...change, week_start: startDate }));
};

export const onDailyAppointmentDragEnd = (event: any) => {
  const { active, over, delta } = event;
  const { current } = active?.data ?? {};
  if (!over || !current) return;

  const { containerId: oldRow, appointment, data, startDate, dispatch } = current;
  const {
    id: newRow,
    rect: { width },
  } = over;

  // Time change event, changing start/end time
  let apptData = appointment;
  if (oldRow === newRow) {
    let { start_time, end_time, duration } = appointment;
    const { date } = appointment;
    const { x } = delta;
    const timeFormat = 'HH:mm';

    // Calculate duration if doesn't exist
    if (!duration) {
      duration = dayjs(`${end_time}`, timeFormat).diff(dayjs(`${start_time}`, timeFormat), 'minute');
    }

    const predictedConverter = (selTime: string) => {
      return getPredictedTime(width, x, MAX_PIPS, selTime, PIP_INTERVALS, date);
    };

    // If moved but placed in same position - Do not simulate.
    if (
      predictedConverter(start_time).format(timeValueFormat) === start_time &&
      predictedConverter(end_time).format(timeValueFormat) === end_time
    ) {
      return;
    }

    // Check time & direction of move on X axis to determine where to snap.
    if (predictedConverter(start_time).isBefore(date, 'day')) {
      start_time = '00:00';
      end_time = dayjs(start_time, timeFormat).add(duration, 'minute').format(timeFormat);
    } else if (predictedConverter(end_time).isAfter(date, 'day')) {
      end_time = '23:55';
      start_time = dayjs(end_time, timeFormat).subtract(duration, 'minute').format(timeFormat);
    } else {
      start_time = predictedConverter(start_time).format(timeValueFormat);
      end_time = predictedConverter(end_time).format(timeValueFormat);
    }

    apptData = {
      ...apptData,
      start_time,
      end_time,
    };
  }

  const change = buildDailyChange(data, oldRow, newRow.toString(), apptData);
  dispatch(simulate({ ...change, week_start: startDate }));
};

export const onUnallocatedDragEnd = (event: any) => {
  const { active, over } = event;
  const { current } = active?.data ?? {};
  if (!over || !current) return;

  const { containerId: oldCell, appointment, weeklyPlannerData, weekStart, dispatch } = current;
  const newCell = over.id;
  if (oldCell === newCell) return;

  const change = buildAllocateAppointmentChange(weeklyPlannerData, newCell.toString(), appointment);
  dispatch(simulate({ ...change, week_start: weekStart }));
};
