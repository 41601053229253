import { createSelector, createSlice, isPending, isRejected } from '@reduxjs/toolkit';
import { RootState } from 'store';
import { LoadingState } from '~libs/reduxUtils';

import { typePrefix, getAll, getOne, getAvailability } from '~care-workers/actions';
import { WorkerAvailability } from '~weekly-planner/lib/common';

interface userRow {
  id: number;
  full_name?: string;
  pay_level?: { id: string; name: string };
  qualifications?: Array<string>;
  rostering_notes?: string;
}

interface userState {
  loading: LoadingState;
  error: string | null | undefined;
  rows: Array<userRow> | [];
  row: userRow | any;
  total?: number;
  availability: WorkerAvailability;
}

const initialState: userState = {
  loading: 'idle',
  error: null,
  rows: [],
  row: { id: -1 },
  total: 0,
  availability: {},
};

// Main slice, connecting API actions to redux state.
export const usersSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    clearUsers(state) {
      state.rows = initialState.rows;
    },
    clearUser(state) {
      state.row = initialState.row;
    },
    clearErrors(state) {
      state.error = initialState.error;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAll.fulfilled, (state, action) => {
      state.loading = 'fulfilled';
      const { rows, total } = action.payload.data;
      state.rows = rows;
      state.total = total;
    });
    builder.addCase(getOne.fulfilled, (state, action) => {
      state.loading = 'fulfilled';
      state.row = action.payload.data;
    });
    builder.addCase(getAvailability.fulfilled, (state, action) => {
      state.loading = 'fulfilled';
      state.availability = action.payload.data;
    });
    // Default matching for loading cases, pending when action is being called
    builder.addMatcher(isPending, (state, { type }) => {
      if (type.startsWith(`${typePrefix}/`)) state.loading = 'pending';
    });
    builder.addMatcher(isRejected, (state, action) => {
      if (action.type.startsWith(`${typePrefix}/`)) {
        state.loading = 'declined';
        state.error = action.error.message;
      }
    });
  },
});

// Selectors, performing common selection tasks for this slice.
const UsersState = (state: RootState) => state.careWorkers.users;
export const selectUsers = (state: RootState) => UsersState(state).rows;
export const selectUsersTotal = (state: RootState) => UsersState(state).total;
export const selectCurrentUser = (state: RootState) => UsersState(state).row;
export const selectLookupUsers = createSelector(selectUsers, (rows) =>
  rows.map(({ id, full_name }) => ({ id, name: `${full_name}` })),
);
export const selectLookupCareWorkers = createSelector(selectUsers, (rows) =>
  rows.map(({ id, full_name, pay_level }) => ({
    id,
    name: `${full_name} ${pay_level?.name && `(${pay_level.name})`}`,
  })),
);

export const selectUserByID = createSelector([selectUsers, (_: RootState, id?: number) => id], (rows, id) =>
  rows.find(({ id: rowId }) => id === rowId),
);

export const selectUserAvailability = (state: RootState) => UsersState(state).availability;
export const selectUsersLoading = (state: RootState) => UsersState(state).loading;
export const selectUsersErrorResponse = (state: RootState) => UsersState(state).error;

export const { clearUsers, clearUser, clearErrors } = usersSlice.actions;

export default usersSlice.reducer;
