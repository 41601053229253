import { apiWeeklyPlannerAppointmentsRejected } from '~constants/api';

import { get } from '~libs/genericAction';
import { createThunks } from '~libs/reduxUtils';

import { typePrefix as prefix } from '~weekly-planner/actions/appointments';

export const typePrefix = `${prefix}-rejected`;

const thunkConfig = [
  {
    actionType: 'getAll',
    apiPath: apiWeeklyPlannerAppointmentsRejected,
    method: get,
  },
];

export const { getAll } = createThunks(typePrefix, thunkConfig);
