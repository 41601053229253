import React, { useState } from 'react';
import { Col, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Progress, Row, Spinner } from 'reactstrap';
import { ColumnDef } from '@tanstack/react-table';
import { Formik, FormikValues } from 'formik';
import Button from 'tsx/components/Button';
import { ReadOnly } from 'tsx/components/FormFields/Inputs';
import { SearchField } from 'tsx/components/FormFields/Search';
import Icon, { faEllipsis } from 'tsx/components/Icon';
import { prepareSearchParam } from 'tsx/libs/searchField';
import { ExportProps } from 'tsx/types/exportProps';

import ModalCard from './ModalCard';
import TableView, { TableViewRows } from './TableView';

type Props = {
  isOpen?: boolean;
  fields?: ExportProps;
  estimate?: { first: TableViewRows; last: TableViewRows; count: number | null };
  openingValues?: Record<string, any>;
  onClose: () => void;
  onEstimate?: (values: { [key: string]: any }) => any;
  onDownload?: (params: { [key: string]: any }, onProgress: (value: any) => void) => any;
};

function ExportDialog({
  isOpen = false,
  fields = {},
  estimate,
  openingValues = {},
  onClose,
  onEstimate,
  onDownload,
}: Props) {
  // All checkboxes are checked by default.
  const [values, setValues]: [any, any] = useState(
    Object.entries(fields).reduce((values, [key]) => {
      values[key] = true;
      return values;
    }, {} as any),
  );
  const [isDownloading, setIsDownloading] = useState<boolean>(false);
  const [isEstimating, setIsEstimating] = useState<boolean>(false);
  const [progressValue, setProgressValue] = useState(100);
  const [progressText, setProgressText] = useState('');
  const [isCardOpen, setCardIsOpen] = useState({ attributes: true, preview: false });

  // Prepare initial values based on opening values (set by default)
  const initialValues = Object.keys(fields).reduce(
    (values, key) => {
      const field = fields[key].field;
      if (field && !(field instanceof Array)) values[key] = openingValues[field] ?? '';
      return values;
    },
    {} as Record<string, any>,
  );

  const onChange = ({ name, value }: { name: string; value: any }) => {
    setValues({
      ...values,
      [name]: value,
    });
  };

  const prepareSearchParams = (searchValues: FormikValues) => {
    let params: { [key: string]: any } = {};
    Object.entries(searchValues).forEach(([key, value]) => {
      const definition = fields[key];
      if (!definition || values[key] !== true || value === '') return;
      const { type, field } = definition;
      params = {
        ...params,
        ...prepareSearchParam(type, field, value),
      };
    });

    return params;
  };

  const prepareColumns = () => {
    const columns: Array<{ id: string; name: string }> = [];
    Object.entries(values).forEach(([key, value]) => {
      const definition = fields[key];
      if (!definition || value !== true) return;
      const { caption, field, columnField } = definition;
      const column = { id: key, name: caption ?? '' };
      if (columnField) column.id = columnField;
      else if (typeof field === 'string') column.id = field;
      columns.push(column);
    });

    return columns;
  };

  const onProgress = (value: string) => {
    setProgressText(value);
    if (value.endsWith('%')) {
      setProgressValue(parseFloat(value.slice(0, -1)));
    } else setProgressValue(100);
  };

  const startDownload = async (values: FormikValues) => {
    if (!onDownload) {
      setIsDownloading(false);
      return;
    }
    setIsDownloading(true);

    if (onEstimate) {
      const { count = 0 } = await startEstimate(values);
      if (count > 10000) {
        setIsDownloading(false);
        return;
      }
    }

    const params = prepareSearchParams(values);

    const columns = prepareColumns();

    const payload = await onDownload({ ...params, columns }, onProgress);
    if (payload) {
      const contentDisposition = payload.headers['content-disposition'];
      const filename = contentDisposition ? contentDisposition.split('=')[1] : 'file.pdf';

      // Create a link adding the received data into navigateable link and provide to user
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(new Blob([payload.data]));
      link.setAttribute('download', filename);
      document.body.appendChild(link);
      setIsDownloading(false);
      setProgressValue(0);
      link.click();
      link.remove();
    } else {
      setIsDownloading(false);
      setProgressValue(0);
    }
  };

  const startEstimate = async (values: FormikValues, toggle = false) => {
    setIsEstimating(true);

    const params = prepareSearchParams(values);

    const { payload } = onEstimate && (await onEstimate(params));
    setIsEstimating(false);

    if (toggle) setCardIsOpen({ attributes: false, preview: true });

    return payload?.data ?? null;
  };

  const buildPills = (searchValues: FormikValues, setFieldValue: any) =>
    Object.entries(fields).map(([key, { caption, ...props }], index) => {
      const checked = values[key];
      const searchValue = searchValues[key];
      return (
        <Row key={index} className="align-items-center ps-2 pe-2 mt-1 mb-1">
          <Col sm={3}>
            <Input
              type="checkbox"
              name={`check_${key}`}
              id={`check_${key}`}
              checked={checked}
              onChange={({ target: { checked } }) => onChange({ name: key, value: checked })}
            />
            <Label for={`check_${key}`} className="ms-2 mb-0">
              {caption}
            </Label>
          </Col>
          <Col>
            <SearchField
              includeCaption={false}
              inline={false}
              id={key}
              value={searchValue}
              className={`d-flex align-items-center justify-content-end border rounded bg-white me-2 ${props.type}`}
              disabled={!checked}
              setFieldValue={setFieldValue}
              {...props}
            />
          </Col>
        </Row>
      );
    });

  const hasEstimate = estimate !== undefined && estimate !== null;
  const count = estimate?.count ?? 0;

  // Load separator to custom render within rows, add first rows and last rows above and below respectively
  const first = estimate?.first ?? [];
  const last = estimate?.last ?? [];
  const estimateRows = [
    ...first,
    {
      id: -1,
      isCustom: true,
      getCustomRow: ({ id, colCount }: any) => (
        <tr key={id}>
          <td colSpan={colCount}>
            <div className="text-center">
              <Icon icon={faEllipsis} className="pt-2 pb-2" />
            </div>
          </td>
        </tr>
      ),
    },
    ...last,
  ];

  const previewColumns = Object.entries(fields)
    .filter(([key, { columnDef }]) => values[key] === true && columnDef !== undefined)
    .map(([, { columnDef }]) => columnDef) as ColumnDef<any>[];

  return (
    <Formik enableReinitialize initialValues={initialValues} onSubmit={(values) => startDownload(values)}>
      {({ values, setFieldValue, submitForm }) => (
        <Modal isOpen={isOpen} toggle={onClose} size="xl">
          <ModalHeader className="bg-success text-white">Export</ModalHeader>
          <ModalBody className="export-dialog">
            <div className="mb-2 pb-2 border-2 border-bottom border-success">
              Choose from the following attributes to export...
            </div>
            <ModalCard
              className="border-0"
              headerClassName="rounded-0"
              isOpen={isCardOpen.attributes}
              setIsOpen={(toggle) => setCardIsOpen({ ...isCardOpen, attributes: toggle })}
              header="Attributes"
            >
              {buildPills(values, setFieldValue)}
            </ModalCard>
            <ModalCard
              className="border-0 preview"
              headerClassName="rounded-0"
              isOpen={isCardOpen.preview}
              setIsOpen={(toggle) => hasEstimate && setCardIsOpen({ ...isCardOpen, preview: toggle })}
              allowOpen={hasEstimate}
              header={
                <div className="d-flex justify-content-between w-100 me-2">
                  <div>Preview</div>
                  {hasEstimate && (
                    <div
                      className={count > 10000 ? ' p-1 ps-2 pe-2 rounded bg-warning' : ''}
                    >{`Approx. ${count} rows`}</div>
                  )}
                </div>
              }
            >
              <TableView
                id="export"
                data={estimateRows}
                columns={previewColumns}
                showHeader={true}
                emptyMessage={'No rows found'}
              />
              <ReadOnly
                type="readonly"
                className="text-end small"
                name="preview_tooltip"
                id="preview_tooltip"
                value={`* Showing first ${first.length} rows, last ${last.length} rows`}
              />
            </ModalCard>
            {isDownloading && (
              <Progress
                className="m-2 text-center"
                color="success"
                style={{ height: '25px' }}
                animated
                value={progressValue}
              >
                {progressText}
              </Progress>
            )}
          </ModalBody>
          <ModalFooter className="align-items-center">
            <div className={`d-flex justify-content-end mt-1 mb-1 me-3`}>
              <ReadOnly
                type="readonly"
                className="ms-2 small"
                name="row_estimate_reminder"
                id="row_estimate_reminder"
                value={`Maximum of 10,000 rows only`}
              />
            </div>
            <Button size="sm" disabled={isEstimating} color="success" onClick={() => startEstimate(values, true)}>
              {isEstimating && <Spinner type="grow" size="sm" className="ms-4 me-4" />}
              {!isEstimating && <span>Estimate/Preview</span>}
            </Button>
            <Button size="sm" color="success" onClick={submitForm} disabled={isDownloading}>
              Download
            </Button>
            <Button size="sm" color="secondary" onClick={onClose}>
              Close
            </Button>
          </ModalFooter>
        </Modal>
      )}
    </Formik>
  );
}

export default ExportDialog;
