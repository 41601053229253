import React, { PropsWithChildren } from 'react';
import Icon, { IconProp, SizeProp } from 'tsx/components/Icon';

type Props = {
  className?: string;
  icon?: IconProp;
  iconSize?: SizeProp;
};

function ContainerHeader({ className, icon, iconSize = '1x', children }: PropsWithChildren<Props>) {
  return (
    <div
      className={`d-flex flex-row justify-content-between ps-3 pe-3 header${className !== undefined ? ` ${className}` : ''}`}
    >
      <div className="d-flex flex-row align-items-center">
        {icon && <Icon size={iconSize} icon={icon} className="me-2" />}
        <h3>{children}</h3>
      </div>
    </div>
  );
}

export default ContainerHeader;
