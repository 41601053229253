import { combineReducers } from '@reduxjs/toolkit';

import * as addressReducers from './address';
import * as clientsReducers from './clients';
import app from './app';
import equipments from './equipments';
import leaveCodes from './leaveCodes';
import leaveRequests from './leaveRequests';
import login from './login';
import medications from './medications';
import ndisSupportItems from './ndisSupportItems';
import paymentTypes from './paymentTypes';
import taskClassifications from './taskClassifications';
import taskTypes from './taskTypes';

export const main = {
  ...addressReducers,
  ...clientsReducers,
  app,
  equipments,
  leaveCodes,
  leaveRequests,
  login,
  medications,
  ndisSupportItems,
  paymentTypes,
  taskClassifications,
  taskTypes,
};

export default combineReducers(main);
