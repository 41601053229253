import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'hooks';
import { Spinner } from 'reactstrap';
import Button from 'tsx/components/Button';
import { InputProps } from 'tsx/components/FormFields';
import * as FormInput from 'tsx/components/FormFields/Inputs';
import Icon, { faPersonArrowDownToLine, faPersonArrowUpFromLine, IconProp } from 'tsx/components/Icon';

import { getDistance } from '../actions/locations';
import {
  selectErrorResponse,
  selectLocationDistance,
  selectLocations,
  selectLocationsLoading,
} from '../reducers/locations';

const FormInputLocations: React.FC<InputProps> = ({ value, onChange }) => {
  const dispatch = useAppDispatch();
  const locations = useAppSelector(selectLocations);
  const distance = useAppSelector(selectLocationDistance);
  const locationsLoading = useAppSelector(selectLocationsLoading);
  const errorResponse = useAppSelector(selectErrorResponse);
  const [params, setParams] = useState({});

  const { client_id, transport_pickup_id = '', transport_dropoff_id = '' } = value;

  useEffect(() => {
    setParams({ ...params, client_id });
  }, [client_id]);

  const clientHomeID = 100000;
  const selectOptions = [
    { id: clientHomeID, name: 'Client Home' },
    ...locations.map(({ id, name }) => ({ id, name: name.toString() })),
  ];

  const setChange = (name: string, value: any) => {
    // Update calculate trip parameters based on user's current selection
    const newParams: { [key: string]: any } = { ...params };
    let homeParam;
    switch (name) {
      case 'pickup_id': {
        homeParam = 'home_pickup';
        break;
      }
      default:
      case 'dropoff_id': {
        homeParam = 'home_dropoff';
      }
    }

    // If a client home pickup/dropoff, add a toggle and remove the previous ID.
    // Else, remove toggle and add the new ID
    if (value === clientHomeID.toString()) {
      newParams[homeParam] = true;
      delete newParams[name];
    } else {
      newParams[name] = value;
      delete newParams[homeParam];
    }

    setParams(newParams);

    onChange && onChange(`transport_${name}`, value);
  };

  const onCalculate = () => {
    dispatch(getDistance(params));
  };

  const buildSelect = (icon: IconProp, className: string, id: string, placeholder: string, value: any) => (
    <>
      <Icon icon={icon} size="1x" className={className} />
      <FormInput.Select
        type="select"
        id={id}
        name={id}
        placeholder={placeholder}
        value={value}
        onChange={setChange}
        options={selectOptions}
      />
    </>
  );

  const buildDistanceValue = () => {
    if (distance === undefined) return '';
    let message = `${Math.round(distance.distance)} billable KMs`;
    message += ` (Approx. ${Math.round(distance.duration)} mins)`;
    return message;
  };

  const isLoading = locationsLoading === 'pending';
  const showDistance = distance !== undefined;

  return (
    <>
      <div className="d-flex align-items-center ps-2 pe-2 pb-2">
        {buildSelect(faPersonArrowUpFromLine, 'me-2', 'pickup_id', 'Pickup', transport_pickup_id)}
        {buildSelect(faPersonArrowDownToLine, 'ms-2 me-2', 'dropoff_id', 'Drop Off', transport_dropoff_id)}
      </div>
      {errorResponse && <div className="ps-2 pe-2 small text-danger">{errorResponse}</div>}
      <div className="d-flex align-items-center p-2">
        <Button
          type="button"
          size="sm"
          className="ms-4 me-4 ps-4 pe-4"
          color="success"
          onClick={onCalculate}
          disabled={isLoading}
        >
          {isLoading && <Spinner type="grow" size="sm" className="ms-4 me-4" />}
          {!isLoading && <span>Calculate Trip</span>}
        </Button>
        {showDistance && !isLoading && (
          <FormInput.ReadOnly type="readonly" name="km_estimate" id="km_estimate" value={buildDistanceValue()} />
        )}
      </div>
    </>
  );
};

export default FormInputLocations;
