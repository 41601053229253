import { ExportProps } from 'tsx/types/exportProps';
import { selectAppointmentStatuses } from '../../reducers/statuses';
import { selectLookupAppointmentServiceTypes } from '../../reducers/serviceTypes';
import { selectAppointmentRepeatFrequencies } from '../../reducers/repeatFrequencies';
import { selectClientPackages } from '~main/reducers/clients/clientPackages';
import { selectClientReferrers } from '~main/reducers/clients/clientReferrers';

import { formatDateString, formatTimeString, formatWeekday } from 'tsx/libs/dayjs';

const fields: ExportProps = {
  client_name: {
    type: 'text-like',
    caption: 'Client',
    checked: true,
    field: 'client.full_name',
    placeholder: 'Filter..',
    columnDef: {
      id: 'client.full_name',
      accessorKey: 'client.full_name',
      header: 'Client',
    },
  },
  care_worker: {
    type: 'text-like',
    caption: 'Care Worker',
    checked: true,
    field: 'user.full_name',
    placeholder: 'Filter..',
    columnDef: {
      id: 'user.full_name',
      accessorFn: (row) => row.user?.full_name ?? '',
      header: 'Care Worker',
    },
  },
  referrer: {
    type: 'multi-lookup',
    field: 'client.referrer_name',
    caption: 'Referrer',
    placeholder: 'All',
    selectorOptions: selectClientReferrers,
    columnDef: {
      id: 'client.referrer_name',
      accessorFn: (row) => row.client?.referrer_name ?? '',
      header: 'Referrer',
    },
  },
  region_name: {
    type: 'text-like',
    caption: 'Client Region',
    field: 'client.region.name',
    placeholder: 'Filter..',
    columnDef: {
      id: 'client.region.name',
      accessorFn: (row) => row.client?.region?.name ?? '',
      header: 'Client Region',
    },
  },
  repeat_start: {
    type: 'date-range',
    caption: 'Repeat Start',
    field: 'repeat_start',
    columnDef: {
      id: 'repeat_start',
      accessorKey: 'repeat_start',
      header: 'Repeat Start',
      cell: (info) => formatDateString(info.getValue()),
    },
  },
  start_time: {
    type: 'time-range',
    field: 'start_time',
    caption: 'Start Time',
    columnDef: {
      id: 'start_time',
      accessorFn: (row) => row?.start_time ?? '',
      header: 'Start Time',
      cell: (info) => formatTimeString(info.getValue()),
    },
  },
  repeat_dates: {
    type: 'multi-lookup',
    caption: 'Week Repeat Day',
    field: 'repeat_weekday',
    options: Array.from({ length: 7 }, (_, index) => {
      const day = formatWeekday(index + 1, true);
      return { id: day, name: day };
    }),
    placeholder: 'All',
    columnDef: {
      id: 'repeat_weekday',
      accessorFn: (row) => row.repeat_frequency?.day ?? '',
      header: 'Week Day',
    },
  },
  week_count: {
    type: 'multi-lookup',
    caption: 'Week Repeat Count',
    field: 'repeat_week_count',
    selectorOptions: selectAppointmentRepeatFrequencies,
    placeholder: 'All',
    columnDef: {
      id: 'repeat_week_count',
      accessorFn: (row) => row.repeat_frequency?.frequency ?? '',
      header: 'Week Repeat Count',
    },
  },
  status: {
    type: 'multi-lookup',
    field: 'status_id',
    columnField: 'status.name',
    caption: 'Status',
    placeholder: 'All',
    selectorOptions: selectAppointmentStatuses,
    columnDef: {
      id: 'status_id',
      accessorKey: 'status.name',
      header: 'Status',
    },
  },
  service_type: {
    type: 'multi-lookup',
    field: 'service_type_id',
    columnField: 'service_type.name',
    caption: 'Service Type',
    placeholder: 'All',
    selectorOptions: selectLookupAppointmentServiceTypes,
    columnDef: {
      id: 'service_type.name',
      accessorFn: ({ service_type }) =>
        service_type !== undefined ? `${service_type.name}${service_type.deleted ? ' - DELETED' : ''}` : '',
      header: 'Service Type',
    },
  },
  client_package: {
    type: 'multi-lookup',
    field: 'package_fixed_id',
    columnField: 'package_fixed.name',
    caption: 'Package',
    placeholder: 'All',
    selectorOptions: selectClientPackages,
    columnDef: {
      id: 'package_fixed.name',
      accessorFn: (row) => row.package_fixed?.name ?? '',
      header: 'Package',
    },
  },
};

export default fields;
