import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'hooks';
import classNames from 'classnames';
import dayjs, { Dayjs } from 'dayjs';
import { throttle } from 'lodash';

import { WeeklyPlannerDateRangeOptions as RangeOptions } from '~constants/maps';

import { dateInputFieldFormat, weeklyPlannerDayDisplayFormat } from '~libs/dayjs';
import { LoadingState } from '~libs/reduxUtils';
import { Client, Worker } from '~weekly-planner/lib/common';

import ContainerPanel from '~components/ContainerPanel';
import Spinner from '~components/Spinner';
import { Row } from '~weekly-planner/components/Main/Daily';
import FocusBar from '~weekly-planner/components/Main/FocusBar';

import { update, updateParams } from '~weekly-planner/reducers/weeklyPlanner';

import { selectDate, selectFocusedAppointment, selectParams } from '~weekly-planner/selectors';

interface ComponentProps {
  headerCell: React.FunctionComponent<any>;
  data: Array<Worker | Client>;
  loadingState: LoadingState | null;
}

const View: React.FC<ComponentProps> = ({ headerCell: HeaderCell, data, loadingState }) => {
  const [isMousedown, setIsMousedown] = useState<boolean>(false);
  const [startX, setStartX] = useState(0);
  const [initialScrollLeft, setInitialScrollLeft] = useState(0);

  const dispatch = useAppDispatch();
  const { week_start, limit } = useAppSelector(selectParams);
  const date = useAppSelector(selectDate);
  const isLoading = loadingState === 'pending';

  // most recent Monday
  const weekStart = dayjs(week_start).weekday(0);
  const daysOfWeek = Array.from({ length: 7 }, (_, i) => weekStart.add(i, 'day'));

  const focused = useAppSelector(selectFocusedAppointment);
  const { appointment: focusedAppointment } = focused || {};
  const { id: focusId } = focusedAppointment || {};

  const contentWrapperRef = useRef<HTMLDivElement>(null);
  const scrollableContainerRef = useRef<HTMLDivElement>(null);
  const middleOfDayRef = useRef<HTMLDivElement>(null);

  const handleMouseMove = (event: any) => {
    if (isMousedown && contentWrapperRef.current) {
      // Calculate the horizontal movement
      const deltaX = startX - event.clientX;

      const newScrollPosition = initialScrollLeft + deltaX;

      // Update scroll position
      contentWrapperRef.current.scrollLeft = newScrollPosition;
    }
  };
  const onVerticalScroll = useCallback(
    throttle(async () => {
      if (!contentWrapperRef.current || isLoading) return;

      const { scrollHeight, scrollTop, clientHeight } = contentWrapperRef.current;
      const nearBottom = scrollHeight - (scrollTop + clientHeight) < 50;

      if (!isLoading && nearBottom && limit <= data.length) {
        dispatch(updateParams({ limit: limit + 30 }));
      }
    }, 200),
    [limit, data.length, dispatch, isLoading],
  );

  // Handle the mouse down event to start dragging
  const handleMouseDown = (event: React.MouseEvent) => {
    if (contentWrapperRef.current) {
      document.addEventListener('mouseup', handleMouseUp);
      setIsMousedown(true);
      setStartX(event.clientX); // Track initial mouse position
      setInitialScrollLeft(contentWrapperRef.current.scrollLeft); // Track initial scroll position
    }
  };

  // Handle the mouse up or mouse leave event to stop dragging
  const handleMouseUp = () => {
    setIsMousedown(false);
    document.removeEventListener('mouseup', handleMouseUp);
  };

  useEffect(() => {
    const container = contentWrapperRef.current;
    if (!container) return;

    container.addEventListener('scroll', onVerticalScroll, { passive: true });

    return () => {
      container.removeEventListener('scroll', onVerticalScroll);
      document.removeEventListener('mouseup', handleMouseUp);
    };
  }, [onVerticalScroll]);

  useEffect(() => {
    if (middleOfDayRef?.current) {
      middleOfDayRef.current.scrollIntoView({
        behavior: 'auto',
        block: 'center',
        inline: 'center',
      });
    }
  }, []);

  const setView = (value: Dayjs) => {
    dispatch(
      updateParams({
        week_start: value.format(dateInputFieldFormat),
        week_end: value.add(1, 'day').format(dateInputFieldFormat),
      }),
    );
    dispatch(update({ date: value.format(dateInputFieldFormat), rangeType: RangeOptions.DAILY }));
  };

  const headerColumnData = daysOfWeek.map((day) => {
    const className = classNames('header-cell', {
      selected: day.isSame(date, 'day'),
    });

    return (
      <div key={day.toString()} className={className}>
        <span className="clickable" onClick={() => setView(day)}>
          {day.format(weeklyPlannerDayDisplayFormat)}
        </span>
      </div>
    );
  });

  const className = classNames('daily-view-container', {
    loading: isLoading,
  });

  const timelineClassName = classNames('timeline-wrapper', { dragging: isMousedown });
  const wrapperClassName = classNames('daily-view-wrapper', { dragging: isMousedown });
  const isCollapsed = isNaN(Number(focusId));

  return (
    <ContainerPanel
      className={wrapperClassName}
      isCollapsed={isCollapsed}
      canCollapse={false}
      canResize={false}
      sidePanel={<FocusBar />}
    >
      <div ref={scrollableContainerRef} className={className} onMouseMove={handleMouseMove}>
        <div className="header-wrapper">
          <div className="header-cell title">View:</div>
          {headerColumnData}
        </div>
        {/* Daily Planner Content */}
        <div className="content-wrapper" ref={contentWrapperRef}>
          {/* Timeline */}
          <div className={timelineClassName} onMouseDown={handleMouseDown}>
            <div className="timeline-header">Timeline</div>
            <div className="timeline-content">
              {[...Array(24)].map((_, index) => (
                <div key={index} className="hour-marker" ref={index === 12 ? middleOfDayRef : null}>
                  {`${index}:00`}
                </div>
              ))}
            </div>
          </div>

          {data?.map((dataObj, index) => (
            <Row
              key={`${dataObj.id}-${index}`}
              headerCell={
                <HeaderCell
                  data={dataObj}
                  date={dayjs(week_start)}
                  style={{
                    gridRow: index + 2,
                  }}
                />
              }
              dataObj={dataObj}
              date={dayjs(date)}
              events={dataObj.events[date]}
              style={{
                gridRow: index + 2,
              }}
            />
          ))}
          {data.length >= 1 && <Spinner loading={isLoading} className="p-5" />}
        </div>
        {data.length < 1 && <Spinner loading={isLoading} className="p-5" />}
      </div>
    </ContainerPanel>
  );
};

export default View;
