import IconComponent, { IconProp, SizeProp } from './Icon';

// Re-export everything from iconLibrary
export * from './iconLibrary';

// Default export for IconComponent
export default IconComponent;

// Export types for Icon properties
export type { IconProp, SizeProp };
