import classNames from 'classnames';
import { useAppSelector } from 'hooks';
import React from 'react';
import tinycolor from 'tinycolor2';
import { selectCareQualificationLevels } from 'tsx/features/appointments/reducers/careQualificationLevels';

interface Props {
  qualifications: string[];
  isCondensed?: boolean;
  isWrapped?: boolean;
}

const WorkerQualifications: React.FC<Props> = ({ qualifications = [], isCondensed = false, isWrapped = true }) => {
  const qualificationLevels = useAppSelector(selectCareQualificationLevels);
  const className = classNames({
    'flex-wrap': isWrapped,
    'gap-1': isWrapped,
    'd-flex': isWrapped,
  });
  return (
    <div className={className}>
      {qualifications
        ?.map((qId, index) => {
          const qualification = qualificationLevels.find(({ id }) => id === parseInt(qId));
          if (!qualification) return false;
          const { id, name, colour } = qualification;

          const backgroundColor = colour.length > 0 ? colour : 'lightgrey';

          return (
            <div className="d-flex" key={`${index}-${id}`}>
              <div
                className="qualification-icon border rounded border-1 border-dark px-1 me-1 mb-1"
                style={{ backgroundColor, color: tinycolor(backgroundColor).isDark() ? 'white' : undefined }}
                title={name}
              >
                {isCondensed && name[0]}
              </div>
              {!isCondensed && <div className="mb-1 me-1 align-self-center">{name}</div>}
            </div>
          );
        })
        .filter(Boolean)}
    </div>
  );
};

export default WorkerQualifications;
