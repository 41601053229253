import {
  ActionReducerMapBuilder,
  CaseReducer,
  createAsyncThunk,
  createSlice,
  Draft,
  isPending,
  isRejected,
  PayloadAction,
  Slice,
} from '@reduxjs/toolkit';

/**
 * Actions
 */

export type LoadingState = 'idle' | 'pending' | 'fulfilled' | 'declined' | 'error';

export interface Params {
  id?: string;
  [key: string]: string | number | boolean | object | null | undefined;
}

export interface Row {
  id: number;
  [key: string]: any;
}

export interface ThunkConfig {
  actionType: string;
  apiPath: string | ((params: Params) => string);
  method: (url: string, params?: any) => Promise<any>;
  actionParams?: Params;
}

const generateThunk = (
  type: string,
  apiEndpoint: string | ((params: Params) => string),
  method: (url: string, params?: any) => Promise<any>,
  actionParams?: Params,
) => {
  return createAsyncThunk(type, async (params?: Params) => {
    let finalParams;
    // If an array of parameters, don't mess with it.
    // Don't add actionParams to an array of values
    if (Array.isArray(params)) finalParams = params;
    else {
      const safeParams = params ?? {};
      finalParams = { ...actionParams, ...safeParams };
    }

    const endpoint = typeof apiEndpoint === 'function' ? apiEndpoint(finalParams) : apiEndpoint;
    return method(endpoint, finalParams);
  });
};

export const createThunk = generateThunk;

export const createThunks = (prefix: string, thunks: Array<ThunkConfig>) => {
  return thunks.reduce(
    (acc, { actionType, apiPath, method, actionParams }) => {
      acc[actionType] = generateThunk(`${prefix}/${actionType}`, apiPath, method, actionParams);
      return acc;
    },
    {} as Record<string, any>,
  );
};

/**
 * Reducers
 */

export interface CommonState<T> {
  loading: LoadingState;
  error: string | null | undefined;
  rows: Array<T>;
  row?: T;
  hasLoaded?: boolean;
  [key: string]: any;
}

const setLoading = <T>(state: CommonState<T>, actionType: string, loadingState: LoadingState, sliceName: string) => {
  if (actionType.startsWith(`${sliceName}/`)) {
    state.loading = loadingState;
  }
};

const addMatchers = <T>(builder: ActionReducerMapBuilder<CommonState<T>>, sliceName: string) => {
  builder
    .addMatcher(isPending, (state, action) => setLoading(state, action.type, 'pending', sliceName))
    .addMatcher(isRejected, (state, action) => {
      setLoading(state, action.type, 'declined', sliceName);
      state.error = action.error.message;
    });
};

const addCases = <T>(builder: ActionReducerMapBuilder<CommonState<T>>, getAll?: any, getOne?: any): void => {
  if (getAll) {
    builder.addCase(getAll.fulfilled, (state, action) => {
      state.loading = 'fulfilled';
      state.rows = action.payload.data;
      state.hasLoaded = true;
    });
  }
  if (getOne) {
    builder.addCase(getOne.fulfilled, (state, action) => {
      state.loading = 'fulfilled';
      state.row = action.payload.data;
    });
  }
};

// FOR BACKWARDS COMPATIBILITY ONLY
export const addCommonReducers = <T>(
  builder: ActionReducerMapBuilder<CommonState<T>>,
  typePrefix: string,
  getAll?: any,
  getOne?: any,
) => {
  addCases(builder, getAll, getOne);
  addMatchers(builder, typePrefix);
};

export const buildSlice = <TState extends CommonState<TRow> & Record<string, any>, TRow = Row>(
  name: string,
  initialState: TState,
  config: {
    extraReducers?: (builder: ActionReducerMapBuilder<TState>) => void;
    customActions?: Record<string, CaseReducer<TState, PayloadAction<any>>>;
    commonConfig?: {
      getAll?: any;
      getOne?: any;
      typePrefix?: string;
    };
    excludeDefaults?: boolean;
  } = {},
): Slice<TState> => {
  const sliceName = config.commonConfig?.typePrefix ?? name;

  const addDefaultReducers = (): Record<string, CaseReducer<TState, PayloadAction<any>>> => ({
    updateParams(state, action: PayloadAction<Record<string, any>>) {
      const stateWithParams = state as Draft<{ params: Record<string, any> }> & Draft<TState>;
      if ('params' in stateWithParams && typeof stateWithParams.params === 'object') {
        stateWithParams.params = { ...stateWithParams.params, ...action.payload };
      }
    },
    clear(state, action: PayloadAction<string[]>) {
      if (action.payload.length === 0) {
        Object.assign(state, initialState);
        if ('hasLoaded' in state && typeof state.hasLoaded === 'boolean') {
          state.hasLoaded = false;
        }
      } else {
        action.payload.forEach((key) => {
          if (key in initialState) {
            (state as any)[key] = initialState[key];
          }
        });
      }
    },
  });

  const reducers = {
    ...(config.excludeDefaults ? {} : addDefaultReducers()),
    ...(config.customActions || {}),
  };

  return createSlice({
    name,
    initialState,
    reducers,
    extraReducers: (builder) => {
      if (config.commonConfig) {
        addCases(builder, config.commonConfig.getAll, config.commonConfig.getOne);
      }

      config.extraReducers?.(builder);
      addMatchers(builder, sliceName);
    },
  });
};
