import React, { CSSProperties, useEffect, useState } from 'react';
import { useAppSelector } from 'hooks';
import classNames from 'classnames';
import dayjs from 'dayjs';

import { weeklyPlannerDayDisplayFormat } from '~libs/dayjs';
import { Appointment } from '~weekly-planner/lib/common';

import Icon, { faRepeat } from '~components/Icon';
import { WorkerQualifications } from '~weekly-planner/components/Common';

import { selectFocusedAppointment, selectOptions as selectWeeklyPlannerOptions } from '~weekly-planner/selectors';

interface ComponentProps {
  appointment: Appointment;
  showStatus?: boolean;
  isActionMenuOpen?: boolean;
  anyActionMenuOpen?: boolean;
}

const UnallocatedCard: React.FC<ComponentProps> = ({
  appointment,
  showStatus = false,
  isActionMenuOpen,
  anyActionMenuOpen,
}) => {
  const { unallocatedDuration, unallocatedQualifications, unallocatedServiceType, unallocatedSuburb } =
    useAppSelector(selectWeeklyPlannerOptions);

  const focused = useAppSelector(selectFocusedAppointment);

  const { appointment: focusedAppointment } = focused || {};
  const { key: focusKey, is_bidding: focusBidding, bidding_user_ids: focusBiddingIds } = focusedAppointment || {};

  const {
    key,
    id,
    client,
    date,
    user,
    parent_repeat_id,
    start_time,
    end_time,
    duration,
    service_type,
    status,
    qualification_level,
    is_bidding,
    bidding_user_ids,
  } = appointment;
  const { full_name, suburb } = client;

  const [localBidding, setLocalBidding] = useState(is_bidding);
  const [localBiddingUsers, setLocalBiddingUsers] = useState(bidding_user_ids || []);

  useEffect(() => {
    if (focusKey && focusKey === key) {
      setLocalBidding(focusBidding ?? false);
      setLocalBiddingUsers(focusBiddingIds || []);
    }
  }, [focusBidding, focusBiddingIds, key]);

  const cardClass = classNames('unallocated-card', {
    'greyed-out': (anyActionMenuOpen && !isActionMenuOpen) || (focusKey && focusKey !== key),
  });

  const appointmentClass = classNames('appointment-content', {
    'hide-address': !unallocatedSuburb,
  });

  const statusStyle: CSSProperties = {
    backgroundColor: status?.colour ?? undefined,
  };

  const biddingStyle: CSSProperties = {
    color: localBidding && localBiddingUsers.length === 0 ? 'red' : 'black',
  };

  const renderPreference = () => {
    let displayPreference = 'Appt has no worker preference';
    const classes = [];
    if (id === null && user) {
      const { full_name, is_available, is_qualified } = user;
      displayPreference = `Worker preference: ${full_name}`;

      if (!is_available) {
        displayPreference += ' (NA)';
        classes.push('not-available');
      }
      if (!is_qualified) {
        displayPreference += ' (NQ)';
        classes.push('not-qualified');
      }
    }

    const className = classNames('preference', classes);
    return <div className={className}>{displayPreference}</div>;
  };

  return (
    <div className={cardClass}>
      {showStatus && <div className="status-strip" style={statusStyle} />}
      <div className="content">
        <div className={appointmentClass}>
          <div className="client">
            <div>{full_name}</div>
            {unallocatedSuburb && <div>{suburb}</div>}
            {unallocatedServiceType && <div className="service">{service_type?.name || ''}</div>}
          </div>
          <div className="date-time">
            <div>{dayjs(date).format(weeklyPlannerDayDisplayFormat)}</div>
            <div className="start-end">
              {start_time} - {end_time}
            </div>
            {unallocatedDuration && <div className="duration">{duration} mins</div>}
          </div>
        </div>

        {unallocatedQualifications && (
          <div className="qualifications">
            <WorkerQualifications qualifications={qualification_level} />
          </div>
        )}
        <div className="extras">
          {renderPreference()}
          <div className="d-flex">
            {localBidding && (
              <div className="fw-bold me-2" style={biddingStyle}>
                {`Bidding (${localBiddingUsers.length} CW)`}
              </div>
            )}
            {id === null && parent_repeat_id && (
              <div className="is-repeat">
                <Icon icon={faRepeat} title="Repeat Appointment" />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default UnallocatedCard;
