import React, { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from 'hooks';
import { Link } from 'react-router-dom';
import { Input, Label, Table } from 'reactstrap';
import dayjs from 'dayjs';
import Button from 'tsx/components/Button';
import { InputProps } from 'tsx/components/FormFields';
import Icon, { faPencil } from 'tsx/components/Icon';
import { dateDisplayFormat } from 'tsx/libs/dayjs';

import { getClientPackageSchedules } from '~main/actions/clients/clientPackageSchedules';

import {
  clearClientPackageSchedules,
  selectClientPackageSchedules,
} from '~main/reducers/clients/clientPackageSchedules';
import { selectClientByID } from '~main/reducers/clients/clients';

const FormInputPackageSelector: React.FC<InputProps> = ({ value, disabled, onChange, id }) => {
  const dispatch = useAppDispatch();
  const { package_fixed_id = '', client_id = '', date } = value;

  const client = useAppSelector((state) => selectClientByID(state, parseInt(client_id)));
  const packageSchedules = useAppSelector(selectClientPackageSchedules);

  const REDIRECT_URL = process.env.REACT_APP_TPONE_URL;

  useEffect(() => {
    // Clean up component on unmount, clear reducer
    return () => {
      dispatch(clearClientPackageSchedules());
    };
  }, []);

  useEffect(() => {
    if (dayjs(date).isValid()) dispatch(getClientPackageSchedules({ client_id, date }));
  }, [client_id, date]);

  const bodyRows = packageSchedules.map(({ package: { id, name }, start_date, end_date }, index) => {
    const reference = id.toString();
    const highlightDefault = client?.default_package?.id.toString() === reference ? ' bg-info rounded' : '';
    return (
      <tr key={index}>
        <td colSpan={2}>
          <div className={`p-1 d-flex align-items-center${highlightDefault}`}>
            <Label className="mb-0">
              <Input
                disabled={disabled}
                type="radio"
                name="package_fixed_id"
                value={reference}
                checked={package_fixed_id.toString() === reference}
                onChange={({ target: { name, value } }) => onChange && onChange(name, value)}
              />
              <span className="ms-2">{name}</span>
            </Label>
          </div>
        </td>
        <td>
          <div className="p-1 d-flex align-items-center">{dayjs(start_date).format(dateDisplayFormat)}</div>
        </td>
        <td>
          <div className="p-1 d-flex align-items-center">{dayjs(end_date).format(dateDisplayFormat)}</div>
        </td>
      </tr>
    );
  });

  const headerClassName = 'ps-2 pe-2 border-bottom border-info';
  const isEmpty = packageSchedules.length === 0;
  const caption = isEmpty ? 'No current packages' : `${packageSchedules.length} packages found`;

  return (
    <>
      <div className="d-flex align-items-center col">
        <span className={`ps-2 pe-2 fw-bold`}>{caption}</span>
        {isEmpty && client_id && !disabled && (
          <>
            <Link to={`${REDIRECT_URL}/client-details.asp?eid=${client_id}&BREAKDOWN_SHOW_PACKAGE_SCHEDULE=yes`}>
              <Button type="button" size="sm" className="ms-2 btn btn-warning btn-sm">
                <Icon icon={faPencil} className="ms-2 me-2" />
                Edit Client Packages
              </Button>
            </Link>
          </>
        )}
      </div>
      {!isEmpty && (
        <Table striped data-id={id}>
          <thead>
            <tr>
              <th className={headerClassName} colSpan={2}>
                Package Name
              </th>
              <th className={headerClassName}>Start Date</th>
              <th className={headerClassName}>End Date</th>
            </tr>
          </thead>
          <tbody>{bodyRows}</tbody>
        </Table>
      )}
    </>
  );
};

export default FormInputPackageSelector;
