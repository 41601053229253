import React, { ReactNode } from 'react';
import { Button, Modal as RSModal, ModalBody, ModalFooter, ModalHeader, ModalProps } from 'reactstrap';

interface ComponentProps extends ModalProps {
  className?: string;
  headerClassName?: string;
  bodyClassName?: string;
  header?: ReactNode;
  isOpen?: boolean;
  footer?: ReactNode;
  setIsOpen: (toggle: boolean) => void;
  closeButtonText?: string;
}

const Modal: React.FC<ComponentProps> = ({
  isOpen,
  setIsOpen,
  children,
  header,
  bodyClassName,
  headerClassName,
  footer,
  closeButtonText = 'Close',
  ...props
}) => {
  return (
    <RSModal isOpen={isOpen} {...props}>
      <ModalHeader
        className={headerClassName}
        toggle={() => {
          setIsOpen(!isOpen);
        }}
      >
        {header}
      </ModalHeader>
      <ModalBody className={bodyClassName}>{children}</ModalBody>
      <ModalFooter>
        {footer ? (
          footer
        ) : (
          <Button color="secondary" onClick={() => setIsOpen(!isOpen)}>
            {closeButtonText}
          </Button>
        )}
      </ModalFooter>
    </RSModal>
  );
};

export default Modal;
