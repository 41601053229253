import FormInputCareWorkers from '~appointments/components/FormInputCareWorkers';
import FormInputDate from '~appointments/components/FormInputDate';
import { Step } from '~components/WizardForm/props';
import WizardReadonlyQualifications from '~weekly-planner/components/Wizard/WizardReadonlyQualifications';
import FormInputStartEndTimes from '~appointments/components/FormInputStartEndTimes';
import WizardInputPackageSelector from '~weekly-planner/components/Wizard/WizardInputPackageSelector';

import {
  deriveClientAddress,
  deriveClientPackageName,
  deriveServiceTypeDescription,
  deriveServiceTypeSelectorParams,
} from '~appointments/lib/derive';
import { onChangeClient, onChangePackage, onChangeServiceType } from '~appointments/lib/onchange';
import { validateStartEndTimes } from '~appointments/lib/validate';

import { getAll as getServiceTypes } from '~appointments/actions/serviceTypes';

import { selectCareQualificationLevels } from '~appointments/reducers/careQualificationLevels';
import { selectClientPackages } from '~main/reducers/clients/clientPackages';
import { selectLookupClients } from '~main/reducers/clients/clients';
import { selectLookupUsers } from '~care-workers/reducers/users';
import { selectAppointmentServiceTypes } from '~appointments/reducers/serviceTypes';

const addAppointmentWizardFields: Step[] = [
  {
    step: 1,
    title: 'Quick Add Appointment',
    fields: {
      id: {
        type: 'hidden',
      },
      date: {
        type: 'custom',
        component: FormInputDate,
        caption: 'Appointment Date',
        required: true,
        colSize: 6,
        order: 100,
      },

      status: {
        type: 'readonly',
        field: 'status.name',
        order: 10,
      },
      client_name: {
        type: 'select',
        field: 'client_id',
        caption: 'Client / Facility',
        deriveDescription: deriveClientAddress,
        onChange: onChangeClient,
        className: 'text-left',
        required: true,
        colSize: 6,
        order: 200,
        selectorOptions: selectLookupClients,
      },
      package_fixed_id: {
        type: 'custom',
        component: WizardInputPackageSelector,
        dependencies: ['package_fixed_id', 'client_id', 'date'],
        onChange: onChangePackage,
        selectorOptions: selectClientPackages,
        required: true,
        colSize: 6,
        order: 300,
      },

      client_package_name: {
        type: 'readonly',
        caption: 'Default Package',
        deriveValue: deriveClientPackageName,
        className: 'text-left',
        order: 400,
      },
      start_end_time: {
        type: 'custom',
        caption: 'Start/End Times',
        colSize: 6,
        component: FormInputStartEndTimes,
        dependencies: ['start_time', 'end_time', 'ends_next_day'],
        validate: validateStartEndTimes,
        order: 500,
      },
    },
  },
  {
    step: 2,
    title: 'Worker & Qualifications',
    fields: {
      service_type_id: {
        type: 'select-lazy',
        caption: 'Service Type',
        initialOption: { id: 'service_type', name: '' },
        selectorOptions: selectAppointmentServiceTypes,
        selectorActions: getServiceTypes,
        selectorParams: deriveServiceTypeSelectorParams,
        onChange: onChangeServiceType,
        deriveWarning: deriveServiceTypeDescription,
        order: 100,
        colSize: 6,
        required: true,
      },
      qualification_level: {
        type: 'custom',
        caption: 'Qualifications Required',
        component: WizardReadonlyQualifications,
        dependencies: ['service_type_id', 'qualification_level'],
        selectorOptions: selectCareQualificationLevels,
        order: 200,
      },
      user_id: {
        type: 'select',
        caption: 'Care Worker',
        component: FormInputCareWorkers,
        selectorOptions: selectLookupUsers,
        initialOption: { id: 'user_id', name: '' },
        colSize: 9,
        order: 300,
      },
    },
  },
  {
    fields: {
      warning: {
        type: 'alert-warning',
        className: 'fw-bold w-100',
        colSize: 12,
        order: 100,
        defaultValue: 'Please note, this will save your appointment immediately. It will not be an unsaved change.',
      },
      warning2: {
        type: 'readonly',
        className: 'fw-bold w-100 text-center',
        colSize: 12,
        order: 200,
        defaultValue: 'Are you sure you want to continue?',
      },
    },
    step: 3,
    title: 'Confirm',
  },
];

export default addAppointmentWizardFields;
