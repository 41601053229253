import dayjs from 'dayjs';
import { createSlice, isPending, isRejected } from '@reduxjs/toolkit';
import axios from 'axios';
import { store } from 'store';

import { get as getLocal, remove as removeLocal, set as setLocal } from '~libs/localstorage';
import { CommonState, Row } from '~libs/reduxUtils';

import { authenticateSession, getSettings, login, typePrefix } from '~main/actions/login';

const sessionKey = 'x-session-token';

interface SystemConfig {
  content?: {
    status?: string;
    heading?: string;
    text?: string;
  };
  last_updated?: string;
}

export interface User {
  id: number;
  first_name: string;
  surname: string;
  accounts_access_enabled: boolean;
  company: {
    id: number;
    name: string;
    logo: string;
  };
}

interface LinkedAccount extends User {
  full_name: string;
}

interface AuthState extends CommonState<Row> {
  authenticated: boolean;
  sessionToken: string | null;
  forceRedirect: boolean;
  settings: { [key: string]: string | number | boolean | [] };
  defaults?: { [key: string]: string | number | boolean };
  system?: SystemConfig;
  user?: User;
  tags?: string[];
  linkedAccounts?: LinkedAccount[];
}

const initialState: AuthState = {
  loading: 'idle',
  error: null,
  authenticated: getLocal(sessionKey) ?? false,
  sessionToken: getLocal(sessionKey) ?? null,
  forceRedirect: false,
  rows: [],
  row: { id: -1 },
  settings: {},
  defaults: {},
  system: {},
  tags: [],
};

// Main slice, connecting API actions to redux state.
const slice = createSlice({
  name: 'login',
  initialState,
  reducers: {
    checkAuthenticated(state) {
      state.authenticated = getLocal(sessionKey) !== undefined;
    },
    removeAuthenticated(state) {
      state.authenticated = false;
      state.sessionToken = null;
      removeLocal(sessionKey);
    },
    setForceRedirect(state) {
      state.forceRedirect = true;
    },
    setSessionToken(state, action) {
      state.sessionToken = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(login.fulfilled, (state, action) => {
      state.loading = 'fulfilled';
      state.authenticated = true;
      state.user = action.payload.data;
    });
    builder.addCase(authenticateSession.fulfilled, (state, action) => {
      state.loading = 'fulfilled';
      state.authenticated = true;
      state.user = action.payload.data;
    });
    builder.addCase(getSettings.fulfilled, (state, action) => {
      state.loading = 'fulfilled';
      const { company, user, tags, defaults, system } = action.payload.data;

      state.settings = company;
      state.system = system;
      state.defaults = defaults;
      state.user = user;
      state.tags = tags;
      state.linkedAccounts = user.linked_accounts;
    });
    // Default matching for loading cases, pending when action is being called
    builder.addMatcher(isPending, (state, { type }) => {
      if (type.startsWith(`${typePrefix}/`)) state.loading = 'pending';
    });
    builder.addMatcher(isRejected, (state, action) => {
      if (action.type.startsWith(`${typePrefix}/`)) {
        state.loading = 'declined';
        state.error = action.error.message;
      }
    });
  },
});

export const { checkAuthenticated, removeAuthenticated, setForceRedirect, setSessionToken } = slice.actions;

axios.interceptors.request.use(
  (request) => {
    // Add x-session-token to request header if available
    const token = store.getState().main.login.sessionToken;
    if (token) {
      request.headers[sessionKey] = token;
    }

    // Add client timezone for notifying API of region for stamping create/update dates accurately
    request.headers['UTC-Offset'] = dayjs().format('ZZ');
    return request;
  },
  (error) => error,
);

// Look for any unauthorised responses from the API.
axios.interceptors.response.use(
  (response) => {
    const token = response.headers[sessionKey];
    if (token) {
      store.dispatch(setSessionToken(token));
      setLocal(sessionKey, token);
    }
    return response;
  },
  (error) => {
    if (error?.response?.status === 401) {
      const { data } = error.response;

      if (data.reason === 'company-disabled') store.dispatch(setForceRedirect());
      store.dispatch(removeAuthenticated());
    }
    return Promise.reject(error);
  },
);

export default slice.reducer;
