import { apiClientReferrers } from '~constants/api';
import { get } from '~libs/genericAction';
import { createThunks, Params } from '~libs/reduxUtils';

import { typePrefix as prefix } from '~main/actions/clients';

export const typePrefix = `${prefix}-referrers`;

const config = [
  {
    actionType: 'getAll',
    apiPath: apiClientReferrers,
    method: get,
    actionParams: { limit: 0 },
  },
  {
    actionType: 'getOne',
    apiPath: (params: Params) => `${apiClientReferrers}/${params.id}`, // TODO: Test getOne endpoint
    method: get,
  },
];

export const { getAll, getOne } = createThunks(typePrefix, config);
