import React, { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from 'hooks';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { Col, Container, Row } from 'reactstrap';
import AnnouncementBanner from '~main/AnnouncementBanner';
import Header from '~main/Header';
import QuickMenu from '~main/QuickMenu';
import classNames from 'classnames';

import { useAllSearchParams } from '~libs/searchParams';

import { getSettings } from '~main/actions/login';

import { selectIsDesktopView, selectViewType, setViewType } from '~main/reducers/app';

function Home() {
  const { pathname } = useLocation();
  const dispatch = useAppDispatch();

  const searchParams = useAllSearchParams();

  const viewType = useAppSelector(selectViewType);
  const isDesktop = useAppSelector(selectIsDesktopView);

  useEffect(() => {
    dispatch(getSettings());

    // Add view toggle to remove header/quick menu/navigation items, used for iFrame windows within TP1
    // This must be removed upon close of TP1. (╯°□°）╯︵ ┻━┻
    const { view_type } = searchParams;
    dispatch(setViewType(view_type));
  }, []);

  if (pathname === '/') return <Navigate to="/home" replace />;

  const containerClass = classNames('app-container', {
    ['prevent-nav']: viewType === 'feature',
    'desktop-view': isDesktop,
  });

  return (
    <Container fluid className={containerClass}>
      <Header />
      <Row className="app-row gx-0">
        <QuickMenu />
        <Col className="features">
          <AnnouncementBanner />
          <Outlet />
        </Col>
      </Row>
    </Container>
  );
}

export default Home;
