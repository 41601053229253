import { SearchProps } from '~components/FormFields/Search';
import { selectLocations } from '~appointments/reducers/locations';
import { selectUserDepartments } from '~care-workers/reducers/userDepartments';
import { selectLookupUsers } from '~care-workers/reducers/users';
import { selectUserPayLevels } from '~care-workers/reducers/userPayLevels';
import { selectRegions } from '~main/reducers/address/regions';

const fields: SearchProps = {
  user_id: {
    defaultShow: true,
    type: 'multi-lookup',
    field: 'id',
    caption: 'Care Worker',
    placeholder: 'All',
    selectorOptions: selectLookupUsers,
  },
  region_id: {
    defaultShow: false,
    type: 'multi-lookup',
    field: 'region_id',
    caption: 'Region',
    placeholder: 'All',
    selectorOptions: selectRegions,
  },
  pay_level: {
    defaultShow: false,
    type: 'multi-lookup',
    field: 'pay_level_id',
    caption: 'Pay Level',
    placeholder: 'All',
    selectorOptions: selectUserPayLevels,
  },
  department: {
    defaultShow: false,
    type: 'multi-lookup',
    field: 'department_id',
    caption: 'Department',
    placeholder: 'All',
    selectorOptions: selectUserDepartments,
  },
  user_location: {
    defaultShow: false,
    type: 'multi-lookup',
    field: 'work_sites',
    caption: 'User Location',
    placeholder: 'All',
    selectorOptions: selectLocations,
  },
  appointment_location: {
    defaultShow: false,
    type: 'multi-lookup',
    field: 'filter_by[location_override_id][]',
    caption: 'Appointment Location',
    placeholder: 'All',
    selectorOptions: selectLocations,
  },
  trip_location: {
    defaultShow: false,
    type: 'multi-lookup',
    field: 'filter_by[transport_id][]',
    caption: 'Pickup / Dropoff Location',
    placeholder: 'All',
    selectorOptions: selectLocations,
  },
};

export default fields;
