import React, { CSSProperties, Fragment, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'hooks';
import { JSX } from 'react/jsx-runtime';
import classNames from 'classnames';
import { Dayjs } from 'dayjs';

import { WeeklyPlannerDatasetOptions as ViewOptions } from '~constants/maps';

import {
  Appointment,
  Break,
  calcIndexFromTime,
  Client,
  Conflict,
  Event,
  getGridColumn,
  Travel,
  Worker,
  WorkerAvailability,
} from '~weekly-planner/lib/common';
import { MAX_PIPS, onDailyAppointmentDragEnd } from '~weekly-planner/lib/drag-drop';

import { DraggableItem, DroppableArea } from '~components/DragAndDrop';
import Spinner from '~components/Spinner';
import { AppointmentCard, BreakCard, TravelCard } from '~weekly-planner/components/Card';

import { selectFocusedAppointment, selectOptions, selectViewType } from '~weekly-planner/selectors';
import { selectAllSimulatedChanges, selectUnallocated } from '~weekly-planner/selectors/simulate';
import { selectRowLoading } from '~weekly-planner/selectors/unsaved';

interface ComponentProps {
  date: Dayjs;
  dataObj: Worker | Client;
  events: Event[];
  style?: CSSProperties;
  startDate?: Dayjs;
}

const Cell: React.FC<ComponentProps> = ({ date, dataObj, events, style, startDate }) => {
  const dispatch = useAppDispatch();
  const [current, setCurrent] = useState<any>(null);
  const [isOver, setIsOver] = useState(false);
  const [isOrigin, setIsOrigin] = useState(false);

  const data = useAppSelector(selectAllSimulatedChanges);
  const unallocated = useAppSelector(selectUnallocated);
  const { showBreaks, showTravel, showTeaBreaks } = useAppSelector(selectOptions);
  const focused = useAppSelector(selectFocusedAppointment);
  const { appointment: focusedAppointment } = focused || {};
  const { id: focusId } = focusedAppointment || {};
  const { id } = dataObj;
  const key = `${id}-cell`;
  const viewType = useAppSelector(selectViewType);
  const loading = useAppSelector((state) => selectRowLoading(state, parseInt(id)));

  const isLoading = loading === 'pending';

  const handleDragOverChange = (isOver: boolean, current?: any) => {
    setCurrent(current);
    setIsOver(isOver);
    setIsOrigin(current?.containerId === id);
  };

  const renderAppointments = (cards: Appointment[], index: string) => {
    // If more than one appointment in a row, there are conflicts
    // Set row as a grid and show appointments side-by-side
    return (
      <Fragment key={index}>
        {cards.map((appointment: any, index: number) => {
          const style: CSSProperties = {
            gridRow: index + 1,
            ...getGridColumn(appointment.start_time, appointment.end_time, 1),
          };

          const { key, is_cancelled, is_shift_pay } = appointment;

          const props = {
            appointment,
            events,
          };

          // Not allowed to be dragged if appointment is cancelled, shift payable or missing primary identifier
          const isNotDraggable = is_cancelled || !key || is_shift_pay || viewType === ViewOptions.CLIENT;
          if (isNotDraggable) {
            return <AppointmentCard {...props} key={`${id}-${index}`} style={{ ...style, gridRow: 5 }} />;
          }
          return (
            <DraggableItem
              key={`${id}-${index}`}
              id={key}
              style={style}
              data={{
                appointment,
                containerId: id,
                containerStyle: style,
                onItemDragEnd: onDailyAppointmentDragEnd,
                data,
                startDate,
                dispatch,
              }}
            >
              <AppointmentCard {...props} />
            </DraggableItem>
          );
        })}
      </Fragment>
    );
  };

  const renderAvailability = (date: Dayjs) => {
    const dayOfWeek = date.format('dddd');
    if (!('availability' in dataObj) || !dataObj.availability) return null;

    const shifts: (JSX.Element | null)[] = [];
    const availability = dataObj?.availability?.[dayOfWeek as keyof WorkerAvailability] ?? {};

    const renderSection = (start: string, end: string) => {
      if (!start || !end) return null;

      // handle edge cases for 00:00 / 24:00 start & end
      let startIndex = start === '0000' || start === '000' || start === '2400' ? 1 : calcIndexFromTime(start);
      let endIndex = end === '0000' || end === '000' || end === '2400' ? MAX_PIPS : calcIndexFromTime(end);

      // same start & end time (full availability)
      if (start === end) {
        startIndex = 1;
        endIndex = MAX_PIPS;
      }

      const style: CSSProperties = { gridColumn: `${startIndex + 1} / span ${endIndex - startIndex}`, gridRow: 1 };
      return <div className="availability" style={style}></div>;
    };

    if (Array.isArray(availability)) {
      availability.map((shift) => {
        shifts.push(renderSection(shift.start, shift.end));
      });
    } else if (availability.start && availability.end) {
      shifts.push(renderSection(availability.start, availability.end));
    }

    return shifts;
  };

  const renderTravels = (cards: Travel[], index: string) => {
    return (
      <Fragment key={index}>
        {cards
          .map((travel, index: number) => {
            // Don't render travel cards with no defined start/end times.
            const {
              from: { time: start },
              to: { time: end },
            } = travel;
            if (!start || !end) return false;

            const style: CSSProperties = {
              gridRow: index + 1,
              ...getGridColumn(start, end, 1),
            };

            return <TravelCard key={index} travel={travel} style={style} />;
          })
          .filter(Boolean)}
      </Fragment>
    );
  };

  const renderBreaks = (cards: Break[], index: string) => {
    return (
      <Fragment key={index}>
        {cards
          .map((breakData, index: number) => {
            // Don't render travel cards with no defined start/end times.
            const {
              from: { time: start },
              to: { time: end },
            } = breakData;
            if (!start || !end) return false;

            const style: CSSProperties = {
              gridRow: index + 1,
              ...getGridColumn(start, end, 1),
            };

            return <BreakCard key={index} data={breakData} style={style} />;
          })
          .filter(Boolean)}
      </Fragment>
    );
  };

  const renderConflict = (data: Conflict[], index: number) => {
    // Group all appointments together and render
    // Group all travels together and render a range travel
    const appointments: any[] = [];
    const travels: any[] = [];
    const breaks: any[] = [];
    const teaBreaks: any[] = [];
    data.forEach((events) =>
      events.forEach(({ type, data }: Event) => {
        switch (type) {
          case 'appointment': {
            appointments.push(data);
            break;
          }
          case 'travel': {
            travels.push(data);
            break;
          }
          case 'break': {
            breaks.push(data);
            break;
          }
          case 'tea_break': {
            teaBreaks.push(data);
            break;
          }
          default: {
            break;
          }
        }
      }),
    );

    return (
      <Fragment key={index}>
        {renderAppointments(appointments, `appointment-${index}`)}
        {showTravel && renderTravels(travels, `travel-${index}`)}
        {showBreaks && renderBreaks(breaks, `break-${index}`)}
        {showTeaBreaks && renderBreaks(teaBreaks, `tea-break-${index}`)}
      </Fragment>
    );
  };

  const renderEvents = (events: Event[]) => {
    return events
      .map(({ type, data }, index) => {
        switch (type) {
          case 'appointment': {
            return renderAppointments([data], `${type}-${index}`);
          }
          case 'conflict': {
            return renderConflict(data, index);
          }
          case 'travel': {
            return showTravel && renderTravels([data], `${type}-${index}`);
          }
          case 'break': {
            return showBreaks && renderBreaks([data], `${type}-${index}`);
          }
          case 'tea_break': {
            return showTeaBreaks && renderBreaks([data], `${type}-${index}`);
          }
          default: {
            return false;
          }
        }
      })
      .filter(Boolean);
  };

  const renderDragPlaceholders = () => {
    if (!current) return <></>;

    const appointment = current?.appointment;
    const style = current?.containerStyle;
    return (
      <>
        {renderDragPlaceholder(style)}
        {isOver && !isOrigin && (
          <AppointmentCard
            key={`${key}-dragging`}
            className="dragging"
            appointment={appointment}
            style={{ ...style, gridRow: 'auto' }}
            events={events}
          />
        )}
      </>
    );
  };

  const renderDragPlaceholder = (style: CSSProperties) => (
    <div key={`${key}-placeholder`} className="drag-placeholder" style={{ ...style, gridRow: '1 / span 5' }} />
  );

  const renderFocusedPlaceholder = () => {
    const appointment = unallocated.find(({ id }) => focusId && id === focusId);
    if (!appointment) return <></>;

    const style = getGridColumn(appointment.start_time, appointment.end_time, 1);
    return renderDragPlaceholder(style);
  };

  const className = classNames('daily-cell', {
    'is-dragging-over': isOver,
    'is-dragging-origin': isOrigin,
  });

  return (
    <DroppableArea key={key} id={id} className={className} style={style} onDragOverChange={handleDragOverChange}>
      {isLoading && <Spinner loading={isLoading} style={style} />}
      {renderAvailability(date)}
      {renderEvents(events ?? [])}
      {current !== null && renderDragPlaceholders()}
      {focusId !== null && renderFocusedPlaceholder()}
    </DroppableArea>
  );
};

export default Cell;
