import React, { useCallback, useEffect, useRef } from 'react';
import { useAppDispatch, useAppSelector } from 'hooks';
import classNames from 'classnames';
import { throttle } from 'lodash';
import dayjs, { Dayjs } from 'dayjs';

import { LoadingState } from '~libs/reduxUtils';
import { dateInputFieldFormat } from '~libs/dayjs';
import { Client, Worker } from '~weekly-planner/lib/common';

import Spinner from '~components/Spinner';
import { Row } from './index';

import { update, updateParams } from '~weekly-planner/reducers/weeklyPlanner';

import { selectParams } from '~weekly-planner/selectors';
import { WeeklyPlannerDateRangeOptions as RangeOptions } from '~constants/maps';

interface ComponentProps {
  headerCell: React.FunctionComponent<any>;
  data: Array<Worker | Client>;
  loadingState: LoadingState | null;
}

const View: React.FC<ComponentProps> = ({ headerCell: HeaderCell, data, loadingState }) => {
  const dispatch = useAppDispatch();
  const { week_start, limit } = useAppSelector(selectParams);
  const isLoading = loadingState === 'pending';

  // most recent Monday
  const weekStart = dayjs(week_start).weekday(0);
  const daysOfWeek = Array.from({ length: 7 }, (_, i) => dayjs(weekStart).add(i, 'day'));

  const setView = (value: Dayjs) => {
    dispatch(updateParams({ week_start: value.weekday(0).format(dateInputFieldFormat) }));
    dispatch(update({ date: value.format(dateInputFieldFormat), rangeType: RangeOptions.DAILY }));
  };

  const containerRef = useRef<HTMLDivElement>(null);

  const onScroll = useCallback(
    throttle(async () => {
      if (!containerRef.current || isLoading) return;

      const { scrollHeight, scrollTop, clientHeight } = containerRef.current;
      const nearBottom = scrollHeight - (scrollTop + clientHeight) < 50;

      if (!isLoading && nearBottom && limit <= data.length) {
        dispatch(updateParams({ limit: limit + 30 }));
      }
    }, 200),
    [limit, data.length, dispatch, isLoading],
  );

  useEffect(() => {
    const container = containerRef.current;
    if (!container) return;

    container.addEventListener('scroll', onScroll, { passive: true });
    return () => container.removeEventListener('scroll', onScroll);
  }, [onScroll]);

  const headerColumnData = daysOfWeek.map((day) => (
    <div key={day.toString()} className={`header-cell ${day.isSame(dayjs(), 'day') ? 'today' : ''}`}>
      <span className="clickable" onClick={() => setView(day)}>
        {day.format('ddd (DD/MMM)')}
      </span>
    </div>
  ));

  const containerClassNames = classNames('weekly-view-container', {
    loading: isLoading,
    'initial-load': data.length < 1,
  });

  return (
    <div>
      <div ref={containerRef} className={`${containerClassNames}`}>
        <div className="header-cell">Care Worker</div>
        {headerColumnData}
        {data?.map((dataObj, index) => (
          <Row
            key={`${dataObj.id}-${index}`}
            headerCell={
              <HeaderCell
                data={dataObj}
                date={dayjs(week_start)}
                style={{
                  gridRow: index + 2,
                  gridColumn: 1,
                }}
              />
            }
            dataObj={dataObj}
            days={daysOfWeek}
            date={dayjs(week_start)}
            style={{
              gridRow: index + 2,
            }}
          />
        ))}
        {data.length >= 1 && <Spinner loading={isLoading} className="p-5" />}
      </div>
      {data.length < 1 && <Spinner loading={isLoading} className="p-5" />}
    </div>
  );
};

export default View;
