import { apiUserPayLevels } from '~constants/api';
import { get } from '~libs/genericAction';
import { createThunks, Params } from '~libs/reduxUtils';

import { typePrefix as prefix } from '~care-workers/actions';

export const typePrefix = `${prefix}-pay-levels`;

const config = [
  {
    actionType: 'getAll',
    apiPath: apiUserPayLevels,
    method: get,
    actionParams: { attributes: ['id', 'name'], sort: ['name'], limit: 0 },
  },
  { actionType: 'getOne', apiPath: (params: Params) => `${apiUserPayLevels}/${params.id}`, method: get },
];

export const { getAll, getOne } = createThunks(typePrefix, config);
