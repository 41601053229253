export const AppointmentStatuses = Object.freeze({
  PENDING_CARER_APPROVAL: 1,
  CARER_REJECTED: 2,
  OFFICE_CHANGED: 3,
  CARER_CHANGED: 4,
  ACCEPTED: 5,
  STARTED: 6,
  COMPLETED: 7,
  CANCELLED: 8,
});

export const PaymentTypes = Object.freeze({
  NONE: 'None',
  EFTPOS: 'EFTPOS',
  CASH: 'Cash',
  ACCOUNT: 'Account',
});

export const FilterOptions = Object.freeze({
  ALL: 'All',
  YES: 'Yes',
  NO: 'No',
});

export const RadioOptions = Object.freeze({
  TIMES: 'Times',
  DISTANCE: 'Distance',
});

export const CareWorkerFilterOptions = Object.freeze({
  AVAILABLE: 'Available',
  PREFERRED: 'Preferred',
  HISTORICAL: 'Historical',
});

export const WeeklyPlannerDatasetOptions = Object.freeze({
  USER: 'Users',
  CLIENT: 'Clients',
});

export const WeeklyPlannerDateRangeOptions = Object.freeze({
  WEEKLY: 'Weekly',
  DAILY: 'Daily',
});
