import { createThunks } from '~libs/reduxUtils';
import { get } from '~libs/genericAction';
import { apiWeeklyPlannerAppointmentMissingQualifications } from '~constants/api';
import { typePrefix as prefix } from '~weekly-planner/actions/appointments';

export const typePrefix = `${prefix}-missing-qualifications`;

const thunkConfig = [{ actionType: 'getAll', apiPath: apiWeeklyPlannerAppointmentMissingQualifications, method: get }];

export const { getAll } = createThunks(typePrefix, thunkConfig);
