import { buildSlice, CommonState } from '~libs/reduxUtils';
import { Appointment } from '~weekly-planner/lib/common';

import { getAll, typePrefix } from '~weekly-planner/actions/appointments/missingQualifications';

interface State extends CommonState<Appointment> {
  params: { [key: string]: any };
  count?: number;
}

const initialState: State = {
  loading: 'idle',
  hasLoaded: false,
  error: null,
  rows: [],
  params: { limit: 30 },
};

const slice = buildSlice<State, Appointment>(typePrefix, initialState, {
  extraReducers: (builder) => {
    builder.addCase(getAll.fulfilled, (state, action) => {
      state.loading = 'fulfilled';
      const { data } = action.payload;
      const { rows, total } = data;
      state.rows = rows;
      state.count = total;
      state.hasLoaded = true;
    });
  },
});

export const { clear, updateParams } = slice.actions;
export default slice.reducer;
