import React from 'react';

import { Fields, FieldProps } from '~components/FormFields';
import { Step } from './props';

/**
 * Props for the Steps component.
 * @property context - The Formik context, which contains form values, field methods, and touched states.
 * @property currentStep - The index of the current step in the wizard form.
 * @property stepCount - The total number of steps in the wizard form.
 * @property step - The current step, containing field definitions, title, and other metadata.
 * @property authTag - A unique authentication tag used in the form (could be for tracking or security purposes).
 * @property invalidFields - A list of fields that are currently invalid.
 */
interface ComponentProps {
  context: any;
  currentStep: number;
  stepCount: number;
  steps: Step[];
  authTag: string;
  invalidFields: string[];
}

/**
 * Steps component responsible for rendering form fields and a review section in the wizard.
 *
 * @param  props
 * @returns The rendered Steps component.
 */
export const Steps: React.FC<ComponentProps> = ({ context, currentStep, authTag, invalidFields, steps }) => {
  const { values, setFieldValue, setFieldTouched, touched } = context;
  const step = steps[currentStep] ?? {};

  const stepFieldDefinitions =
    step &&
    Object.entries(step.fields as FieldProps).map(([key, props]) => ({
      key,
      ...props,
    }));

  return (
    step && (
      <Fields
        authTag={authTag}
        definitions={stepFieldDefinitions}
        invalidFields={invalidFields}
        values={values}
        setFieldValue={setFieldValue}
        setFieldTouched={setFieldTouched}
        touched={touched}
      />
    )
  );
};

export default Steps;
