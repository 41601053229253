import { createSlice, isPending, isRejected } from '@reduxjs/toolkit';
import { RootState } from 'store';
import { LoadingState } from '~libs/reduxUtils';
import { typePrefix, getAll } from '~main/actions/ndisSupportItems';

interface Row {
  [key: string]: number | string;
}

interface NDISSupportItemState {
  loading: LoadingState;
  error: string | null | undefined;
  row: Row;
}

const initialState: NDISSupportItemState = {
  loading: 'idle',
  error: null,
  row: {},
};

// Main slice, connecting API actions to redux state.
export const ndisSupportItemsSlice = createSlice({
  name: 'ndis-support-items',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getAll.fulfilled, (state, action) => {
      state.loading = 'fulfilled';
      state.row = action.payload.data;
    });
    // Default matching for loading cases, pending when action is being called
    builder.addMatcher(isPending, (state, { type }) => {
      if (type.startsWith(`${typePrefix}/`)) state.loading = 'pending';
    });
    builder.addMatcher(isRejected, (state, action) => {
      if (action.type.startsWith(`${typePrefix}/`)) {
        state.loading = 'declined';
        state.error = action.error.message;
      }
    });
  },
});

// Selectors, performing common selection tasks for this slice.
export const selectNDISSupportItems = ({ main }: RootState) => main.ndisSupportItems.row;

export default ndisSupportItemsSlice.reducer;
