import React from 'react';
import { Col, PopoverBody, PopoverHeader, Row, UncontrolledPopover } from 'reactstrap';
import dayjs from 'dayjs';
import { formatDuration, weeklyPlannerDayDisplayFormat } from 'tsx/libs/dayjs';

import { Appointment } from '../../lib/common';
import WorkerQualifications from '../Common/WorkerQualifications';

interface ComponentProps {
  isOpen?: boolean;
  target: string;
  toggle?: () => void;
  appointment: Appointment;
}

const AppointmentPopover: React.FC<ComponentProps> = ({ isOpen = false, target, toggle, appointment }) => {
  const {
    client,
    status,
    duration,
    date,
    flexibility,
    qualification_level,
    service_type,
    start_time,
    end_time,
    notes,
  } = appointment;

  const formattedDate = dayjs(date).format(weeklyPlannerDayDisplayFormat);

  return (
    <UncontrolledPopover
      isOpen={isOpen}
      toggle={toggle}
      target={target}
      className="appointment-popover"
      aria-label="Appointment Details Popover"
    >
      <PopoverHeader className="bg-success text-white">Appointment Details</PopoverHeader>
      <PopoverBody className="pop-body">
        <Row className="title mb-2">{client.full_name} </Row>
        <Row>
          <Col lg={3}>Status:</Col>
          <Col className="text-end">{status.name}</Col>
        </Row>
        <Row>
          <Col lg={3}>Referrer:</Col>
          <Col className="text-end">{client.referrer_name || '-'}</Col>
        </Row>
        <Row>
          <Col lg={3}>Service:</Col>
          <Col className="text-end">{service_type?.name}</Col>
        </Row>
        <Row>
          <Col lg={3}>Day:</Col>
          <Col className="text-end">{formattedDate}</Col>
        </Row>
        <Row>
          <Col lg={3}>Time:</Col>
          <Col className="text-end">
            {start_time} - {end_time} ({formatDuration(duration)})
          </Col>
        </Row>

        <Row>
          <Col className={`mb-3 text-end ${flexibility ? 'text-success' : 'text-danger'}`}>
            {flexibility ? 'Flexible with Times' : 'Not Flexible with Times'}
          </Col>
        </Row>

        <Row className="mb-3">
          {qualification_level?.length === 0 ? (
            <Col>No Qualifications Required</Col>
          ) : (
            <Col>
              Qualifications Required:
              <WorkerQualifications isCondensed qualifications={qualification_level} />
            </Col>
          )}
        </Row>
        <Row title={notes} className="mb-3 ">
          <Row className="notes">Notes: {notes}</Row>
        </Row>
        <Row>
          {dayjs().isAfter(date) && (
            <div className="text-danger">
              <p className="fw-bold mb-0">This appointment date has passed.</p>
              <p>Do not remove unless there has been a clerical / data entry error.</p>
            </div>
          )}
        </Row>
      </PopoverBody>
    </UncontrolledPopover>
  );
};

export default AppointmentPopover;
