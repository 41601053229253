import { createAsyncThunk } from '@reduxjs/toolkit';
import { createThunks, Params } from 'tsx/libs/reduxUtils';
import { download, get, post, put } from 'tsx/libs/genericAction';
import { apiAppointments, apiWeeklyPlanner } from 'tsx/constants/api';

export const typePrefix = 'appointments';

interface DownloadParams extends Params {
  onProgress: (value: string) => void;
}

const config = [
  { actionType: 'getAll', apiPath: apiAppointments, method: get },
  { actionType: 'getSignature', apiPath: (params: Params) => `${apiAppointments}/${params.id}/signature`, method: get },
  { actionType: 'create', apiPath: apiAppointments, method: post },
  { actionType: 'bulkUpdate', apiPath: apiAppointments, method: put },
  { actionType: 'update', apiPath: (params: Params) => `${apiAppointments}/${params.id}`, method: put },
  {
    actionType: 'acknowledge',
    apiPath: (params: Params) => `${apiAppointments}/${params.id}/acknowledge`,
    method: put,
  },
  { actionType: 'cancel', apiPath: (params: Params) => `${apiAppointments}/${params.id}/cancel`, method: post },
  { actionType: 'getEstimate', apiPath: `${apiAppointments}/estimate`, method: get },
  {
    actionType: 'startBidding',
    apiPath: (params: Params) => `${apiAppointments}/${params.id}/bidding/start`,
    method: post,
  },
  {
    actionType: 'endBidding',
    apiPath: (params: Params) => `${apiAppointments}/${params.id}/bidding/end`,
    method: post,
  },
  { actionType: 'getStatistics', apiPath: `${apiWeeklyPlanner}/statistics`, method: get },
];

export const {
  getAll,
  getSignature,
  create,
  bulkUpdate,
  update,
  acknowledge,
  cancel,
  getEstimate,
  startBidding,
  endBidding,
  getStatistics,
} = createThunks(typePrefix, config);

// handle separately due to extra params
export const exportAll = async ({ onProgress, params }: DownloadParams) =>
  download(`${apiAppointments}/export`, params, onProgress);

export const getOne = createAsyncThunk(`${typePrefix}/getOne`, async ({ isTemplate, isParent, ...params }: Params) => {
  const response = await get(`${apiAppointments}/${params.id}`, params);
  return { ...response, isTemplate, isParent };
});
